import { createStyles, makeStyles, Theme } from '@material-ui/core';
import {
  CREW_TOP_BAR_HEIGHT,
  MAX_LARGE_INTERACTIVE_ELEMENT_WIDTH,
} from 'shared/constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: `calc(100% - ${CREW_TOP_BAR_HEIGHT}px - 80px)`, // TODO: find alternative solution
    },
    contentContainer: {
      boxSizing: 'border-box',
      width: '100%',
      maxWidth: '572px',
      padding: '20px',
      background: '#fff',
      boxShadow: `0 0 27px 4px ${theme.customPalette.darkPurple}`,
      borderRadius: '22px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.up('sm')]: {
        padding: '38px 56px',
      },
    },
    codeContainer: {
      marginTop: '22px',
      marginBottom: '32px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      maxWidth: `${MAX_LARGE_INTERACTIVE_ELEMENT_WIDTH}px`,
      border: '2px solid #EBEBEC',
      borderRadius: '12px',
      height: '78px',
    },
    codeText: {
      color: theme.customPalette.blueGray,
      fontSize: '16px',
    },
    scanButton: {
      marginBottom: '20px',
    },
    placeOrderButton: {
      marginTop: '20px',
    },
    buttonIcon: {
      height: '30px',
      width: '30px',
      fontSize: '30px',
      color: 'white',
    },
  }),
);

export default useStyles;
