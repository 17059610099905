import { PayloadAction } from '@reduxjs/toolkit';
import { EventResponse } from 'shared/types';
import { CurrentEventState } from './index';

const reducers = {
  getCurrentEventRequest: (state: CurrentEventState) => ({
    ...state,
    loading: true,
  }),
  getCurrentEventSuccess: (
    state: CurrentEventState,
    { payload }: PayloadAction<EventResponse>,
  ) => ({ ...state, data: payload, loading: false }),
  getCurrentEventFailure: (state: CurrentEventState) => ({
    ...state,
    loading: false,
  }),
  resetCurrentEventState: () => ({
    data: undefined,
    loading: true,
  }),
};

export default reducers;
