import { FC } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from 'core/store';
import { CardLayout } from 'shared/layouts';
import { CreditCount } from 'shared/components';
import Footer from './Footer';

const MyCredits: FC = () => {
  const { t } = useTranslation();
  const { goBack } = useHistory();
  const { currentUserCredits, currency, eventName } = useSelector(
    (state: RootState) => ({
      currentUserCredits: state.currentUser.data?.credits,
      currency: state.currentEvent.data?.currency || '',
      eventName: state.currentEvent.data?.name || '',
    }),
  );

  return (
    <CardLayout
      title={t('myCredits.title')}
      contentTitle={eventName}
      contentDescription={t('myCredits.contentDescription')}
      footer={<Footer />}
      onBackClick={goBack}
      maxContentWidth={750}
      centered
    >
      <CreditCount
        title={t('myCredits.creditCountTitle')}
        amount={currentUserCredits ?? 0}
        currency={currency}
      />
    </CardLayout>
  );
};

export default MyCredits;
