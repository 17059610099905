import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { TOP_BAR_HEIGHT } from 'shared/constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      height: `calc(100% - ${TOP_BAR_HEIGHT}px)`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      position: 'relative',
    },
    title: {
      fontSize: '21px',
      fontWeight: 900,
      lineHeight: '28px',
      color: theme.customPalette.textPrimary,
      marginBottom: '6px',
      marginTop: '24px',
    },
    currency: {
      fontSize: '14px',
      color: theme.customPalette.labelGray,
      paddingBottom: '3px',
      marginRight: '4px',
    },
    amount: {
      fontSize: '21px',
      fontWeight: 600,
      lineHeight: '28px',
      color: '#000',
    },
    description: {
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '22px',
      color: theme.customPalette.labelGray,
      width: '280px',
      textAlign: 'center',
      marginTop: '6px',
      marginBottom: '18px',
    },
    icon: {
      fontSize: '120px',
      color: theme.customPalette.accentGreen,
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
    },
    button: {
      padding: 0,
      minWidth: 0,
      position: 'absolute',
      top: 4,
      left: 15,
    },
    buttonLabel: {
      padding: 0,
      margin: 0,
    },
    buttonIcon: {
      fontSize: '26px',
      color: 'white',
    },
  }),
);

export default useStyles;
