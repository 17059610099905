import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      padding: '10px 18px 18px 18px',
      marginBottom: '20px',
    },
    inactiveCard: {
      padding: '10px 18px 10px 18px',
      marginBottom: '20px',
    },
    cardRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      '&:not(:last-child)': {
        marginBottom: '9px',
      },
    },
    eventDayText: {
      fontSize: '16px',
      color: theme.customPalette.textPrimary,
    },
    eventDayTextDisabled: {
      fontSize: '16px',
      color: theme.customPalette.accentText,
      textDecoration: 'line-through',
    },
    checkbox: {
      marginLeft: '-12px',
    },
    editIcon: {
      fontSize: '17px',
      color: theme.customPalette.labelGray,
    },
  }),
);

export default useStyles;
