import { MenuItem, SelectProps } from '@material-ui/core';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  getActiveEvents,
  getMerchantActiveEvents,
  postSwitchEvent,
} from 'core/requests';
import {
  categoriesSlice,
  currentEventSlice,
  currentUserSlice,
  loaderSlice,
  messageSlice,
  orderedProductsSlice,
  RootState,
} from 'core/store';
import { Select } from 'shared/components';
import { HOME_ROUTE } from 'shared/constants';
import { authToken, useRoleNavigation } from 'shared/helpers';
import { CardLayout } from 'shared/layouts';
import { EventsListItem } from 'shared/types';
import EventSelectionFooter from './EventSelectionFooter';
import useStyles from './useStyles';

const EventSelection: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { goBack } = useHistory();
  const { push } = useRoleNavigation();
  const currentEventUuid = useSelector((state: RootState) =>
    state.currentEvent?.data?.active ? state.currentEvent?.data?.uuid : '',
  );
  const [events, setEvents] = useState<EventsListItem[]>();
  const [prevEventUuid, setPrevEventUuid] = useState<string>('');
  const [eventUuid, setEventUuid] = useState<string>('');
  const dispatch = useDispatch();
  const userRoles = authToken.getUserRoles();
  const isMerchant = userRoles && userRoles.includes('ROLE_MERCHANT');

  useEffect(() => {
    setEventUuid(currentEventUuid);
  }, [currentEventUuid]);

  useEffect(() => {
    (async () => {
      dispatch(loaderSlice.actions.setLoaderState({ loading: true }));
      try {
        if (isMerchant) {
          const response = await getMerchantActiveEvents();
          setEvents(response?.data?.data?.items);
        } else {
          const response = await getActiveEvents();
          setEvents(response?.data?.data?.items);
        }
      } catch {
        dispatch(
          messageSlice.actions.showMessage({
            text: t('eventSelection.message.fetchEventListFail'),
            type: 'error',
          }),
        );
      } finally {
        dispatch(loaderSlice.actions.setLoaderState({ loading: false }));
      }
    })();
  }, []);

  const handleChangeEvent: SelectProps['onChange'] = (event) => {
    setEventUuid((prevState) => {
      setPrevEventUuid(prevState);
      return event.target.value as string;
    });
  };

  const handleClickActivate = async () => {
    if (eventUuid) {
      dispatch(loaderSlice.actions.setLoaderState({ loading: true }));
      try {
        await postSwitchEvent(eventUuid);
        dispatch(orderedProductsSlice.actions.clearOrderedProducts());
        dispatch(categoriesSlice.actions.resetCategoriesState());
        dispatch(currentEventSlice.actions.getCurrentEventRequest());
        dispatch(currentUserSlice.actions.getCurrentUserRequest());
        push(HOME_ROUTE);
      } catch {
        dispatch(
          messageSlice.actions.showMessage({
            text: t('eventSelection.message.changeEventFail'),
            type: 'error',
          }),
        );
        setEventUuid(prevEventUuid);
      } finally {
        dispatch(loaderSlice.actions.setLoaderState({ loading: false }));
      }
    }
  };

  const renderEventListItem = (item: EventsListItem) => (
    <MenuItem key={item.uuid} value={item.uuid}>
      {item.name}
    </MenuItem>
  );

  return (
    <CardLayout
      title={t('eventSelection.title')}
      footer={<EventSelectionFooter onClickActivate={handleClickActivate} />}
      onBackClick={currentEventUuid ? goBack : undefined}
      maxContentWidth={340}
      centered
    >
      <h3>{t('eventSelection.contentTitle')}</h3>
      <div className={classes.contentBox}>
        <Select
          className={classes.select}
          aria-label={t('eventSelection.chooseYourFestival')}
          inputProps={{ placeholder: t('placeOrder.chooseABoothNumber') }}
          value={eventUuid || ''}
          displayEmpty
          onChange={handleChangeEvent}
        >
          <MenuItem key="0" value="" disabled>
            <span className={classes.placeholder}>
              {t('eventSelection.chooseYourFestival')}
            </span>
          </MenuItem>
          {events?.map(renderEventListItem)}
        </Select>
      </div>
    </CardLayout>
  );
};

export default EventSelection;
