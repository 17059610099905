import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { FC } from 'react';
import noOutstandingOrdersIcon from 'assets/main-dish.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    noOutstanding: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    noOutstandingIcon: {
      width: 58,
      height: 58,
      marginTop: 6,
    },
    noOutstandingText: {
      marginTop: 4,
      fontSize: '1rem',
      lineHeight: '22px',
      fontWeight: 600,
      color: theme.customPalette.labelGray,
    },
  }),
);

type Props = {
  text: string;
};

const NoOrders: FC<Props> = ({ text }) => {
  const classes = useStyles();

  return (
    <div className={classes.noOutstanding}>
      <img
        className={classes.noOutstandingIcon}
        src={noOutstandingOrdersIcon}
      />
      <span className={classes.noOutstandingText}>{text}</span>
    </div>
  );
};

export default NoOrders;
