import { SvgIcon } from '@material-ui/core';
import {
  ChangeEventHandler,
  FC,
  MouseEventHandler,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { parseISO, format, formatISO } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { messageSlice, RootState } from 'core/store';
import { ReactComponent as CalendarIcon } from 'assets/calendar.svg';
import { ReactComponent as EmailIcon } from 'assets/email.svg';
import { ReactComponent as UserIcon } from 'assets/user.svg';
import { CardLayout } from 'shared/layouts';
import { Input, Button } from 'shared/components';
import { PROFILE_ROUTE } from 'shared/constants';
import { useRegisterStyles } from 'shared/styles';
import { useRoleNavigation } from 'shared/helpers';

const ChangeProfileData: FC = () => {
  const classes = useRegisterStyles();
  const { goBack } = useHistory();
  const { push } = useRoleNavigation();
  const dispatch = useDispatch();
  const { data } = useSelector((state: RootState) => state.currentUser);

  const { t, i18n } = useTranslation();
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const dateInputRef = useRef<HTMLInputElement>();

  const initialValues = useMemo(
    () => ({
      firstName: data?.firstName ?? '',
      lastName: data?.lastName ?? '',
      email: data?.email ?? '',
      dateOfBirth: data?.dateOfBirth ?? '',
    }),
    [],
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        firstName: Yup.string().required(t('validation.required')),
        lastName: Yup.string().required(t('validation.required')),
        email: Yup.string()
          .required(t('validation.required'))
          .email(t('validation.invalidEmail')),
        dateOfBirth: Yup.string().required(t('validation.required')),
      }),
    [i18n.language],
  );
  const handleFormSubmit = async (values: typeof initialValues) => {
    try {
      // TODO: add request for profile data change once backend is ready
      dispatch(
        messageSlice.actions.showMessage({
          text: t('message.savedChanges'),
          type: 'success',
        }),
      );
      push(PROFILE_ROUTE);
    } catch {
      dispatch(
        messageSlice.actions.showMessage({
          text: t('message.unknownError'),
          type: 'error',
        }),
      );
    }
  };

  const handleDateClick: MouseEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();
    setIsDatePickerOpen(true);
    dateInputRef.current?.blur();
  };

  const handleDatePickerClose = () => {
    setIsDatePickerOpen(false);
    dateInputRef.current?.blur();
  };

  return (
    <CardLayout
      title={t('profile.title')}
      contentTitle={t('changeProfileData.contentTitle')}
      contentDescription={t('changeProfileData.contentDescription')}
      onBackClick={goBack}
      backgroundColor="white"
      maxContentWidth={340}
      centered
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {({
          values,
          touched,
          errors,
          setFieldValue,
          setFieldTouched,
          handleSubmit,
          handleChange,
          handleBlur,
        }) => {
          const getFieldError = (field: keyof typeof initialValues) => {
            const isTouched = touched[field] || undefined;
            return isTouched && errors[field];
          };

          const handleFormFieldChange: ChangeEventHandler<HTMLInputElement> = (
            event,
          ) => {
            const fieldName = event.target.name as keyof typeof initialValues;
            if (touched[fieldName]) {
              handleChange(event);
            } else {
              handleChange(event);
              setFieldTouched(fieldName);
            }
          };

          return (
            <div className={classes.container}>
              <Input
                name="firstName"
                icon={
                  <SvgIcon component={UserIcon} className={classes.inputIcon} />
                }
                placeholder={t('register.firstNamePlaceholder')}
                value={values.firstName}
                className={classes.input}
                onChange={handleFormFieldChange}
                onBlur={handleBlur}
                error={getFieldError('firstName')}
              />
              <Input
                name="lastName"
                icon={
                  <SvgIcon component={UserIcon} className={classes.inputIcon} />
                }
                placeholder={t('register.lastNamePlaceholder')}
                value={values.lastName}
                className={classes.input}
                onChange={handleFormFieldChange}
                onBlur={handleBlur}
                error={getFieldError('lastName')}
              />
              <Input
                name="email"
                icon={
                  <SvgIcon
                    component={EmailIcon}
                    className={classes.inputIcon}
                  />
                }
                placeholder={t('register.emailPlaceholder')}
                value={values.email}
                className={classes.input}
                onChange={handleFormFieldChange}
                onBlur={handleBlur}
                error={getFieldError('email')}
              />
              <span className={classes.dobText}>
                {t('register.dobSectionTitle')}
              </span>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className={classes.datePicker}
                  views={['year', 'month', 'date']}
                  open={isDatePickerOpen}
                  openTo="year"
                  margin="normal"
                  id="date-picker-dialog"
                  format="yyyy-MM-dd"
                  value={null}
                  onChange={(_, value) => {
                    if (typeof value === 'string') {
                      const date = new Date(value);
                      setFieldValue('dateOfBirth', formatISO(date));
                    }
                  }}
                  onClose={handleDatePickerClose}
                />
              </MuiPickersUtilsProvider>
              <Input
                inputRef={dateInputRef}
                icon={
                  <SvgIcon
                    component={CalendarIcon}
                    className={classes.inputIcon}
                  />
                }
                placeholder="DD / MM / YYYY"
                className={classes.input}
                error={getFieldError('dateOfBirth')}
                value={
                  values.dateOfBirth &&
                  format(parseISO(values.dateOfBirth), 'dd / MM / yyyy')
                }
                onClick={handleDateClick}
              />
              <Button
                className={classes.registerButton}
                text={t('changeProfileData.saveChanges')}
                primary
                onClick={handleSubmit}
              />
            </div>
          );
        }}
      </Formik>
    </CardLayout>
  );
};

export default ChangeProfileData;
