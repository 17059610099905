import { ComponentProps } from 'react';
import { OrderCard } from 'shared/components';
import { OrderDraft, OrderItem } from 'shared/types';

const getOrderDraftProps = (orderDraft: OrderDraft | null) => {
  if (orderDraft) {
    const draftDetails = orderDraft.products.reduce(
      (acc, value) => ({
        orderBoothTypes: acc.orderBoothTypes.includes(value.boothTypeId)
          ? acc.orderBoothTypes
          : [...acc.orderBoothTypes, value.boothTypeId],
        orderItems: [
          ...acc.orderItems,
          {
            ...value,
            available: true,
          },
        ],
      }),
      { orderBoothTypes: [], orderItems: [] } as {
        orderBoothTypes: number[];
        orderItems: OrderItem[];
      },
    );

    const order: ComponentProps<typeof OrderCard> = {
      orderNumber: undefined,
      orderDate: undefined,
      orderStatus: undefined,
      paymentStatus: orderDraft.paymentStatus,
      isDraft: true,
      orderTotalAmount: orderDraft.totalAmount,
      variant: 'visitor',
      ...draftDetails,
    };

    return order;
  }

  return null;
};

export default getOrderDraftProps;
