import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getDraft, getVisitorRecentOrders } from 'core/requests';
import { loaderSlice, messageSlice, RootState } from 'core/store';
import { OrderCard, Status } from 'shared/components';
import { CHECKOUT_ROUTE, HOME_ROUTE } from 'shared/constants';
import { getOrderDraftProps, useRoleNavigation } from 'shared/helpers';
import { CardLayout } from 'shared/layouts';
import { OrderDraft, OrderHistoryItem, PaymentStatus } from 'shared/types';
import Footer from './Footer';
import useStyles from './useStyles';

const Payment: FC = () => {
  const classes = useStyles({});
  const { t } = useTranslation();
  const { push } = useRoleNavigation();
  const dispatch = useDispatch();
  const [paymentOrders, setPaymentOrders] = useState<OrderHistoryItem[]>([]);
  const [paymentStatus, setPaymentStatus] =
    useState<PaymentStatus | undefined>();
  const [draft, setDraft] = useState<OrderDraft | null>(null);
  const loading = useSelector((state: RootState) => state.loader.loading);
  const orderDraftProps = useMemo(() => getOrderDraftProps(draft), [draft]);

  useEffect(() => {
    const fetchVisitorRecentOrders = async () => {
      try {
        const {
          data: { data },
        } = await getVisitorRecentOrders();
        setPaymentOrders(data.items);
        setPaymentStatus(data.items[0]?.paymentStatus);
      } catch (error) {
        dispatch(
          messageSlice.actions.showMessage({
            text: t('payment.message.fetchNewOrdersFail'),
            type: 'error',
          }),
        );
      }
    };

    const fetchDraft = async () => {
      try {
        const {
          data: { data },
        } = await getDraft();
        setDraft(data);
        setPaymentStatus(data.paymentStatus);
      } catch (error) {
        if (error?.response?.status !== 404) {
          dispatch(
            messageSlice.actions.showMessage({
              text: error.message,
              type: 'error',
            }),
          );
        } else {
          fetchVisitorRecentOrders();
        }
      }
    };

    (async () => {
      dispatch(loaderSlice.actions.setLoaderState({ loading: true }));
      await fetchDraft();
      dispatch(loaderSlice.actions.setLoaderState({ loading: false }));
    })();
  }, []);

  const getPaymentDescription = () => {
    switch (paymentStatus) {
      case PaymentStatus.Paid:
        return {
          contentTitle: t('payment.success.contentTitle'),
          contentUnderTitle: (
            <Status text={t('payment.success.subtitle')} status="success" />
          ),
          description: t('payment.success.description'),
        };
      case PaymentStatus.Open:
        return {
          contentTitle: t('payment.pending.contentTitle'),
          contentUnderTitle: (
            <Status text={t('payment.pending.subtitle')} status="warning" />
          ),
          description: t('payment.pending.description'),
        };
      case undefined:
        return loading
          ? {
              contentTitle: t('payment.loading.contentTitle'),
              contentUnderTitle: (
                <Status text={t('payment.loading.subtitle')} status="warning" />
              ),
              description: t('payment.loading.description'),
            }
          : {
              contentTitle: '',
              contentUnderTitle: '',
              description: '',
            };
      default:
        return {
          contentTitle: t('payment.failure.contentTitle'),
          contentUnderTitle: (
            <Status text={t('payment.failure.subtitle')} status="failure" />
          ),
          description: t('payment.failure.description'),
        };
    }
  };

  const handleClickGoBack = () => {
    push(`${CHECKOUT_ROUTE}?return=${HOME_ROUTE}`);
  };

  const renderOrder = (order: OrderHistoryItem) => (
    <OrderCard
      key={order.uuid}
      orderBoothTypes={[order.boothTypeId]}
      orderItems={[]}
      orderNumber={order.number}
      orderStatus={order.deliveryStatus}
      paymentStatus={order.paymentStatus}
      orderTotalAmount={order.amount}
      orderDate={order.created}
      variant="visitor"
    />
  );

  const paymentDescription = getPaymentDescription();

  return (
    <CardLayout
      title={t('payment.title')}
      contentTitle={paymentDescription.contentTitle}
      contentUnderTitle={paymentDescription.contentUnderTitle}
      contentDescription={paymentDescription.description}
      onBackClick={handleClickGoBack}
      footer={
        <Footer
          paymentStatus={paymentStatus}
          draftUuid={(draft && draft.id) || undefined}
        />
      }
      maxContentWidth={750}
      centered
    >
      {(Boolean(paymentOrders.length) || orderDraftProps) && (
        <section>
          <h3>
            {t(orderDraftProps ? 'payment.pendingOrders' : 'payment.newOrders')}
          </h3>
          <div className={classes.ordersContainer}>
            {orderDraftProps ? (
              <OrderCard {...orderDraftProps} />
            ) : (
              paymentOrders.map(renderOrder)
            )}
          </div>
        </section>
      )}
    </CardLayout>
  );
};

export default Payment;
