import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { MAX_INTERACTIVE_ELEMENT_WIDTH } from 'shared/constants';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '22px',
        alignSelf: 'center',
        width: '100%',
      },
      inputIcon: {
        fontSize: '18px',
        color: theme.palette.common.black,
      },
      input: {
        width: '100%',
      },
      dobText: {
        fontSize: '11px',
        fontWeight: 700,
        color: theme.customPalette.labelGray,
        display: 'block',
        width: '100%',
        textAlign: 'left',
        marginLeft: '7px',
        marginBottom: '7px',
        textTransform: 'uppercase',
        maxWidth: `${MAX_INTERACTIVE_ELEMENT_WIDTH}px`,
      },
      tosContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: '14px',
        width: '100%',
        alignItems: 'center',
        marginBottom: '21px',
        maxWidth: `${MAX_INTERACTIVE_ELEMENT_WIDTH}px`,
      },
      checkIcon: {
        fontSize: '18px',
        color: theme.palette.primary.main,
        marginRight: '14px',
      },
      tosText: {
        fontSize: '12px',
        fontWeight: 600,
        color: theme.palette.secondary.main,
      },
      tosLink: {
        textDecoration: 'underline',
      },
      registerButton: {
        marginBottom: '23px',
      },
      datePicker: {
        display: 'none',
      },
    }),
  { index: 1 },
);

export default useStyles;
