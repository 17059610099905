import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      alignSelf: 'center',
      padding: '0 21px',
      width: '100%',
    },
    inputIcon: {
      fontSize: '18px',
    },
    input: {
      width: '100%',
    },
    currentPasswordInput: {
      width: '100%',
      marginBottom: '10px',
    },
    sectionTitle: {
      fontSize: '11px',
      fontWeight: 700,
      color: theme.customPalette.labelGray,
      textTransform: 'uppercase',
      display: 'block',
      marginTop: '3px',
      marginBottom: '7px',
    },
    button: {
      marginBottom: '23px',
    },
  }),
);

export default useStyles;
