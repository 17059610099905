import DeleteIcon from '@material-ui/icons/Delete';
import clsx from 'clsx';
import { TFunction } from 'i18next';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteBoothTypeImages, postBoothTypeImages } from 'core/requests';
import { messageSlice } from 'core/store';
import { Button, ConfirmDialog } from 'shared/components';
import AddButton from './AddButton';
import Upload from './Upload';
import useStyles from '../useStyles';
import { Photo } from '../index';

type Props = {
  editable: boolean;
  photos: Photo[];
  t: TFunction;
  fetchMerchantBoothInfo: () => void;
};

const BootTypeImages: FC<Props> = ({
  editable,
  photos,
  t,
  fetchMerchantBoothInfo,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [uploading, setUploading] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [selectedPictures, setSelectedPictures] = useState<string[]>([]);

  const uploadFiles = async (files: FileList | null) => {
    if (files?.length) {
      setUploading(true);
      const formData = new FormData();

      Array.from(files).forEach((file) => {
        formData.append('boothImages[]', file);
      });

      try {
        await postBoothTypeImages(formData);
        fetchMerchantBoothInfo();
      } catch {
        dispatch(
          messageSlice.actions.showMessage({
            text: t('merchantProfile.message.uploadImagesFail'),
            type: 'error',
          }),
        );
      } finally {
        setUploading(false);
      }
    }
  };

  const handleChangeImageInput = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    uploadFiles(event.target.files);
  };

  const handleClickPicture = (original: string) => () => {
    setSelectedPictures((prevState) => {
      if (prevState.includes(original)) {
        return prevState.filter((picture) => picture !== original);
      }
      return [...prevState, original];
    });
  };

  const handleDeleteImages = async () => {
    setDeleteConfirmationOpen(true);
  };

  const handleConfirmDeleteImages = async () => {
    setDeleteConfirmationOpen(false);
    if (selectedPictures.length) {
      try {
        await deleteBoothTypeImages(selectedPictures);
        setSelectedPictures([]);
        fetchMerchantBoothInfo();
      } catch {
        dispatch(
          messageSlice.actions.showMessage({
            text: t('merchantProfile.message.deleteImagesFail'),
            type: 'error',
          }),
        );
      } finally {
        setUploading(false);
      }
    }
  };

  const handleCancelDeleteImages = async () => {
    setDeleteConfirmationOpen(false);
  };

  const renderPicture = ({ original, src }: Photo) => (
    <div
      className={clsx(
        classes.pictureBox,
        selectedPictures.includes(original) && classes.selected,
      )}
      onClick={handleClickPicture(original)}
    >
      <img key={original} className={classes.picture} src={src} />
    </div>
  );

  return (
    <section className={classes.section}>
      <span className={classes.sectionTitleText}>
        {t('merchantProfile.pictures')}
      </span>
      <div className={classes.pictureRow}>
        {photos.map(renderPicture)}
        {editable && (
          <Upload
            className={classes.upload}
            onChange={handleChangeImageInput}
            loading={uploading}
          >
            <AddButton aria-label="upload picture" />
          </Upload>
        )}
      </div>
      {Boolean(selectedPictures.length) && (
        <Button
          icon={<DeleteIcon />}
          onClick={handleDeleteImages}
          text={t('merchantProfile.deletePictures')}
        />
      )}
      <ConfirmDialog
        open={deleteConfirmationOpen}
        title={t('merchantProfile.confirmDeleteTitle')}
        description={t('merchantProfile.confirmDeleteDescription')}
        okText={t('common.yes')}
        cancelText={t('common.no')}
        onCancel={handleCancelDeleteImages}
        onConfirm={handleConfirmDeleteImages}
      />
    </section>
  );
};

export default BootTypeImages;
