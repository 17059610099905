import { PayloadAction } from '@reduxjs/toolkit';
import { OrderDraft, PaymentStatus, Product } from 'shared/types';
import { OrderedProductsState } from './index';

const reducers = {
  addOrderedProduct: (
    state: OrderedProductsState,
    { payload }: PayloadAction<Product>,
  ): OrderedProductsState => {
    const product = state.products[payload.uuid];
    if (product) {
      const newProducts = Object.values(state.products).reduce((acc, value) => {
        if (value.uuid === product.uuid) {
          return {
            ...acc,
            [product.uuid]: { ...product, quantity: product.quantity + 1 },
          };
        }
        return { ...acc, [value.uuid]: value };
      }, {} as OrderedProductsState['products']);

      return {
        ...state,
        products: newProducts,
        total: state.total + product.amount.value,
        defaultCurrencyTotal:
          state.defaultCurrencyTotal + product.defaultCurrencyAmount.value,
      };
    }
    return {
      ...state,
      products: {
        ...state.products,
        [payload.uuid]: { ...payload, quantity: 1 },
      },
      total: state.total + payload.amount.value,
      defaultCurrencyTotal:
        state.defaultCurrencyTotal + payload.defaultCurrencyAmount.value,
    };
  },
  subtractOrderedProduct: (
    state: OrderedProductsState,
    { payload }: PayloadAction<string>,
  ): OrderedProductsState => {
    const product = state.products[payload];
    if (!product?.quantity) {
      return state;
    }

    const newProducts = Object.values(state.products).reduce((acc, value) => {
      if (value.uuid === product.uuid) {
        return {
          ...acc,
          [product.uuid]: { ...product, quantity: product.quantity - 1 },
        };
      }
      return { ...acc, [value.uuid]: value };
    }, {} as OrderedProductsState['products']);

    return {
      ...state,
      products: newProducts,
      total: state.total - product.amount.value,
      defaultCurrencyTotal:
        state.defaultCurrencyTotal - product.defaultCurrencyAmount.value,
    };
  },
  deleteOrderedProduct: (
    state: OrderedProductsState,
    { payload }: PayloadAction<string>,
  ): OrderedProductsState => {
    const product = state.products[payload];
    if (!product) {
      return state;
    }
    const productsCopy = { ...state.products };
    delete productsCopy[payload];

    return {
      ...state,
      products: productsCopy,
      total: state.total - product.quantity * product.amount.value,
      defaultCurrencyTotal:
        state.defaultCurrencyTotal -
        product.quantity * product.defaultCurrencyAmount.value,
    };
  },
  getOrderDraftRequest: (
    state: OrderedProductsState,
  ): OrderedProductsState => ({ ...state, loading: true }),
  getOrderDraftSuccess: (
    _: OrderedProductsState,
    { payload }: PayloadAction<OrderDraft>,
  ): OrderedProductsState => {
    const newProductsState = payload.products.reduce(
      (acc, value) => ({
        products: {
          ...acc.products,
          [value.uuid]: { ...value, available: true },
        },
        total: payload.totalAmount.value,
        defaultCurrencyTotal: payload.defaultCurrencyTotalAmount.value,
        paymentStatus: payload.paymentStatus,
      }),
      {} as Omit<OrderedProductsState, 'loading' | 'isDraft'>,
    );

    return { ...newProductsState, isDraft: true, loading: false };
  },
  getOrderDraftEmptySuccess: (
    state: OrderedProductsState,
  ): OrderedProductsState => ({ ...state, isDraft: false, loading: false }),
  getOrderDraftFailure: (
    state: OrderedProductsState,
  ): OrderedProductsState => ({ ...state, loading: false }),
  markAsDraft: (state: OrderedProductsState): OrderedProductsState => ({
    ...state,
    isDraft: true,
  }),
  clearOrderedProducts: (): OrderedProductsState => ({
    loading: false,
    isDraft: false,
    products: {},
    total: 0,
    defaultCurrencyTotal: 0,
    paymentStatus: PaymentStatus.None,
  }),
};

export default reducers;
