import { FC } from 'react';
import {
  createStyles,
  makeStyles,
  Select as MuiSelect,
  SelectProps,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import SimpleInput from './SimpleInput';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      margin: '25px 0',
      '& .MuiSelect-select.MuiSelect-select': {
        paddingRight: '40px',
      },
      '& .MuiSelect-icon': {
        right: 16,
        color: '#5e7b95',
      },
    },
  }),
);

const Select: FC<SelectProps> = ({
  className,
  fullWidth = true,
  input = <SimpleInput />,
  IconComponent = ExpandMoreIcon,
  ...props
}) => {
  const classes = useStyles();

  return (
    <MuiSelect
      className={clsx(className, classes.root)}
      fullWidth={fullWidth}
      input={input}
      IconComponent={IconComponent}
      {...props}
    />
  );
};

export default Select;
