import axios from 'axios';
import i18next from 'i18next';
import { messageSlice, store } from 'core/store';
import { authToken } from 'shared/helpers';
import { getCustomErrorCode, signOut } from 'shared/helpers';

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_URL || ''}/api`,
  headers: {
    'x-api-key': process.env.REACT_APP_API_KEY,
  },
});

axiosInstance.interceptors.request.use(async (config) => {
  const token = authToken.getToken();
  if (token) {
    config.headers.Authorization = `bearer ${token}`;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (r) => r,
  async (error) => {
    if (
      error?.request?.status === 401 &&
      !error.request.requestURL?.includes('/users/token') &&
      !error.request.responseURL?.includes('/active-events')
    ) {
      const errorCode = getCustomErrorCode(error);
      if (errorCode === 2137) {
        setTimeout(() => {
          store.dispatch(
            messageSlice.actions.showMessage({
              text: i18next.t('message.eventHasBecomeInactive'),
              type: 'error',
            }),
          );
        }, 1);
      }
      setTimeout(signOut, 3000);
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
