import { ButtonBase, Link, SvgIcon } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { ReactComponent as DashboardIcon } from 'assets/dashboard.svg';
import { ReactComponent as EditIcon } from 'assets/edit.svg';
import { ReactComponent as HomeIcon } from 'assets/home.svg';
import { ReactComponent as LogoutIcon } from 'assets/logout.svg';
import { ReactComponent as MusicIcon } from 'assets/music.svg';
import { ReactComponent as OrderHistoryIcon } from 'assets/order-history.svg';
import { ReactComponent as QRCodeIcon } from 'assets/qr-code.svg';
import { ReactComponent as UserIcon } from 'assets/user.svg';
import { CreditCount } from 'shared/components';
import {
  EVENT_SELECTION_ROUTE,
  HOME_ROUTE,
  MY_CREDITS_ROUTE,
  MY_QR_ROUTE,
  PROFILE_ROUTE,
  ORDER_HISTORY_ROUTE,
} from 'shared/constants';
import { signOut, useRoleNavigation, useLanguage } from 'shared/helpers';
import i18n from '../../i18n/i18n';
import BottomDrawer from './BottomDrawer';
import MenuItem from './MenuItem';
import TripleCircle from './TripleCircle';
import useStyles from './useStyles';

const getTermsAndConditionsUrl = (languageCode: string) =>
  `${process.env.REACT_APP_URL}/tos/terms-and-conditions-${languageCode}.pdf`;

type Props = {
  isOpen: boolean;
  hideMenu: () => void;
  isMerchant: boolean;
};

const AppMenu: FC<Props> = ({ isOpen, hideMenu, isMerchant }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { location, push } = useRoleNavigation();
  const {
    currentBooth,
    currency,
    currentUserCredits,
    eventName,
    userFullName,
  } = useSelector((state: RootState) => ({
    currentBooth: state.merchantBooths.data?.items.find(
      (booth) => booth.id === state.currentUser.data?.merchantBoothId,
    ),
    eventName: state.currentEvent.data?.name,
    userFullName: `${state.currentUser.data?.firstName || ''} ${
      state.currentUser.data?.lastName || ''
    }`,
    currentUserCredits: state.currentUser.data?.credits,
    currency: state.currentEvent.data?.currency || '',
  }));
  const { currentLanguage, changeLanguage, languages } = useLanguage();
  const [boothSelectionOpen, setBoothSelectionOpen] = useState(false);
  const isActiveLink = (path: string) =>
    location.pathname.replace('merchant/', '') === path;

  const handleLanguageClick = (languageCode: string) => () => {
    changeLanguage(languageCode);
  };

  const handleLogoutClick = () => {
    signOut();
    hideMenu();
  };

  const handleNavigationLinkClick = (to: string) => () => {
    push(to);
    hideMenu();
  };

  const renderLanguage = (item: typeof languages[0]) => (
    <Link
      key={item.code}
      component="button"
      className={classes.languageButton}
      onClick={handleLanguageClick(item.code)}
    >
      <span
        className={
          currentLanguage === item.code
            ? classes.languageTextActive
            : classes.languageTextInactive
        }
      >
        {item.name}
      </span>
    </Link>
  );

  const handleClickChangeAccount = () => {
    setBoothSelectionOpen(true);
  };

  const handleCloseAccountSelect = () => {
    setBoothSelectionOpen(false);
  };

  const handleClickEditEvent = () => {
    push(EVENT_SELECTION_ROUTE);
    hideMenu();
  };

  return isOpen ? (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <div className={classes.userContainer}>
          <div className={classes.userIconContainer}>
            <SvgIcon component={UserIcon} className={classes.userIcon} />
          </div>
          <span className={classes.userNameText}>
            {isMerchant ? currentBooth?.name || '' : userFullName}
          </span>
        </div>
        <div className={classes.eventContainer}>
          {/* TODO: replace with legit event image */}
          <div className={classes.userIconContainer} />
          <div className={classes.eventRightContainer}>
            <span className={classes.eventNameText}>{eventName || ''}</span>
            <Link
              color="inherit"
              component="button"
              onClick={handleClickEditEvent}
              underline="always"
              className={classes.eventChangeContainer}
            >
              <SvgIcon component={EditIcon} className={classes.editIcon} />
              {t('appMenu.changeEvent')}
            </Link>
          </div>
        </div>
        {isMerchant && (
          <ButtonBase
            onClick={handleClickChangeAccount}
            className={classes.accountSelect}
          >
            <span className={classes.accountContainer}>
              <TripleCircle />
              <span className={classes.eventRightContainer}>
                <span className={classes.eventNameText}>
                  {t('appMenu.changeAccount')}
                </span>
              </span>
              <ExpandMore color="inherit" />
            </span>
          </ButtonBase>
        )}
      </div>
      <nav>
        <div className={classes.sectionContainer}>
          <MenuItem
            icon={isMerchant ? DashboardIcon : HomeIcon}
            text={t(isMerchant ? 'appMenu.dashboard' : 'appMenu.home')}
            onClick={handleNavigationLinkClick(HOME_ROUTE)}
            isActive={isActiveLink(HOME_ROUTE)}
          />
          <MenuItem
            icon={UserIcon}
            text={t('appMenu.profile')}
            onClick={handleNavigationLinkClick(PROFILE_ROUTE)}
            isActive={isActiveLink(PROFILE_ROUTE)}
          />
          {!isMerchant && (
            <>
              <MenuItem
                icon={MusicIcon}
                text={t('appMenu.credits')}
                onClick={handleNavigationLinkClick(MY_CREDITS_ROUTE)}
                isActive={isActiveLink(MY_CREDITS_ROUTE)}
              />
              <CreditCount
                title={t('myCredits.creditCountTitle')}
                amount={currentUserCredits ?? 0}
                currency={currency}
                className={classes.creditCount}
                secondVariant
              />
            </>
          )}
          <MenuItem
            icon={OrderHistoryIcon}
            text={t(isMerchant ? 'appMenu.orderOverview' : 'appMenu.history')}
            onClick={handleNavigationLinkClick(ORDER_HISTORY_ROUTE)}
            isActive={isActiveLink(ORDER_HISTORY_ROUTE)}
          />
        </div>
        {!isMerchant && (
          <div className={classes.sectionContainer}>
            <MenuItem
              icon={QRCodeIcon}
              text={t('appMenu.myQr')}
              onClick={handleNavigationLinkClick(MY_QR_ROUTE)}
              isActive={isActiveLink(MY_QR_ROUTE)}
            />
          </div>
        )}
        <div className={classes.sectionContainer}>
          <MenuItem
            icon={LogoutIcon}
            text={t('appMenu.logout')}
            onClick={handleLogoutClick}
          />
        </div>
        <div className={classes.languagesContainer}>
          {languages.map(renderLanguage)}
        </div>
        {!isMerchant && (
          <div className={classes.bottomContainer}>
            <Link
              href={getTermsAndConditionsUrl(i18n.language)}
              color="inherit"
              target="_blank"
            >
              {t('appMenu.termsAndConditions')}
            </Link>
          </div>
        )}
      </nav>
      {boothSelectionOpen && <BottomDrawer close={handleCloseAccountSelect} />}
    </div>
  ) : null;
};

export default AppMenu;
