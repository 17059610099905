import { OrderStatus, OrderStatusExtended, PaymentStatus } from 'shared/types';

const getOrderStatusExtended = (
  status: OrderStatus | undefined,
  paymentStatus: PaymentStatus | undefined,
  isDraft?: boolean,
) => {
  if (isDraft) {
    return OrderStatusExtended.Draft;
  }
  if (status === OrderStatus.Pending && paymentStatus !== PaymentStatus.Paid) {
    return OrderStatusExtended.Pending;
  }
  if (status === OrderStatus.Pending && paymentStatus === PaymentStatus.Paid) {
    return OrderStatusExtended.Paid;
  }
  if (status === OrderStatus.Retrieved) {
    return OrderStatusExtended.Retrieved;
  }
  if (status === OrderStatus.Helpdesk) {
    return OrderStatusExtended.Helpdesk;
  }
  if (status === OrderStatus.Cancelled) {
    return OrderStatusExtended.Cancelled;
  }
  if (status === OrderStatus.WrongBooth) {
    return OrderStatusExtended.WrongBooth;
  }
  return OrderStatusExtended.Unknown;
};

export default getOrderStatusExtended;
