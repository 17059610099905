import { createStyles, makeStyles } from '@material-ui/core';
import PaymentIcon from '@material-ui/icons/Payment';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { orderedProductsSlice } from 'core/store';
import { Button, Spacer } from 'shared/components';
import { PAYMENT_METHOD_SELECTION_ROUTE, HOME_ROUTE } from 'shared/constants';
import { useRoleNavigation } from 'shared/helpers';

const useStyles = makeStyles(() =>
  createStyles({
    footer: {
      display: 'flex',
      height: '100%',
    },
    buttonsContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      margin: '16px 18px',
    },
    cancelButton: {
      width: 'auto',
      minWidth: 108,
      color: 'white',
    },
  }),
);

type Props = {
  isCrew?: boolean;
};

const MerchantFooter: FC<Props> = ({ isCrew }) => {
  const classes = useStyles();
  const { push } = useRoleNavigation();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleCancelClick = () => {
    push(HOME_ROUTE);
    dispatch(orderedProductsSlice.actions.clearOrderedProducts());
  };

  const handlePaymentClick = () => {
    push(PAYMENT_METHOD_SELECTION_ROUTE);
  };

  return (
    <div className={classes.footer}>
      <div className={classes.buttonsContainer}>
        <Button
          className={classes.cancelButton}
          rounded
          text={t('common.cancel')}
          onClick={handleCancelClick}
          color={isCrew ? 'black' : 'white'}
        />
        <Spacer width={18} />
        <Button
          primary
          text={t('merchant.boothMenu.payment')}
          onClick={handlePaymentClick}
          icon={<PaymentIcon />}
        />
      </div>
    </div>
  );
};

export default MerchantFooter;
