import { FC } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: '23px 18px 31px 18px',
      textAlign: 'center',
    },
    title: {
      margin: '0 0 6px 0',
      fontSize: '1.3125rem',
      fontWeight: 700,
      color: 'white',
      letterSpacing: '0.86px',
    },
    subtitle: {
      fontSize: '1.125rem',
      fontWeight: 700,
      color: '#2c7ec1',
      letterSpacing: '1px',
    },
  }),
);

const MerchantHeader: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <h2 className={classes.title}>{t('booth.menu.merchant.title')}</h2>
      <div className={classes.subtitle}>
        {t('booth.menu.merchant.subtitle')}
      </div>
    </div>
  );
};

export default MerchantHeader;
