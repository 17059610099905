import { SvgIcon, Switch } from '@material-ui/core';
import { Cancel, CheckCircle } from '@material-ui/icons';
import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as UserIcon } from 'assets/user.svg';
import { ReactComponent as EmailIcon } from 'assets/email.svg';
import { getMerchantBoothInfo, putBoothStatus } from 'core/requests';
import {
  loaderSlice,
  merchantBoothsSlice,
  messageSlice,
  RootState,
} from 'core/store';
import { Button, Card, InfoItem } from 'shared/components';
import { usePermissions } from 'features/Auth';
import { getImageOrFallback, getImageUrl, signOut } from 'shared/helpers';
import { CardLayout } from 'shared/layouts';
import { MerchantBoothInfo } from 'shared/types';
import LabeledInfo from './LabeledInfo';
import BoothTypeImages from './BoothTypeImages';
import BoothTypeAboutSection from './BoothTypeAboutSection';
import BoothTypeStatusTextSection from './BoothTypeStatusTextSection';
import OrderingHours from './OrderingHours';
import useStyles from './useStyles';

export type Photo = {
  original: string;
  src: string;
};

const MerchantProfile: FC = () => {
  const { isAllowedTo } = usePermissions();
  const classes = useStyles();
  const { goBack } = useHistory();
  const { t } = useTranslation();
  const currentUser = useSelector((state: RootState) => state.currentUser.data);
  const [isBoothOpen, setIsBoothOpen] = useState(false);
  const [boothInfo, setBoothInfo] = useState<MerchantBoothInfo>();
  const [photos, setPhotos] = useState<Photo[]>([]);
  const dispatch = useDispatch();
  const merchantBoothId = currentUser?.merchantBoothId;

  const fetchMerchantBoothInfo = async () => {
    if (merchantBoothId) {
      try {
        const { data } = await getMerchantBoothInfo(merchantBoothId);
        setBoothInfo(data.data);
        setIsBoothOpen(data.data.open);
      } catch {
        dispatch(
          messageSlice.actions.showMessage({
            text: t('message.boothMenuFetchError'),
            type: 'error',
          }),
        );
      }
    }
  };

  useEffect(() => {
    fetchMerchantBoothInfo();
  }, [merchantBoothId]);

  useEffect(() => {
    if (boothInfo?.typeImages?.length) {
      (async () => {
        const images = await Promise.all(
          boothInfo.typeImages.map(async (image) => ({
            original: image,
            src: await getImageOrFallback(
              image,
              '/images/booth/booth-dummy.png',
            ),
          })),
        );
        setPhotos(images.filter((image) => Boolean(image.src)));
      })();
    }
  }, [boothInfo?.typeImages]);

  const handleChangeBoothStatus = async () => {
    if (merchantBoothId) {
      const isOpen = isBoothOpen;
      setIsBoothOpen(!isOpen);
      dispatch(loaderSlice.actions.setLoaderState({ loading: true }));
      try {
        await putBoothStatus(merchantBoothId);
        dispatch(merchantBoothsSlice.actions.getMerchantBoothsRequest());
      } catch (error) {
        setIsBoothOpen(isOpen);
        dispatch(
          messageSlice.actions.showMessage({
            text: t('merchantProfile.message.editBoothStatusFail'),
            type: 'error',
          }),
        );
      } finally {
        dispatch(loaderSlice.actions.setLoaderState({ loading: false }));
      }
    }
  };

  return (
    <CardLayout
      title={t('merchantProfile.title')}
      onBackClick={goBack}
      maxContentWidth={750}
    >
      <Trans
        className={classes.sectionTitleText}
        parent="span"
        i18nKey="merchantProfile.welcome"
        values={{ name: currentUser?.firstName ?? '' }}
        components={[<span key="welcome" className={classes.nameText} />]}
      />
      <div className={classes.boothInfoContainer}>
        <div className={classes.avatarContainer}>
          <SvgIcon component={UserIcon} className={classes.avatarIcon} />
        </div>
        <LabeledInfo
          label={t('merchantProfile.boothNameLabel')}
          text={boothInfo?.name ?? ''}
        />
      </div>
      <Button
        color="#7b8084"
        text={t('merchantProfile.signOutButton')}
        className={classes.signOutButton}
        onClick={signOut}
      />
      <Card className={classes.card}>
        <span className={classes.closeBoothText}>
          {t(`merchantProfile.${isBoothOpen ? 'closeBooth' : 'openBooth'}`)}
        </span>
        <div className={classes.switchContainer}>
          <span className={classes.switchTextActive}>
            {t('merchantProfile.switchOpenLabel')}
          </span>
          <Switch
            checked={isBoothOpen}
            onChange={handleChangeBoothStatus}
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
              root: classes.switchRoot,
              switchBase: classes.switchBase,
              thumb: classes.thumb,
              track: classes.track,
              checked: classes.checked,
            }}
            checkedIcon={<CheckCircle fontSize="inherit" />}
            icon={<Cancel fontSize="inherit" />}
          />
          <span className={classes.switchTextInactive}>
            {t('merchantProfile.switchCloseLabel')}
          </span>
        </div>
      </Card>

      <BoothTypeStatusTextSection
        boothInfo={boothInfo}
        fetchMerchantBoothInfo={fetchMerchantBoothInfo}
      />

      <span className={classes.sectionTitleText}>
        {t('merchantProfile.contactInfo')}
      </span>
      <InfoItem
        icon={UserIcon}
        text={`${currentUser?.firstName ?? ''} ${currentUser?.lastName ?? ''}`}
      />
      <InfoItem icon={EmailIcon} text={currentUser?.email ?? ''} />

      <BoothTypeAboutSection
        boothInfo={boothInfo}
        fetchMerchantBoothInfo={fetchMerchantBoothInfo}
      />

      <BoothTypeImages
        photos={photos}
        editable={isAllowedTo('boothType.images.edit')}
        t={t}
        fetchMerchantBoothInfo={fetchMerchantBoothInfo}
      />

      <OrderingHours
        merchantBoothId={merchantBoothId}
        boothInfo={boothInfo}
        fetchMerchantBoothInfo={fetchMerchantBoothInfo}
      />

      <span
        className={classes.sectionTitleText}
        style={{ marginBottom: '17px' }}
      >
        {t('merchantProfile.festivalInformation')}
      </span>
      <LabeledInfo
        className={classes.festivalInformationLabeledInfo}
        label={t('merchantProfile.organizationNameLabel')}
        text={boothInfo?.organizationName ?? ''}
      />
      <LabeledInfo
        className={classes.festivalInformationLabeledInfo}
        label={t('merchantProfile.boothNumberLabel')}
        text={String(boothInfo?.number || '')}
      />
      <LabeledInfo
        className={classes.festivalInformationLabeledInfo}
        label={t('merchantProfile.locationLabel')}
        text={boothInfo?.zone || ''}
      />
      <div className={classes.categoryContainer}>
        <div className={classes.categoryIconContainer}>
          <img
            src={
              boothInfo?.categories[0]?.iconUrl &&
              getImageUrl(boothInfo?.categories[0]?.iconUrl)
            }
            className={classes.avatarIcon}
          />
        </div>
        <LabeledInfo
          label={t('merchantProfile.categoryLabel')}
          text={
            boothInfo?.categories.map((category) => category.name).join(', ') ??
            ''
          }
        />
      </div>
    </CardLayout>
  );
};

export default MerchantProfile;
