import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'center',
    },
    menuItemsContainer: {
      boxSizing: 'border-box',
      width: '100%',
      maxWidth: '500px',
      margin: '22px 0 0 0',
      padding: '0 20px',
    },
    footer: {
      height: 38,
    },

    bodyContainer: {
      boxSizing: 'border-box',
      margin: '0px auto 10px auto',
      padding: '30px',
      borderRadius: '8px',
      backgroundColor: theme.palette.common.white,
      boxShadow: '0 0 21px -2px rgba(0,0,0,0.2)',
    },
    qrCode: {
      width: '100%',
      height: 'auto',
    },
    descriptionContainer: {
      margin: '38px 0 28px 0',
    },
    descriptionTitle: {
      margin: '0 0 26px 0',
      fontSize: '21px',
      fontWeight: 800,
      color: '#393F44',
    },
    description: {
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '22px',
      color: '#7B8084',
    },
  }),
);

export default useStyles;
