import { createStyles, makeStyles } from '@material-ui/core';
import { FC } from 'react';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: 'white',
      borderTopLeftRadius: '25px',
      borderTopRightRadius: '25px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '24px 21px 30px 21px',
      boxShadow: '0 0 11px -2px rgba(0,0,0,0.14)',
      '& > *:not(:last-child)': {
        marginBottom: 20,
      },
    },
  }),
);

const LayoutFooter: FC = (props) => {
  const classes = useStyles();

  return <div className={classes.root} {...props} />;
};

export default LayoutFooter;
