import { ButtonBase, Radio } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import { FunctionComponent } from 'react';
import { Card } from 'shared/components';
import { getImageUrl } from 'shared/helpers';
import { PaymentMethod, PaymentMethodType } from 'shared/types';
import useStyles from './useStyles';

type Props = {
  paymentMethod: PaymentMethod;
  isSelected: boolean;
  onClickCard: (paymentMethodId: PaymentMethodType) => void;
  disabled: boolean;
};

const MethodCard: FunctionComponent<Props> = ({
  paymentMethod,
  isSelected,
  onClickCard,
  disabled,
}) => {
  const classes = useStyles({});

  const handleClickCard = () => {
    onClickCard(paymentMethod.id);
  };

  return (
    <ButtonBase
      focusRipple
      className={classes.cardButton}
      onClick={handleClickCard}
      disabled={disabled}
    >
      <Card
        element="span"
        className={classes.card}
        style={{ opacity: disabled ? 0.4 : 1 }}
      >
        <span className={classes.leftSlot}>
          <Radio
            id={paymentMethod.name}
            className={classes.radio}
            color="primary"
            checked={isSelected}
            value={paymentMethod.id}
            inputProps={{ 'aria-label': paymentMethod.id }}
            checkedIcon={<CheckCircle fontSize="inherit" />}
            disabled={disabled}
            tabIndex={-1}
          />
        </span>
        <span className={classes.rightSlot}>
          <span className={classes.festivalCredits}>
            <span>{paymentMethod.name}</span>
            <img
              src={getImageUrl(`images/paymentMethods/${paymentMethod.id}.svg`)}
              width="52px"
              height="39px"
              alt=""
            />
          </span>
        </span>
      </Card>
    </ButtonBase>
  );
};

export default MethodCard;
