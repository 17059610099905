import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  FooterButtonsContainer,
  ScreenFooter,
} from 'shared/components';
import { TOP_UP_WALLET_ROUTE } from 'shared/constants';
import { useRoleNavigation } from 'shared/helpers';

const Footer: FC = () => {
  const { t } = useTranslation();
  const { push } = useRoleNavigation();

  const handleTopUpClick = () => {
    push(TOP_UP_WALLET_ROUTE);
  };

  return (
    <ScreenFooter fixed transparent>
      <FooterButtonsContainer>
        <Button
          text={t('common.topUpButtonText')}
          primary
          onClick={handleTopUpClick}
        />
      </FooterButtonsContainer>
    </ScreenFooter>
  );
};

export default Footer;
