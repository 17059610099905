import clsx from 'clsx';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  NoOrders,
  OrderCard,
  SeeAllButton,
  SeeMoreButton,
} from 'shared/components';
import { getOrderDraftProps } from 'shared/helpers';
import { OrderDraft, OrderHistoryItem } from 'shared/types';
import useStyles from './useStyles';

const ORDER_HISTORY_ITEMS_PER_PAGE = 20;

const getOrdersToRender = (orders: OrderHistoryItem[], showAll: boolean) =>
  showAll ? orders : orders.slice(0, ORDER_HISTORY_ITEMS_PER_PAGE);

type Props = {
  outstandingOrders: OrderHistoryItem[];
  completedOrders: OrderHistoryItem[];
  orderDraft: OrderDraft | null;
};

const CardBody: FC<Props> = ({
  outstandingOrders,
  completedOrders,
  orderDraft,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [showAllOutstanding, setShowAllOutstanding] = useState(false);
  const [showAllRetrieved, setShowAllRetrieved] = useState(false);

  const handleClickshowAllOutstanding = () => {
    setShowAllOutstanding((state) => !state);
  };

  const handleClickshowAllRetrieved = () => {
    setShowAllRetrieved((state) => !state);
  };

  const renderOrder = (completed: boolean) => (order: OrderHistoryItem) =>
    (
      <OrderCard
        key={order.uuid}
        orderBoothTypes={[order.boothTypeId]}
        orderItems={[]}
        orderNumber={order.number}
        orderStatus={order.deliveryStatus}
        paymentStatus={order.paymentStatus}
        orderTotalAmount={order.amount}
        orderDate={order.created.replace(' ', 'T')}
        initiallyCollapsed={completed}
        variant="visitor"
        showStatus={completed}
      />
    );

  const orderDraftProps = useMemo(
    () => getOrderDraftProps(orderDraft),
    [orderDraft],
  );

  return (
    <>
      <section className={clsx(classes.section, classes.outstandingSection)}>
        <header>
          <h3>{t('orderHistory.contentTitle1')}</h3>
          {outstandingOrders.length > ORDER_HISTORY_ITEMS_PER_PAGE && (
            <SeeAllButton
              expanded={showAllOutstanding}
              onClick={handleClickshowAllOutstanding}
            />
          )}
        </header>
        {!outstandingOrders.length && !orderDraft && (
          <NoOrders text={t('orderHistory.noOutstandingOrders')} />
        )}
        {orderDraftProps && <OrderCard {...orderDraftProps} />}
        {getOrdersToRender(outstandingOrders, showAllOutstanding).map(
          renderOrder(false),
        )}
        {outstandingOrders.length > ORDER_HISTORY_ITEMS_PER_PAGE && (
          <SeeMoreButton onClick={handleClickshowAllOutstanding} />
        )}
      </section>
      <section className={clsx(classes.section, classes.completedSection)}>
        <header>
          <h3>{t('orderHistory.contentTitle2')}</h3>
          {completedOrders.length > ORDER_HISTORY_ITEMS_PER_PAGE && (
            <SeeAllButton
              expanded={showAllRetrieved}
              onClick={handleClickshowAllRetrieved}
            />
          )}
        </header>
        {!completedOrders.length && (
          <NoOrders text={t('orderHistory.noCompletedOrders')} />
        )}
        {getOrdersToRender(completedOrders, showAllRetrieved).map(
          renderOrder(true),
        )}
        {completedOrders.length > ORDER_HISTORY_ITEMS_PER_PAGE && (
          <SeeMoreButton onClick={handleClickshowAllRetrieved} />
        )}
      </section>
    </>
  );
};

export default CardBody;
