import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      flex: 1,
    },
    creditsDescription: {
      width: '100%',
      fontSize: '16px',
      fontWeight: 600,
      color: theme.customPalette.labelGray,
      lineHeight: '22px',
    },
    footer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    button: {
      marginBottom: '20px',
    },
  }),
);

export default useStyles;
