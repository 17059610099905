import { ChangeEventHandler, FC, useMemo } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { VpnKey } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { changePassword } from 'core/requests';
import { messageSlice } from 'core/store';
import { Input, Button } from 'shared/components';
import { PROFILE_ROUTE } from 'shared/constants';
import { CardLayout } from 'shared/layouts';
import { getPasswordRules, useRoleNavigation } from 'shared/helpers';
import useStyles from './useStyles';

const initialValues = {
  currentPassword: '',
  password: '',
  confirmPassword: '',
};

const Register: FC = () => {
  const classes = useStyles();
  const { goBack, push } = useRoleNavigation();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        currentPassword: Yup.string().required(t('validation.required')),
        password: getPasswordRules(t),
        confirmPassword: Yup.string()
          .oneOf([Yup.ref('password')], t('validation.passwordsMustMatch'))
          .required(t('validation.required')),
      }),
    [i18n.language],
  );

  const handleBackClick = () => {
    goBack();
  };

  const handleFormSubmit = async ({
    currentPassword,
    password,
  }: typeof initialValues) => {
    try {
      await changePassword({
        currentPassword,
        password,
      });
      dispatch(
        messageSlice.actions.showMessage({
          text: t('message.savedChanges'),
          type: 'success',
        }),
      );
      push(PROFILE_ROUTE);
    } catch {
      dispatch(
        messageSlice.actions.showMessage({
          text: t('message.unknownError'),
          type: 'error',
        }),
      );
    }
  };

  return (
    <CardLayout
      title={t('profile.title')}
      contentTitle={t('changePassword.contentTitle')}
      contentDescription={t('changePassword.contentDescription')}
      onBackClick={handleBackClick}
      backgroundColor="white"
      maxContentWidth={340}
      centered
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {({
          touched,
          errors,
          setFieldTouched,
          handleSubmit,
          handleChange,
          handleBlur,
        }) => {
          const getFieldError = (field: keyof typeof initialValues) => {
            const isTouched = touched[field] || undefined;
            return isTouched && errors[field];
          };

          const handleFormFieldChange: ChangeEventHandler<HTMLInputElement> = (
            event,
          ) => {
            const fieldName = event.target.name as keyof typeof initialValues;
            if (touched[fieldName]) {
              handleChange(event);
            } else {
              handleChange(event);
              setFieldTouched(fieldName);
            }
          };

          return (
            <div className={classes.container}>
              <span className={classes.sectionTitle}>
                {t('changePassword.currentPassword')}
              </span>
              <Input
                name="currentPassword"
                icon={<VpnKey className={classes.inputIcon} />}
                placeholder={t('changePassword.passwordPlaceholder')}
                className={classes.currentPasswordInput}
                onChange={handleFormFieldChange}
                onBlur={handleBlur}
                error={getFieldError('currentPassword')}
                secure
              />
              <span className={classes.sectionTitle}>
                {t('changePassword.newPassword')}
              </span>

              <Input
                name="password"
                icon={<VpnKey className={classes.inputIcon} />}
                placeholder={t('register.passwordPlaceholder')}
                className={classes.input}
                onChange={handleFormFieldChange}
                onBlur={handleBlur}
                error={getFieldError('password')}
                secure
              />
              <span className={classes.sectionTitle}>
                {t('changePassword.confirmPassword')}
              </span>
              <Input
                name="confirmPassword"
                icon={<VpnKey className={classes.inputIcon} />}
                placeholder={t('changePassword.passwordPlaceholder')}
                className={classes.input}
                onChange={handleFormFieldChange}
                onBlur={handleBlur}
                error={getFieldError('confirmPassword')}
                secure
              />
              <Button
                className={classes.button}
                text={t('changePassword.saveButtonText')}
                primary
                onClick={handleSubmit}
              />
            </div>
          );
        }}
      </Formik>
    </CardLayout>
  );
};

export default Register;
