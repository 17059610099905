import { theme } from 'theme';

const getStatusColor = (status: 'success' | 'warning' | 'failure') => {
  if (status === 'success') {
    return theme.customPalette.accentGreen;
  }
  if (status === 'warning') {
    return theme.customPalette.warning;
  }
  return theme.customPalette.error;
};

export default getStatusColor;
