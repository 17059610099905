import { ComponentProps, FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { getBoothTypeInfo, getDraft, getOrderDetails } from 'core/requests';
import { loaderSlice, messageSlice, RootState } from 'core/store';
import { CardLayout } from 'shared/layouts';
import { Chip, OrderSection, ScreenFooter, QrCode } from 'shared/components';
import {
  Order,
  OrderDetailsBoothOrder,
  OrderDraft,
  OrderStatusExtended,
  PaymentStatus,
  QrCodeType,
} from 'shared/types';
import OrderDetailsFooter from './OrderDetailsFooter';
import useStyles from './useStyles';

const OrderDetails: FunctionComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { goBack } = useHistory();
  const { orderId } = useParams<{ orderId: string }>();
  const [order, setOrder] = useState<Order | null>(null);
  const [orderDraft, setOrderDraft] = useState<OrderDraft | null>(null);
  const [orderProps, setOrderProps] =
    useState<ComponentProps<typeof OrderSection> | null>(null);
  const dispatch = useDispatch();
  const cancelPaidOrder = useSelector(
    (state: RootState) => state.currentEvent?.data?.cancelPaidOrder,
  );

  const fetchOrder = async () => {
    dispatch(loaderSlice.actions.setLoaderState({ loading: true }));
    try {
      if (orderId === 'Draft') {
        const response = await getDraft();
        setOrderDraft(response.data?.data);
      } else {
        const response = await getOrderDetails(orderId);
        setOrder(response.data.data);
      }
    } catch (error) {
      dispatch(
        messageSlice.actions.showMessage({
          text: error.message,
          type: 'error',
        }),
      );
    }
    dispatch(loaderSlice.actions.setLoaderState({ loading: false }));
  };

  useEffect(() => {
    fetchOrder();
  }, [orderId]);

  const getOrderProps = async (): Promise<ComponentProps<
    typeof OrderSection
  > | null> => {
    if (orderDraft) {
      const splitOrders = Object.values(
        orderDraft.products.reduce((acc, value) => {
          const product = { ...value, available: true };

          return {
            ...acc,
            [value.boothTypeId]: acc[value.boothTypeId]
              ? {
                  ...acc[value.boothTypeId],
                  items: [...acc[value.boothTypeId].items, product],
                }
              : {
                  boothType: String(value.boothTypeId),
                  boothZone: '',
                  items: [product],
                },
          };
        }, {} as { [key: string]: OrderDetailsBoothOrder }),
      );

      // TODO: Get backend to include booth zone info in the product to avoid unnecesary booth details requests in the future
      const boothOrders = await Promise.all(
        splitOrders.map(async (splitOrder) => {
          try {
            const response = await getBoothTypeInfo(splitOrder.boothType);
            const boothZone = response.data.data.zone;
            return { ...splitOrder, boothZone };
          } catch {
            return splitOrder;
          }
        }),
      );

      return {
        number: -1,
        totalAmount: orderDraft.totalAmount,
        boothOrders,
      };
    }

    if (order) {
      return {
        number: order.number,
        totalAmount: order.totalAmount,
        boothOrders: [
          {
            boothType: order.booth.type,
            boothZone: order.booth.zone,
            items: order.products,
          },
        ],
      };
    }

    return null;
  };

  useEffect(() => {
    (async () => {
      const newOrderProps = await getOrderProps();
      setOrderProps(newOrderProps);
    })();
  }, [order, orderDraft]);

  const orderStatus = (
    orderDraft ? OrderStatusExtended.Draft : order?.status
  ) as OrderStatusExtended | undefined;
  const orderPaymentStatus = orderDraft
    ? PaymentStatus.None
    : order?.paymentStatus;

  const getOrderPendingStatusMessage = () => {
    if (orderPaymentStatus === PaymentStatus.Paid) {
      return (
        <div className={classes.inlineContent}>
          <span>{t('orderDetails.message.paid')}</span>{' '}
          <Chip
            label={t('booth.boothNumberTextWithColon', {
              number: order?.booth?.type,
            })}
          />
        </div>
      );
    }
    if (
      orderPaymentStatus &&
      [PaymentStatus.Open, PaymentStatus.Pending].includes(orderPaymentStatus)
    ) {
      return t('orderDetails.message.pendingPaymentOpenOrPending');
    }
    if (
      orderPaymentStatus &&
      [PaymentStatus.Failed, PaymentStatus.Canceled].includes(
        orderPaymentStatus,
      )
    ) {
      return t('orderDetails.message.pendingPaymentFailedOrCancelled');
    }
    return '';
  };

  const renderMessage = () => {
    switch (orderStatus) {
      case OrderStatusExtended.Draft:
        return '';
      case OrderStatusExtended.Pending:
        return getOrderPendingStatusMessage();
      case OrderStatusExtended.Retrieved:
        return t('orderDetails.message.retrieved', {
          dateTime: order?.date
            ? format(parseISO(order.date.replace(' ', 'T')), 'dd/MM/yy - HH:mm')
            : '',
          boothTypeNumber: order?.booth.type,
        });
      case OrderStatusExtended.Helpdesk:
        return t('orderDetails.message.helpdesk');
      case OrderStatusExtended.Cancelled:
        return t('orderDetails.message.cancelled');
      default:
        return '';
    }
  };

  const message = renderMessage();

  return (
    <CardLayout
      title={t('orderDetails.title')}
      footer={
        <ScreenFooter fixed transparent>
          {orderStatus && orderPaymentStatus && (
            <OrderDetailsFooter
              orderStatus={orderStatus}
              paymentStatus={orderPaymentStatus}
              cancelPaidOrders={cancelPaidOrder}
              order={order}
              fetchOrder={fetchOrder}
              draftUuid={orderDraft?.id}
            />
          )}
        </ScreenFooter>
      }
      maxContentWidth={750}
      centered
      onBackClick={goBack}
    >
      {orderProps && (
        <OrderSection {...orderProps}>
          {message && <div className={classes.message}>{message}</div>}
          {orderStatus === OrderStatusExtended.Pending &&
            orderPaymentStatus === PaymentStatus.Paid && (
              <QrCode type={QrCodeType.OrderId} value={order?.number ?? -1} />
            )}
        </OrderSection>
      )}
    </CardLayout>
  );
};

export default OrderDetails;
