import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    section: {
      '& header': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '1.3125rem',
        '& h3': {
          margin: 0,
        },
      },
      '& > *:not(:last-child)': {
        marginBottom: 15,
      },
    },
    overviewSection: {
      paddingBottom: '26px',
    },
    outstandingSection: {
      paddingBottom: '57px',
    },
    retrievedSection: {
      paddingBottom: '21px',
    },
    secondaryText: {
      paddingBottom: '1.25rem',
      fontSize: '0.9375rem',
    },
    boothInfo: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    linkWithIcon: {
      display: 'inline-flex',
      alignItems: 'center',
      fontSize: '0.875rem',
      color: '#4f329a',
      '& .MuiSvgIcon-root': {
        fontSize: '0.9375rem',
      },
    },
    list: {
      listStyle: 'none',
      margin: '0.625rem 0 2.125rem 0',
      padding: '0 0.625rem',
    },
    orderSummary: {
      fontWeight: 600,
      lineHeight: '25px',
      margin: 0,
    },
  }),
);

export default useStyles;
