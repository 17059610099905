import { MenuItem, SelectProps, SvgIcon } from '@material-ui/core';
import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { getBoothTypes } from 'core/requests';
import { messageSlice } from 'core/store';
import { CardLayout } from 'shared/layouts';
import { Button, Select } from 'shared/components';
import { BOOTH_MENU_ROUTE, QR_SCAN_ROUTE } from 'shared/constants';
import { useRoleNavigation } from 'shared/helpers';
import { BoothType } from 'shared/types';
import { ReactComponent as BoothNumberIcon } from 'assets/booth-number.svg';
import { ReactComponent as BoothScanQrCodeIcon } from 'assets/booth-scan-qr-code.svg';
import useStyles from './useStyles';

const OrderByBoothNumber: FunctionComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { goBack } = useHistory();
  const { push } = useRoleNavigation();
  const [boothNumber, setBoothNumber] = useState('');
  const [boothTypes, setBoothTypes] = useState<BoothType[]>();
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      try {
        const response = await getBoothTypes();
        setBoothTypes(response?.data?.data?.items);
      } catch (error) {
        dispatch(
          messageSlice.actions.showMessage({
            text: t('placeOrder.message.boothNumberFetchError'),
            type: 'error',
          }),
        );
      }
    })();
  }, []);

  const handleChangeBoothNumber: SelectProps['onChange'] = (event) => {
    setBoothNumber((event.target.value as number).toString());
  };

  const handleClickContinue = () => {
    push(`${BOOTH_MENU_ROUTE}/${boothNumber}`);
  };

  const handleClickScan = () => {
    push(QR_SCAN_ROUTE);
  };

  const renderBoothNumberItem = (item: BoothType) => (
    <MenuItem key={item.id} value={item.number}>
      {item.number}
    </MenuItem>
  );

  return (
    <CardLayout
      title={t('placeOrder.title')}
      onBackClick={goBack}
      maxContentWidth={340}
      centered
    >
      <section className={classes.section}>
        <h3>{t('placeOrder.orderByBoothNumber.title')}</h3>
        <div className={classes.inlineElementsContainer}>
          <div>
            <SvgIcon
              component={BoothNumberIcon}
              className={classes.boothIcon}
            />
          </div>
          <div>{t('placeOrder.orderByBoothNumber.description')}</div>
        </div>
        <Select
          className={classes.select}
          aria-label={t('placeOrder.chooseABoothNumber')}
          inputProps={{ placeholder: t('placeOrder.chooseABoothNumber') }}
          value={boothNumber}
          displayEmpty
          onChange={handleChangeBoothNumber}
        >
          <MenuItem key="0" value="" disabled>
            <span className={classes.placeholder}>
              {t('placeOrder.chooseABoothNumber')}
            </span>
          </MenuItem>
          {boothTypes?.map(renderBoothNumberItem)}
        </Select>
        <Button
          text={t('placeOrder.continue')}
          primary
          onClick={handleClickContinue}
          disabled={boothNumber === ''}
        />
      </section>

      {/* TODO: uncomment in second version ( Scan QR code for menu ) */}
      {/*<section className={classes.section}>
        <h3>{t('placeOrder.scanQrCodeForMenu')}</h3>
        <div className={classes.inlineElementsContainer}>
          <div>
            <SvgIcon
              component={BoothScanQrCodeIcon}
              className={classes.boothIcon}
            />
          </div>
          <div>
            <Button
              custom
              className={classes.scanButton}
              text={t('placeOrder.scanQrCode')}
              onClick={handleClickScan}
            />
          </div>
        </div>
      </section>*/}
    </CardLayout>
  );
};

export default OrderByBoothNumber;
