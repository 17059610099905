import clsx from 'clsx';
import { FC, InputHTMLAttributes } from 'react';
import { CircularProgress, createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: 'relative',
    },
    fileInput: {
      display: 'none',
    },
    spinner: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: '-20px',
      marginLeft: '-20px',
    },
  }),
);

type Props = InputHTMLAttributes<HTMLInputElement> & {
  loading: boolean;
};

const Upload: FC<Props> = ({
  children,
  accept = 'image/*',
  id = 'upload-input',
  loading,
  className,
  ...props
}) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      <input
        accept={accept}
        className={classes.fileInput}
        id={id}
        multiple
        type="file"
        disabled={loading}
        {...props}
      />
      <label htmlFor={id}>{children}</label>
      {loading && <CircularProgress className={classes.spinner} />}
    </div>
  );
};

export default Upload;
