import { FC } from 'react';
import {
  createStyles,
  makeStyles,
  SvgIcon,
  SvgIconTypeMap,
} from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';

const useStyles = makeStyles(() =>
  createStyles({
    infoItem: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: '8px',
    },
    itemIcon: {
      fontSize: '14px',
      color: '#000',
      marginRight: '18px',
      marginTop: '2px',
    },
    itemText: {
      fontSize: '14px',
      fontWeight: 600,
      color: '#383f44',
    },
  }),
);

type Props = {
  icon:
    | OverridableComponent<SvgIconTypeMap<unknown, 'svg'>>
    | React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & {
          title?: string | undefined;
        }
      >;
  text: string;
};

const InfoItem: FC<Props> = ({ icon, text }) => {
  const classes = useStyles();

  return (
    <div className={classes.infoItem}>
      <SvgIcon className={classes.itemIcon} component={icon} />
      <span className={classes.itemText}>{text}</span>
    </div>
  );
};

export default InfoItem;
