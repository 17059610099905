import { createSlice } from '@reduxjs/toolkit';
import { CurrentEventState } from '.';
import reducers from './reducers';

const initialState: CurrentEventState = {
  loading: true,
  data: undefined,
};

const currentEventSlice = createSlice({
  name: 'currentEvent',
  initialState,
  reducers,
});

export default currentEventSlice;
