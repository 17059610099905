import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { putOrderAsRetrieved } from 'core/requests';
import { loaderSlice, messageSlice, RootState } from 'core/store';
import { Button } from 'shared/components';
import { HOME_ROUTE, PAYMENT_METHOD_SELECTION_ROUTE } from 'shared/constants';
import { useRoleNavigation } from 'shared/helpers';
import { Order, OrderStatus, PaymentStatus } from 'shared/types';
import useStyles from './useStyles';
import { theme } from 'theme';

type Props = {
  orderDetails: Order | undefined;
  isCrew?: boolean;
};

const Footer: FC<Props> = ({ orderDetails, isCrew }) => {
  const classes = useStyles({ isCrew });
  const { t } = useTranslation();
  const { push } = useRoleNavigation();
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state: RootState) => ({
    currentUser: state.currentUser.data,
  }));
  const merchantBoothId = currentUser?.merchantBoothId;

  const handleClickYes = async () => {
    if (
      orderDetails &&
      merchantBoothId &&
      orderDetails.status === OrderStatus.Pending &&
      orderDetails.paymentStatus === PaymentStatus.Paid
    ) {
      dispatch(loaderSlice.actions.setLoaderState({ loading: true }));
      try {
        await putOrderAsRetrieved(orderDetails.id, merchantBoothId!);
        push(HOME_ROUTE);
      } catch (error) {
        dispatch(
          messageSlice.actions.showMessage({
            text: t('merchant.message.orderStatusChangeFail'),
            type: 'error',
          }),
        );
      } finally {
        dispatch(loaderSlice.actions.setLoaderState({ loading: false }));
      }
    }
  };

  const handleClickRetryPayment = () => {
    if (orderDetails?.id) {
      push(
        `${PAYMENT_METHOD_SELECTION_ROUTE}?orderUuid=${orderDetails?.id}&orderId=${orderDetails.number}`,
      );
    }
  };

  const handleClickPlaceNewOrder = () => {
    push(HOME_ROUTE);
  };

  const getButtonProps = () => {
    switch (orderDetails?.paymentStatus) {
      case PaymentStatus.Paid:
        return {
          primary: true,
          text: t('payment.confirmationFooterOkText'),
          onClick: handleClickYes,
        };
      case PaymentStatus.Expired:
        return {
          primary: true,
          color: theme.customPalette.warning,
          text: t('payment.retryPayment'),
          onClick: handleClickRetryPayment,
        };
      case PaymentStatus.Canceled:
        return {
          primary: true,
          color: theme.customPalette.warning,
          text: t('payment.retryPayment'),
          onClick: handleClickRetryPayment,
        };
      case PaymentStatus.Failed:
        return {
          primary: true,
          color: theme.customPalette.warning,
          text: t('payment.retryPayment'),
          onClick: handleClickRetryPayment,
        };
      case PaymentStatus.Open:
        return {
          primary: true,
          color: theme.customPalette.warning,
          text: t('payment.retryPayment'),
          onClick: handleClickRetryPayment,
        };
      default:
        return {
          primary: true,
          text: t('common.placeNewOrder'),
          onClick: handleClickPlaceNewOrder,
        };
    }
  };

  return (
    <div className={classes.footer}>
      {orderDetails?.paymentStatus === PaymentStatus.Paid && (
        <span>{t('payment.confirmationFooterTitle')}</span>
      )}
      {orderDetails?.paymentStatus &&
        orderDetails?.paymentStatus !== PaymentStatus.Pending && (
          <Button className={classes.button} {...getButtonProps()} />
        )}
    </div>
  );
};

export default Footer;
