import { FC } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles<Theme, { fixed?: boolean; transparent?: boolean }>(
  (theme) =>
    createStyles({
      container: {
        position: (props) => (props.fixed ? 'initial' : 'fixed'),
        bottom: (props) => (props.fixed ? undefined : 0),
        marginTop: (props) => (props.fixed ? 'auto' : undefined),
        width: '100%',
        borderTopLeftRadius: ({ transparent }) => (transparent ? 0 : '20px'),
        borderTopRightRadius: ({ transparent }) => (transparent ? 0 : '20px'),
        backgroundColor: ({ transparent }) =>
          transparent ? 'transparent' : theme.customPalette.background,
        alignSelf: 'flex-end',
      },
    }),
);

type Props = {
  fixed?: boolean;
  transparent?: boolean;
  className?: string;
};

const ScreenFooter: FC<Props> = ({
  fixed,
  transparent,
  className,
  ...props
}) => {
  const classes = useStyles({ fixed, transparent });
  return <div className={clsx(classes.container, className)} {...props} />;
};

export default ScreenFooter;
