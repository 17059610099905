import { SvgIcon, Button as MaterialButton } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getPaymentDetails } from 'core/requests';
import { ReactComponent as PastaIcon } from 'assets/noodles.svg';
import { loaderSlice, messageSlice, RootState } from 'core/store';
import { Button } from 'shared/components';
import { HOME_ROUTE } from 'shared/constants';
import {
  getCurrencyString,
  useRoleNavigation,
  getStatusColor,
} from 'shared/helpers';
import { PaymentStatus } from 'shared/types';
import useStyles from './useStyles';

const getPaymentStatusColor = (status?: PaymentStatus) => {
  if (status === PaymentStatus.Paid) {
    return getStatusColor('success');
  }
  if (status === PaymentStatus.Open) {
    return getStatusColor('warning');
  }
  return getStatusColor('failure') || 'inherit';
};

const TopUpPayment: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { push } = useRoleNavigation();
  const [paymentStatus, setPaymentStatus] =
    useState<PaymentStatus | undefined>();
  const [credits, setCredits] = useState<number | undefined>();
  const query = new URLSearchParams(location.search);
  const paymentUuid = query.get('paymentUuid');
  const { currency } = useSelector((state: RootState) => ({
    currency: state.currentEvent?.data?.currency,
  }));

  const currencySymbol = useMemo(() => {
    return currency ? getCurrencyString(currency) : '';
  }, [currency]);

  useEffect(() => {
    (async () => {
      if (paymentUuid) {
        dispatch(loaderSlice.actions.setLoaderState({ loading: true }));
        try {
          const response = await getPaymentDetails(paymentUuid);
          const data = response?.data?.data;
          setPaymentStatus(data.status);
          setCredits(data.credits);
        } catch (error) {
          dispatch(
            messageSlice.actions.showMessage({
              text: t('payment.message.fetchPaymentDetailsFail'),
              type: 'error',
            }),
          );
        } finally {
          dispatch(loaderSlice.actions.setLoaderState({ loading: false }));
        }
      }
    })();
  }, [paymentUuid]);

  const handleClickButton = () => {
    push(HOME_ROUTE);
  };

  const getStatusBasedDetails = () => {
    switch (paymentStatus) {
      case PaymentStatus.Paid:
        return {
          title: t('topUp.success.title'),
          description: t('topUp.success.description'),
          buttonText: t('topUp.success.buttonText'),
        };
      default:
        return {
          title: t('topUp.default.title'),
          description: t('topUp.default.description'),
          buttonText: t('topUp.default.buttonText'),
        };
    }
  };

  const color = getPaymentStatusColor(paymentStatus);
  const statusBasedDetails = getStatusBasedDetails();

  return (
    <div className={classes.container}>
      <MaterialButton
        variant="contained"
        color="primary"
        className={classes.button}
        classes={{
          label: classes.buttonLabel,
        }}
        onClick={handleClickButton}
      >
        <ChevronLeft className={classes.buttonIcon} />
      </MaterialButton>
      <SvgIcon
        component={PastaIcon}
        className={classes.icon}
        viewBox="0 0 120 122"
        style={{ color }}
      />
      <span className={classes.title}>{statusBasedDetails?.title}</span>
      {paymentStatus === PaymentStatus.Paid && (
        <div className={classes.row}>
          <span className={classes.currency}>{currencySymbol}</span>
          <span className={classes.amount}>{credits}</span>
        </div>
      )}
      <span className={classes.description}>
        {statusBasedDetails?.description}
      </span>
      <Button
        text={statusBasedDetails.buttonText}
        onClick={handleClickButton}
        primary
        color="#220961"
      />
    </div>
  );
};

export default TopUpPayment;
