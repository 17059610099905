import { PayloadAction } from '@reduxjs/toolkit';
import { ProductCategory } from 'shared/types';
import { CategoriesState } from './index';

const reducers = {
  getCategoriesRequest: (state: CategoriesState): CategoriesState => ({
    ...state,
    loading: true,
  }),
  getCategoriesSuccess: (
    state: CategoriesState,
    { payload }: PayloadAction<ProductCategory[]>,
  ): CategoriesState => ({ data: payload, loading: false }),
  getCategoriesFailure: (state: CategoriesState): CategoriesState => ({
    ...state,
    loading: false,
  }),
  resetCategoriesState: (): CategoriesState => ({
    data: [],
    loading: false,
  }),
};

export default reducers;
