import { FC, ReactNode } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { TFunctionResult } from 'i18next';

type FlexAlign = 'flex-start' | 'center' | 'flex-end' | undefined;

const useStyles = makeStyles<Theme, { align: FlexAlign }>((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: ({ align }) => (align ? align : 'center'),
      justifyContent: 'space-between',
      marginBottom: '6px',
    },
    key: {
      color: '#393F44',
      fontWeight: 600,
    },
    value: {
      color: theme.customPalette.labelGray,
    },
  }),
);

type Props = {
  label: (ReactNode & TFunctionResult) | number;
  value: (ReactNode & TFunctionResult) | number;
  align?: FlexAlign;
};

const LabeledValue: FC<Props> = ({ label, value, align }) => {
  const classes = useStyles({ align });

  return (
    <div className={classes.root}>
      <div className={classes.key}>{label}</div>
      <div className={classes.value}>{value}</div>
    </div>
  );
};

export default LabeledValue;
