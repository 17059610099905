import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { putBoothTypeAbout } from 'core/requests';
import { usePermissions } from 'features/Auth';
import { BoothTypeAbout, MerchantBoothInfo } from 'shared/types';
import EditableCard from './EditableCard';
import useStyles from './useStyles';

type Props = {
  boothInfo?: MerchantBoothInfo;
  fetchMerchantBoothInfo: () => Promise<void>;
};

const BoothTypeAboutSection: FC<Props> = ({
  boothInfo,
  fetchMerchantBoothInfo,
}) => {
  const { isAllowedTo } = usePermissions();
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const renderCard = (data: BoothTypeAbout) => (
    <EditableCard
      key={data.languageCode}
      languageCode={data.languageCode}
      text={data.text}
      isEditable={isAllowedTo('boothType.description.edit')}
      t={t}
      dispatch={dispatch}
      onSave={putBoothTypeAbout}
      errorMessage={t('merchantProfile.message.editBoothTypeAboutFail')}
      fetchMerchantBoothInfo={fetchMerchantBoothInfo}
    />
  );

  return (
    <>
      <span className={classes.sectionTitleText}>
        {t('merchantProfile.about')}
      </span>
      {boothInfo?.typeDescription.map(renderCard)}
    </>
  );
};

export default BoothTypeAboutSection;
