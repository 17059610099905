import { getImageUrl } from '.';

function getImageOrFallback(url: string, fallback: string) {
  return new Promise<string>((resolve, reject) => {
    const img = new Image();
    img.src = url;
    img.onload = () => resolve(getImageUrl(url));
    img.onerror = () => {
      reject(`image not found for url ${getImageUrl(url)}`);
    };
  }).catch(() => {
    return getImageUrl(fallback);
  });
}

export default getImageOrFallback;
