import { call, put, takeEvery } from 'redux-saga/effects';
import { getProductCategories } from 'core/requests';
import { PromiseResult } from 'shared/types';
import i18next from 'i18next';
import { categoriesSlice } from './';
import { messageSlice } from '../message';

function* getCategoriesSaga() {
  try {
    const {
      data: { data },
    }: PromiseResult<ReturnType<typeof getProductCategories>> = yield call(
      getProductCategories,
    );
    yield put(categoriesSlice.actions.getCategoriesSuccess(data.items));
  } catch {
    yield put(categoriesSlice.actions.getCategoriesFailure());
    yield put(
      messageSlice.actions.showMessage({
        text: i18next.t('message.categoriesFetchError'),
        type: 'error',
      }),
    );
  }
}

export function* getCategoriesSagaWatcher() {
  yield takeEvery(
    categoriesSlice.actions.getCategoriesRequest,
    getCategoriesSaga,
  );
}
