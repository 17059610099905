import { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { messageSlice, RootState } from 'core/store';

const Message: FC = () => {
  const dispatch = useDispatch();
  const { text, type, duration } = useSelector(
    (state: RootState) => state.message,
  );

  const handleSnackbarClose = useCallback(() => {
    dispatch(
      messageSlice.actions.showMessage({
        text: null,
        duration: undefined,
      }),
    );
  }, [dispatch]);

  return (
    <Snackbar
      open={Boolean(text)}
      autoHideDuration={duration ?? 3000}
      onClose={handleSnackbarClose}
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
    >
      <Alert variant="filled" severity={type}>
        {text}
      </Alert>
    </Snackbar>
  );
};

export default Message;
