import boothIcon from 'assets/booth-2.svg';
import cravingForIcon from 'assets/craving-for.svg';

const screens = [
  {
    pageNumber: 1,
    key: 'screen1',
    image: boothIcon,
  },
  {
    pageNumber: 2,
    key: 'screen2',
    image: cravingForIcon,
  },
  {
    pageNumber: 3,
    key: 'screen3',
    image: cravingForIcon,
  },
];

export default screens;
