import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { Edit } from '@material-ui/icons';
import { formatTextDate } from 'shared/helpers';
import { Card } from 'shared/components';
import useStyles from './useStyles';
import DateTimeRow from '../DateTimeRow';
import { parseISO } from 'date-fns/esm';

type Props = {
  active: boolean;
  openingDate?: string;
  closingDate?: string;
  dayNumber: number;
  onEditClick?: () => void;
  isEditable: boolean;
};

const OrderHoursCard: FC<Props> = ({
  active,
  dayNumber,
  openingDate,
  closingDate,
  onEditClick,
  isEditable,
}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const parseDate = (date: string) => parseISO(date.replace(' ', 'T'));

  const formatDay = (date: string) =>
    formatTextDate(parseDate(date), i18n.language);

  const formatTime = (date: string) => format(parseDate(date), 'HH:mm');

  if (active && openingDate && closingDate) {
    return (
      <Card altShadow className={classes.card}>
        <div className={classes.cardRow}>
          <span className={classes.eventDayText}>
            {t('merchantProfile.eventDay', { number: dayNumber })}
          </span>
          {isEditable && (
            <Edit className={classes.editIcon} onClick={onEditClick} />
          )}
        </div>
        <DateTimeRow
          date={formatDay(openingDate)}
          time={formatTime(openingDate)}
        />
        <DateTimeRow
          date={formatDay(closingDate)}
          time={formatTime(closingDate)}
        />
      </Card>
    );
  }

  return (
    <Card altShadow className={classes.inactiveCard}>
      <div className={classes.cardRow}>
        <span className={classes.eventDayTextDisabled}>
          Event day {dayNumber}
        </span>
        {isEditable && (
          <Edit className={classes.editIcon} onClick={onEditClick} />
        )}
      </div>
    </Card>
  );
};

export default OrderHoursCard;
