import { PayloadAction } from '@reduxjs/toolkit';
import { MessageState } from './index';

const setMessage = (
  state: MessageState,
  { payload }: PayloadAction<MessageState>,
): MessageState => ({
  ...state,
  ...payload,
});

export default setMessage;
