import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles<Theme, { isCrew?: boolean }>(() =>
  createStyles({
    subcontainer: {
      width: '100%',
      maxWidth: '750px',
      padding: '30px 20px 20px',
    },
    topContainer: {
      paddingBottom: '20px',
    },
    contentTitle: {
      fontSize: '21px',
      fontWeight: 800,
      color: 'white',
    },
    footer: ({ isCrew }) => ({
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: isCrew ? 'row' : 'column',
      alignItems: 'center',
      justifyContent: isCrew ? 'space-between' : 'initial',
      padding: '16px 21px 16px 21px',
      height: '100%',
    }),
    button: {
      margin: '10px 0',
    },
  }),
);

export default useStyles;
