import { FC, useMemo } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { SvgIcon } from '@material-ui/core';
import { login } from 'core/requests';
import { currentEventSlice, messageSlice } from 'core/store';
import { Input, Button, OrDivider } from 'shared/components';
import {
  HOME_ROUTE,
  SUPER_ADMIN_EMAIL,
  FORGOT_PASSWORD_ROUTE,
} from 'shared/constants';
import { CardLayout } from 'shared/layouts';
import { useLoginStyles } from 'shared/styles';
import { ReactComponent as KeyIcon } from 'assets/key.svg';
import { ReactComponent as UserIcon } from 'assets/user.svg';

const initialValues = {
  email: '',
  password: '',
};

const MerchantLogin: FC = () => {
  const classes = useLoginStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string()
          .required(t('validation.required'))
          .email(t('validation.invalidEmail')),
        password: Yup.string().required(t('validation.required')),
      }),
    [i18n.language],
  );

  const handleContactUsClick = () => {
    window.location.href = `mailto:${SUPER_ADMIN_EMAIL}`;
  };

  const handleForgotPasswordClick = () => {
    history.push(FORGOT_PASSWORD_ROUTE);
  };

  const handleFormSubmit = async (values: typeof initialValues) => {
    try {
      await login(values);
      dispatch(currentEventSlice.actions.getCurrentEventRequest());
      history.push(`/merchant${HOME_ROUTE}`);
    } catch (error) {
      dispatch(
        messageSlice.actions.showMessage({
          text:
            error?.response?.status === 401
              ? t('message.invalidCredentials')
              : t('message.unknownError'),
          type: 'error',
        }),
      );
    }
  };

  return (
    <CardLayout
      title={t('login.screenTitle')}
      contentTitle={t('login.title')}
      contentDescription={t('login.description')}
      backgroundColor="white"
      maxContentWidth={340}
      centered
    >
      <Formik<typeof initialValues>
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={false}
        onSubmit={handleFormSubmit}
      >
        {({ errors, handleChange, handleSubmit }) => (
          <div className={classes.container}>
            <Input
              name="email"
              className={classes.input}
              icon={
                <SvgIcon component={UserIcon} className={classes.inputIcon} />
              }
              placeholder={t('login.loginPlaceholder')}
              onChange={handleChange}
              error={errors.email}
            />
            <Input
              name="password"
              className={classes.input}
              icon={
                <SvgIcon component={KeyIcon} className={classes.inputIcon} />
              }
              placeholder={t('login.passwordPlaceholder')}
              secure
              onChange={handleChange}
              error={errors.password}
            />
            <Button
              className={classes.button}
              text={t('login.signinButtonText')}
              primary
              onClick={handleSubmit}
            />
            <span
              className={classes.forgotPasswordText}
              onClick={handleForgotPasswordClick}
            >
              {t('login.forgotPasswordText')}
            </span>
            <OrDivider />
            <Button
              className={classes.registerButton}
              text={t('login.merchantRegisterButtonText')}
              color="gray"
              onClick={handleContactUsClick}
            />
          </div>
        )}
      </Formik>
    </CardLayout>
  );
};

export default MerchantLogin;
