import { FC } from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { Provider as StoreProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, history, persistor } from 'core/store';
import Routes from './routes';
import { theme } from './theme';
import { ConnectedRouter } from 'connected-react-router';

const App: FC = () => {
  return (
    <StoreProvider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider theme={theme}>
          <ConnectedRouter history={history}>
            <Routes />
          </ConnectedRouter>
        </ThemeProvider>
      </PersistGate>
    </StoreProvider>
  );
};

export default App;
