import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, CreditCount, InfoWithIcon } from 'shared/components';
import { HOME_ROUTE, PAYMENT_METHOD_SELECTION_ROUTE } from 'shared/constants';
import { useRoleNavigation } from 'shared/helpers';
import { Amount } from 'shared/types';
import useStyles from './useStyles';

type Props = {
  isBasketEmpty: boolean;
  amount: Amount;
  isOrderValid: boolean;
  multipleLocations: boolean;
};

const Footer: FC<Props> = ({
  isBasketEmpty,
  isOrderValid,
  amount,
  multipleLocations,
}) => {
  const classes = useStyles({});
  const { t } = useTranslation();
  const { push } = useRoleNavigation();

  const handleOrderFoodClick = () => {
    push(HOME_ROUTE);
  };

  const handleCheckoutClick = async () => {
    push(PAYMENT_METHOD_SELECTION_ROUTE);
  };

  if (isBasketEmpty) {
    return (
      <div className={classes.footer}>
        <Button
          onClick={handleOrderFoodClick}
          primary
          text={t('checkout.orderButton')}
        />
      </div>
    );
  }

  return (
    <div className={classes.footer}>
      <CreditCount
        title={t('checkout.totalAmount')}
        amount={amount.value}
        currency={amount.currency}
        noMargin
      />
      {multipleLocations && (
        <div className={classes.multipleLocations}>
          <InfoWithIcon text={t('checkout.multipleLocations')} />
        </div>
      )}
      <Button
        primary
        text={t('checkout.checkoutButton')}
        onClick={handleCheckoutClick}
        disabled={!isOrderValid}
      />
    </div>
  );
};

export default Footer;
