import { FC, ReactNode } from 'react';
import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import { TOP_BAR_HEIGHT } from 'shared/constants';
import { Spacer } from 'shared/components';

const useStyles = makeStyles<
  Theme,
  {
    backgroundOverflow?: number;
    brightBackground?: boolean;
    maxContentWidth?: number;
  }
>((theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: (props) =>
        props.brightBackground
          ? 'white'
          : theme.customPalette.lightGrayBackground,
      minHeight: `calc(100% - ${TOP_BAR_HEIGHT}px)`,
    },
    headerContainer: {
      paddingBottom: (props) => `${props.backgroundOverflow ?? 76}px`,
      backgroundColor: theme.customPalette.background,
    },
    contentContainer: {
      marginTop: (props) => `-${props.backgroundOverflow ?? 76}px`,
      display: 'flex',
      justifyContent: 'center',
    },
    contentSubcontainer: ({ maxContentWidth }) => ({
      boxSizing: 'border-box',
      width: '100%',
      maxWidth: maxContentWidth || 'none',
    }),
    secondaryHeader: {
      padding: '3px 15px 0 15px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: theme.customPalette.background,
    },
    button: {
      padding: 0,
      minWidth: 0,
    },
    buttonLabel: {
      padding: 0,
      margin: 0,
    },
    icon: {
      fontSize: '26px',
      color: 'white',
    },
  }),
);

type Props = {
  header?: ReactNode;
  secondaryHeader?: ReactNode;
  footer?: ReactNode;
  brightBackground?: boolean;
  backgroundOverflow?: number;
  onBackClick?: () => void;
  maxContentWidth?: number;
};

const FlatLayout: FC<Props> = ({
  header,
  secondaryHeader,
  footer,
  backgroundOverflow,
  brightBackground,
  onBackClick,
  children,
  maxContentWidth,
}) => {
  const classes = useStyles({
    backgroundOverflow,
    brightBackground,
    maxContentWidth,
  });
  return (
    <div className={classes.container}>
      {onBackClick && (
        <div className={classes.secondaryHeader}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            classes={{
              label: classes.buttonLabel,
            }}
            onClick={onBackClick}
            aria-label="Go back"
          >
            <ChevronLeft className={classes.icon} />
          </Button>
          <div>{secondaryHeader}</div>
          <Spacer width="26px" />
        </div>
      )}
      <div className={classes.headerContainer}>{header}</div>
      <div className={classes.contentContainer}>
        <div className={classes.contentSubcontainer}>{children}</div>
      </div>
      {footer}
    </div>
  );
};

export default FlatLayout;
