import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { getOrderDetails, putOrderAsRetrieved } from 'core/requests';
import { loaderSlice, messageSlice, RootState } from 'core/store';
import {
  Button,
  MerchantFooter,
  OrderSection,
  ScreenFooter,
} from 'shared/components';
import { PAYMENT_METHOD_SELECTION_ROUTE } from 'shared/constants';
import { useRoleNavigation } from 'shared/helpers';
import { CardLayout } from 'shared/layouts';
import { Order, OrderStatus, PaymentStatus } from 'shared/types';
import useStyles from './useStyles';

const PAYMENT_STATUSES_ALLOWED_TO_RETRY = [
  PaymentStatus.Canceled,
  PaymentStatus.Expired,
  PaymentStatus.Failed,
];

const MerchantOrderDetails: FunctionComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { goBack } = useHistory();
  const { push } = useRoleNavigation();
  const { orderId } = useParams<{ orderId: string }>();
  const [order, setOrder] = useState<Order>();
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state: RootState) => ({
    currentUser: state.currentUser.data,
  }));
  const merchantBoothId = currentUser?.merchantBoothId;

  const fetchOrderDetails = async () => {
    dispatch(loaderSlice.actions.setLoaderState({ loading: true }));
    try {
      const response = await getOrderDetails(orderId);
      setOrder(response?.data?.data);
    } catch (error) {
      dispatch(
        messageSlice.actions.showMessage({
          text: t('orderDetails.message.fetchOrderDetailsFail'),
          type: 'error',
        }),
      );
    } finally {
      dispatch(loaderSlice.actions.setLoaderState({ loading: false }));
    }
  };

  useEffect(() => {
    (async () => {
      fetchOrderDetails();
    })();
  }, [orderId]);

  const handleClickRetryPayment = () => {
    if (order?.id) {
      push(
        `${PAYMENT_METHOD_SELECTION_ROUTE}?orderUuid=${order.id}&orderId=${order.number}`,
      );
    }
  };

  const handleClickCompleteOrder = async () => {
    if (order && merchantBoothId) {
      dispatch(loaderSlice.actions.setLoaderState({ loading: true }));
      try {
        await putOrderAsRetrieved(order.id, merchantBoothId!);
        fetchOrderDetails();
      } catch (error) {
        dispatch(
          messageSlice.actions.showMessage({
            text: t('merchant.message.orderStatusChangeFail'),
            type: 'error',
          }),
        );
      } finally {
        dispatch(loaderSlice.actions.setLoaderState({ loading: false }));
      }
    }
  };

  const pendingAndAllowedToRetry =
    order?.status === OrderStatus.Pending &&
    PAYMENT_STATUSES_ALLOWED_TO_RETRY.includes(order?.paymentStatus);

  const pendingAndPaid =
    order?.status === OrderStatus.Pending &&
    order?.paymentStatus === PaymentStatus.Paid;

  return (
    <CardLayout
      title={t('orderDetails.title')}
      footer={
        <ScreenFooter fixed>
          <MerchantFooter />
        </ScreenFooter>
      }
      onBackClick={goBack}
      maxContentWidth={500}
    >
      {order && (
        <OrderSection
          number={order.number}
          totalAmount={order.totalAmount}
          boothOrders={[
            {
              boothType: order.booth.type,
              boothZone: order.booth.zone,
              items: order.products,
            },
          ]}
        >
          <div className={classes.contentBox}>
            {pendingAndAllowedToRetry && (
              <Button
                className={classes.bodyButton}
                text={t('orderDetails.retryPayment')}
                onClick={handleClickRetryPayment}
              />
            )}
            {pendingAndPaid && (
              <Button
                className={classes.bodyButton}
                text={t('orderDetails.completeOrder')}
                onClick={handleClickCompleteOrder}
              />
            )}
          </div>
        </OrderSection>
      )}
    </CardLayout>
  );
};

export default MerchantOrderDetails;
