import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import QrReader from 'react-qr-reader';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { ReactComponent as QRCodeIcon } from 'assets/qr-code.svg';
import {
  createMerchantOrder,
  postActivateCrewMemberDevice,
  retryOrderPayment,
} from 'core/requests';
import {
  currentEventSlice,
  loaderSlice,
  messageSlice,
  orderedProductsSlice,
  RootState,
} from 'core/store';
import {
  CREW_HOME_ROUTE,
  BOOTH_MENU_ROUTE,
  PAYMENT_METHOD_SELECTION_ROUTE,
  PAYMENT_REDIRECT_ROUTE,
  SCAN_ORDER_RESULT_ROUTE,
} from 'shared/constants';
import { useRoleNavigation } from 'shared/helpers';
import { CardLayout, CrewLayout } from 'shared/layouts';
import { PaymentMethodType, QrCodeType } from 'shared/types';
import CrewFooter from './CrewFooter';
import FailureDialog from './FailureDialog';
import Footer from './Footer';
import useStyles from './useStyles';

const QrScan: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { goBack, push } = useRoleNavigation();
  const { isAuthenticatedCrewMember, orderedProducts } = useSelector(
    (state: RootState) => ({
      isAuthenticatedCrewMember:
        state.currentUser.data?.role?.[0] === 'ROLE_CREW_MEMBER',
      orderedProducts: state.orderedProducts,
    }),
  );
  const [failureDialogOpen, setFailureDialogOpen] = useState(false);
  const [failureDescription, setFailureDescription] = useState('');
  const query = new URLSearchParams(location.search);
  const orderUuid = query.get('orderUuid');
  const isCrew = pathname.includes('/crew');

  const handleScanError = () => {
    dispatch(
      messageSlice.actions.showMessage({
        text: t('message.scanError'),
        type: 'error',
      }),
    );
  };

  const handleCreateMerchantOrder = async (identityToken: string) => {
    dispatch(loaderSlice.actions.setLoaderState({ loading: true }));
    try {
      const checkoutItems = Object.values(orderedProducts.products).map(
        (item) => ({
          uuid: item.uuid,
          quantity: item.quantity,
        }),
      );
      const {
        data: { data },
      } = await createMerchantOrder({
        paymentMethodId: PaymentMethodType.Credits,
        products: checkoutItems,
        identityToken,
      });
      dispatch(orderedProductsSlice.actions.clearOrderedProducts());
      push(`${PAYMENT_REDIRECT_ROUTE}?orderId=${data.orderId}`);
    } catch (error) {
      let message: string;
      switch (error?.response?.status) {
        case 409:
          message = t('order.message.notEnoughCredits');
          setFailureDescription(t('order.message.notEnoughCredits'));
          break;
        case 503:
          message = t('order.message.paymentFail');
          setFailureDescription(t('order.message.paymentFail'));
          break;
        default:
          message = t('order.message.createFail');
          setFailureDescription(t('order.message.createFail'));
      }
      dispatch(
        messageSlice.actions.showMessage({
          text: message,
          type: 'error',
        }),
      );
      setFailureDialogOpen(true);
    }
    dispatch(loaderSlice.actions.setLoaderState({ loading: false }));
  };

  const handleMerchantRetryPayment = async (identityToken: string) => {
    if (orderUuid && identityToken) {
      dispatch(loaderSlice.actions.setLoaderState({ loading: true }));
      try {
        const {
          data: { data },
        } = await retryOrderPayment({
          orderUuid: orderUuid,
          paymentMethodId: PaymentMethodType.Credits,
          redirectUrl: `${window.location.origin}/merchant${PAYMENT_REDIRECT_ROUTE}?orderId=${orderUuid}`,
          identityToken,
        });
        dispatch(orderedProductsSlice.actions.clearOrderedProducts());
        window.location.href = data.checkoutUrl;
      } catch {
        dispatch(
          messageSlice.actions.showMessage({
            text: t('message.paymentRedirectError'),
            type: 'error',
          }),
        );
      }
      dispatch(loaderSlice.actions.setLoaderState({ loading: false }));
    }
  };

  const handleActivateDevice = async (identityToken: string) => {
    dispatch(loaderSlice.actions.setLoaderState({ loading: true }));
    try {
      await postActivateCrewMemberDevice({
        identityToken,
      });
      dispatch(currentEventSlice.actions.getCurrentEventRequest());
      push(CREW_HOME_ROUTE);
    } catch {
      dispatch(
        messageSlice.actions.showMessage({
          text: t('crew.message.activateDeviceFail'),
          type: 'error',
        }),
      );
    }
    dispatch(loaderSlice.actions.setLoaderState({ loading: false }));
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleVisitorFeatures = (parsedData: any) => {
    if (parsedData?.type === QrCodeType.BoothNumber) {
      push(`${BOOTH_MENU_ROUTE}/${parsedData.value}`);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleMerchantFeatures = (parsedData: any) => {
    if (parsedData?.type === QrCodeType.OrderId) {
      push(`${SCAN_ORDER_RESULT_ROUTE}/${parsedData.value}`);
    }
    if (parsedData?.type === QrCodeType.CreditsPayment) {
      if (orderUuid) {
        handleMerchantRetryPayment(parsedData.value);
      } else {
        handleCreateMerchantOrder(parsedData.value);
      }
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleCrewFeatures = (parsedData: any) => {
    if (parsedData?.type === QrCodeType.ActivateDevice) {
      handleActivateDevice(parsedData.value);
    }
    if (parsedData?.type === QrCodeType.OrderId && isAuthenticatedCrewMember) {
      push(`${SCAN_ORDER_RESULT_ROUTE}/${parsedData.value}`);
    }

    if (parsedData?.type === QrCodeType.CreditsPayment) {
      if (orderUuid) {
        handleMerchantRetryPayment(parsedData.value);
      } else {
        handleCreateMerchantOrder(parsedData.value);
      }
    }
  };

  const handleScan = (data: string | null) => {
    const parsedData = data ? JSON.parse(data) : null;
    if (!parsedData?.value) {
      return;
    }
    if (pathname.includes('/merchant')) {
      handleMerchantFeatures(parsedData);
    } else if (pathname.includes('/crew')) {
      handleCrewFeatures(parsedData);
    } else {
      handleVisitorFeatures(parsedData);
    }
  };

  const handleRetry = () => {
    push(PAYMENT_METHOD_SELECTION_ROUTE);
  };

  const handleCancel = () => {
    push(BOOTH_MENU_ROUTE);
  };

  if (isCrew) {
    return (
      <CrewLayout
        title={t('crew.home.title')}
        titleIcon={QRCodeIcon}
        footer={<CrewFooter />}
      >
        <div className={classes.conatiner}>
          <div className={classes.scanContainer}>
            <div className={classes.scanSubcontainer}>
              {!failureDialogOpen && (
                <QrReader
                  facingMode="environment"
                  delay={500}
                  onError={handleScanError}
                  onScan={handleScan}
                  showViewFinder={false}
                />
              )}
            </div>
          </div>
        </div>
        {failureDialogOpen && (
          <FailureDialog
            open={true}
            t={t}
            onRetry={handleRetry}
            onCancel={handleCancel}
            description={failureDescription}
          />
        )}
      </CrewLayout>
    );
  }

  return (
    <CardLayout
      title={t('qrScan.title')}
      contentTitle={t('qrScan.contentTitle')}
      contentDescription={t('qrScan.contentDescription')}
      footer={<Footer />}
      onBackClick={goBack}
      maxContentWidth={750}
      centered
    >
      <div className={classes.conatiner}>
        <div className={classes.scanContainer}>
          <div className={classes.scanSubcontainer}>
            {!failureDialogOpen && (
              <QrReader
                facingMode="environment"
                delay={500}
                onError={handleScanError}
                onScan={handleScan}
                showViewFinder={false}
              />
            )}
          </div>
        </div>
      </div>
      {failureDialogOpen && (
        <FailureDialog
          open={true}
          t={t}
          onRetry={handleRetry}
          onCancel={handleCancel}
          description={failureDescription}
        />
      )}
    </CardLayout>
  );
};

export default QrScan;
