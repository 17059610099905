import { createSlice } from '@reduxjs/toolkit';
import { MerchantBoothsState } from '.';
import reducers from './reducers';

const initialState: MerchantBoothsState = {
  loading: false,
  data: undefined,
};

const merchantBoothsSlice = createSlice({
  name: 'merchantBooths',
  initialState,
  reducers,
});

export default merchantBoothsSlice;
