import { SvgIcon } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as QRCodeIcon } from 'assets/qr-code.svg';
import { putOrderAsHelpdesk, putOrderAsPending } from 'core/requests';
import { loaderSlice, messageSlice } from 'core/store';
import { Button, ConfirmDialog, CreditCount } from 'shared/components';
import {
  BOOTH_MENU_ROUTE,
  CREW_HOME_ROUTE,
  QR_SCAN_ROUTE,
} from 'shared/constants';
import { useRoleNavigation } from 'shared/helpers';
import { Order, OrderStatusExtended } from 'shared/types';
import useStyles from './useStyles';
import { useDispatch } from 'react-redux';

type Props = {
  fetchOrderDetails: () => Promise<void>;
  order?: Order;
  remainingCredits: number;
  status: OrderStatusExtended;
};

const CrewFooter: FC<Props> = ({
  fetchOrderDetails,
  order,
  remainingCredits,
  status,
}) => {
  const classes = useStyles({});
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { push } = useRoleNavigation();
  const [confirmDialogEntity, setConfirmDialogEntity] =
    useState<'refuse' | 'helpdesk' | undefined>();

  const confirmDialogProps = {
    refuse: {
      title: t('scanOrderResult.confirmRefuseOrderTitle'),
      description: t('scanOrderResult.confirmRefuseOrderDescription'),
    },
    helpdesk: {
      title: t('scanOrderResult.confirmSendToHelpdeskTitle'),
      description: t('scanOrderResult.confirmSendToHelpdeskDescription'),
    },
    default: {
      title: '',
      description: '',
    },
  };

  const handleOrderCompletedClick = async () => {
    push(CREW_HOME_ROUTE);
  };

  const handleScanCodeClick = () => {
    push(QR_SCAN_ROUTE);
  };

  const handlePlaceOrderClick = () => {
    push(BOOTH_MENU_ROUTE);
  };

  const handleClickSendToHelpdesk = () => {
    setConfirmDialogEntity('helpdesk');
  };

  const handleRefuseClick = () => {
    setConfirmDialogEntity('refuse');
  };

  const handleRefuseOrder = async () => {
    if (order?.id) {
      dispatch(loaderSlice.actions.setLoaderState({ loading: true }));
      try {
        await putOrderAsPending(order.id);
        push(CREW_HOME_ROUTE);
      } catch {
        dispatch(
          messageSlice.actions.showMessage({
            text: t('scanOrderResult.message.refuseOrderFail'),
            type: 'error',
          }),
        );
      } finally {
        dispatch(loaderSlice.actions.setLoaderState({ loading: false }));
        setConfirmDialogEntity(undefined);
      }
    }
  };

  const handleSendToHelpdesk = async () => {
    if (order) {
      dispatch(loaderSlice.actions.setLoaderState({ loading: true }));
      try {
        await putOrderAsHelpdesk(order.id);
        fetchOrderDetails();
      } catch {
        dispatch(
          messageSlice.actions.showMessage({
            text: t('scanOrderResult.message.sendToHelpdeskFail'),
            type: 'error',
          }),
        );
      } finally {
        dispatch(loaderSlice.actions.setLoaderState({ loading: false }));
        setConfirmDialogEntity(undefined);
      }
    }
  };

  const handleClickConfirmOk = () => {
    if (confirmDialogEntity === 'helpdesk') {
      handleSendToHelpdesk();
    } else if (confirmDialogEntity === 'refuse') {
      handleRefuseOrder();
    }
  };

  const handleClickConfirmCancel = () => {
    setConfirmDialogEntity(undefined);
  };

  const getButtons = () =>
    status === OrderStatusExtended.Paid ? (
      <>
        <Button
          text={t('crew.scanOrderResult.refuseButtonText')}
          icon={<CancelIcon />}
          rounded
          color="#140048"
          onClick={handleRefuseClick}
        />
        <Button
          primary
          text={t('crew.scanOrderResult.confirmationFooterOkText')}
          icon={<CheckCircleIcon />}
          onClick={handleOrderCompletedClick}
        />
      </>
    ) : (
      <>
        <Button
          text={t('crew.scanOrderResult.placeOrderButtonText')}
          rounded
          color="#140048"
          onClick={handlePlaceOrderClick}
        />
        <Button
          primary
          text={t('crew.scanOrderResult.scanButtonText')}
          onClick={handleScanCodeClick}
          icon={
            <SvgIcon component={QRCodeIcon} className={classes.buttonIcon} />
          }
        />
      </>
    );

  return (
    <div className={classes.footer}>
      <div className={classes.leftSlot}>
        <div className={classes.remainingCreditsLabel}>
          {t('scanOrderResult.creditCountTitle')}
        </div>
        <CreditCount
          title={t('crew.scanOrderResult.total')}
          currency="PKP"
          amount={remainingCredits}
          noMargin
        />
      </div>
      <div className={classes.centerSlot}>{getButtons()}</div>
      <div className={classes.rightSlot}>
        {status === OrderStatusExtended.Paid && (
          <Button
            text={t('scanOrderResult.sendToHelpdesk')}
            color="#7b8084"
            onClick={handleClickSendToHelpdesk}
          />
        )}
      </div>
      <ConfirmDialog
        open={Boolean(confirmDialogEntity)}
        okText={t('common.yes')}
        cancelText={t('common.no')}
        onCancel={handleClickConfirmCancel}
        onConfirm={handleClickConfirmOk}
        {...(confirmDialogEntity
          ? confirmDialogProps[confirmDialogEntity]
          : confirmDialogProps.default)}
      />
    </div>
  );
};

export default CrewFooter;
