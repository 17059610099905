import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { TOP_BAR_HEIGHT } from 'shared/constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'absolute',
      top: TOP_BAR_HEIGHT,
      height: '100vh',
      left: 0,
      right: 0,
      backgroundColor: theme.customPalette.background,
      zIndex: 1,
      overflowY: 'scroll',
    },
    sectionContainer: {
      padding: '24px 0 10px 3px',
      marginLeft: '27px',
      width: '140px',
      borderTop: '1px solid #4f329a',
    },
    headerContainer: {
      margin: '26px 21px 27px 21px',
    },
    userContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: '8px',
    },
    accountContainer: {
      marginLeft: 38,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      color: '#c7bae8',
    },
    tripleCircleContainer: {
      position: 'relative',
      width: 54,
      height: 26,
      '& > div': {
        boxSizing: 'border-box',
        position: 'absolute',
        top: 0,
        margin: 0,
        border: '2px solid #220961',
        zIndex: 1,
      },
      '& > div:nth-child(1)': {
        left: 20,
      },
      '& > div:nth-child(2)': {
        left: 10,
        backgroundColor: '#7961B9',
        zIndex: 2,
      },
      '& > div:nth-child(3)': {
        left: 0,
        backgroundColor: '#9A87CD',
        zIndex: 3,
      },
    },
    eventContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    eventRightContainer: {
      display: 'flex',
      flexDirection: 'column',
      color: theme.customPalette.menuSecondaryLink,
      fontSize: '13px',
    },
    userNameText: {
      fontSize: '15px',
      fontWeight: 700,
      color: theme.palette.primary.main,
    },
    eventNameText: {
      fontSize: '15px',
      color: '#c7bae8',
    },
    userIconContainer: {
      width: '30px',
      height: '30px',
      borderRadius: '50%',
      backgroundColor: '#4f329a',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '9px',
    },
    userIconContainerSmall: {
      width: '26px',
      height: '26px',
      backgroundColor: '#4f329a',
      '& > svg': {
        fontSize: '15px',
      },
    },
    userIcon: {
      fontSize: '20px',
      color: 'white',
    },
    eventChangeContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      lineHeight: '24px',
    },
    editIcon: {
      fontSize: '13px',
      color: '#7961b9',
      marginRight: '4.5px',
    },
    eventChangeText: {
      fontSize: '13px',
      color: '#7961b9',
      textDecoration: 'underline',
    },
    languageButton: {
      marginRight: '6px',
      height: '24px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '&:not(:first-child)': {
        '&:before': {
          content: '" "',
          display: 'block',
          height: '16px',
          width: '1px',
          backgroundColor: '#4f329a',
          marginRight: '6px',
          marginTop: '2px',
        },
      },
    },
    languageTextInactive: {
      fontSize: '15px',
      lineHeight: '24px',
      color: 'white',
    },
    languageTextActive: {
      fontSize: '15px',
      lineHeight: '24px',
      color: theme.palette.primary.main,
    },
    languagesContainer: {
      display: 'flex',
      flexDirection: 'row',
      marginLeft: '28px',
    },
    bottomContainer: {
      padding: '24px 0 24px 3px',
      marginLeft: '27px',
      color: theme.customPalette.menuSecondaryLink,
      fontSize: '14px',
      paddingBottom: `calc(100px + ${TOP_BAR_HEIGHT}px)`,
    },
    creditCount: {
      width: 'auto',
      maxWidth: 'calc(100vw - 54px)',
      margin: '-10px 27px 10px 27px',
    },
    accountSelect: {
      marginTop: '8px',
    },
  }),
);

export default useStyles;
