import ForgotPassword from './ForgotPassword';
import Login from './Login';
import MerchantLogin from './MerchantLogin';
import Register from './Register';
import ResetPassword from './ResetPassword';

export { default as permissions } from './permissions';
export { default as usePermissions } from './usePermissions';
export { default as ProtectedRoute } from './ProtectedRoute';
export type { Permission } from './permissions';

export default {
  ForgotPassword,
  Login,
  MerchantLogin,
  Register,
  ResetPassword,
};
