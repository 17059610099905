import { RootState } from 'core/store';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

const useRoleNavigation = () => {
  const history = useHistory();
  const { data } = useSelector((state: RootState) => state.currentUser);

  const push = (path: string) => {
    if (data?.role.includes('ROLE_MERCHANT')) {
      history.push(`/merchant${path}`);
    } else if (
      data?.role.includes('ROLE_CREW_MEMBER') &&
      !path.includes('/crew')
    ) {
      history.push(path === '/' ? '/crew' : `/crew${path}`);
    } else {
      history.push(path);
    }
  };

  return { ...history, push };
};

export default useRoleNavigation;
