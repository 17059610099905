import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      height: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: 'white',
      '& > *': {
        flex: '1 1 auto',
      },
      '& button': {
        minWidth: 170,
        [theme.breakpoints.up('sm')]: {
          width: 'auto',
        },
      },
    },
    leftSlot: {
      width: '100%',
      margin: '0 15px',
      [theme.breakpoints.up('sm')]: {
        flex: '0 1 auto',
        width: 230,
      },
    },
    remainingCreditsLabel: {
      color: theme.customPalette.labelGray,
    },
    centerSlot: {
      flexGrow: 1,
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      '& > *': {
        margin: '8px 15px',
      },
      '& > *:last-child': {
        minWidth: 250,
      },
      [theme.breakpoints.up('sm')]: {
        justifyContent: 'flex-end',
      },
    },
    rightSlot: {
      display: 'flex',
      justifyContent: 'center',
      '& > *': {
        margin: '8px 15px',
      },
      [theme.breakpoints.up('sm')]: {
        justifyContent: 'flex-end',
      },
    },
    buttonIcon: {
      height: '30px',
      width: '30px',
      fontSize: '30px',
      color: 'white',
    },
  }),
);

export default useStyles;
