import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getBoothRetrievedOrders, getOutstandingOrders } from 'core/requests';
import { loaderSlice, messageSlice, RootState } from 'core/store';
import { MerchantFooter, ScreenFooter } from 'shared/components';
import { CardLayout } from 'shared/layouts';
import { MerchantOrderHistoryItem } from 'shared/types';
import CardBody from './CardBody';

const MerchantOrderHistory: FC = () => {
  const { t } = useTranslation();
  const { goBack } = useHistory();
  const { merchantBoothId } = useSelector((state: RootState) => ({
    merchantBoothId: state.currentUser.data?.merchantBoothId,
  }));
  const [outstandingOrders, setOutsatandingOrders] = useState<
    MerchantOrderHistoryItem[]
  >([]);
  const [retrievedOrders, setRetrievedOrders] = useState<
    MerchantOrderHistoryItem[]
  >([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchOutstandingOrders = async () => {
      try {
        const response = await getOutstandingOrders();
        setOutsatandingOrders(response.data.data.items);
      } catch (error) {
        if (error?.response?.status !== 404) {
          dispatch(
            messageSlice.actions.showMessage({
              text: t(
                'merchantOrderHistory.message.fetchOutstandingOrdersFail',
              ),
              type: 'error',
            }),
          );
        }
      }
    };
    const fetchRetrievedOrders = async () => {
      try {
        const response = await getBoothRetrievedOrders(merchantBoothId!);
        setRetrievedOrders(response.data.data.items);
      } catch (error) {
        if (error?.response?.status !== 404) {
          dispatch(
            messageSlice.actions.showMessage({
              text: t('merchantOrderHistory.message.fetchRetrievedOrdersFail'),
              type: 'error',
            }),
          );
        }
      }
    };
    if (merchantBoothId !== undefined) {
      (async () => {
        dispatch(loaderSlice.actions.setLoaderState({ loading: true }));
        await Promise.allSettled([
          fetchOutstandingOrders(),
          fetchRetrievedOrders(),
        ]);
        dispatch(loaderSlice.actions.setLoaderState({ loading: false }));
      })();
    }
  }, [merchantBoothId]);

  return (
    <CardLayout
      title={t('merchantOrderHistory.title')}
      footer={
        <ScreenFooter fixed>
          <MerchantFooter />
        </ScreenFooter>
      }
      onBackClick={goBack}
      maxContentWidth={750}
    >
      <CardBody
        outstandingOrders={outstandingOrders}
        retrievedOrders={retrievedOrders}
      />
    </CardLayout>
  );
};

export default MerchantOrderHistory;
