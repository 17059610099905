import { Amount } from 'shared/types';
import currencySymbols from 'shared/currencySymbols';

export const getCurrencyString = (currency: string) =>
  currencySymbols.hasOwnProperty(currency)
    ? currencySymbols[currency as keyof typeof currencySymbols]
    : currency;

export const getValueWithCurrencyString = (amount: Amount) =>
  `${getCurrencyString(amount.currency)} ${amount.value.toFixed(2) ?? ''}`;
