import { createSlice } from '@reduxjs/toolkit';
import { CurrentUserState } from '.';
import reducers from './reducers';

const initialState: CurrentUserState = {
  loading: false,
  data: undefined,
};

const currentUserSlice = createSlice({
  name: 'currentUser',
  initialState,
  reducers,
});

export default currentUserSlice;
