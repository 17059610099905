import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, LayoutFooter } from 'shared/components';
import { HOME_ROUTE, PAYMENT_METHOD_SELECTION_ROUTE } from 'shared/constants';
import { useRoleNavigation } from 'shared/helpers';
import { PaymentStatus } from 'shared/types';
import { theme } from 'theme';
import { StringLiteralLike } from 'typescript';

type Props = {
  paymentStatus: PaymentStatus | undefined;
  draftUuid?: string;
};

const Footer: FC<Props> = ({ paymentStatus, draftUuid }) => {
  const { t } = useTranslation();
  const { push } = useRoleNavigation();

  const handleClickPlaceNewOrder = () => {
    push(HOME_ROUTE);
  };

  const handleClickRetryPayment = () => {
    if (draftUuid) {
      push(`${PAYMENT_METHOD_SELECTION_ROUTE}/draft?draftUuid=${draftUuid}`);
    }
  };

  const getButtonProps = () =>
    paymentStatus &&
    [PaymentStatus.Pending, PaymentStatus.Paid].includes(paymentStatus)
      ? {
          primary: true,
          text: t('common.placeNewOrder'),
          onClick: handleClickPlaceNewOrder,
        }
      : {
          primary: true,
          color: theme.customPalette.warning,
          text: t('payment.retryPayment'),
          onClick: handleClickRetryPayment,
        };

  return (
    <LayoutFooter>
      {paymentStatus && paymentStatus !== PaymentStatus.Pending && (
        <Button {...getButtonProps()} />
      )}
    </LayoutFooter>
  );
};

export default Footer;
