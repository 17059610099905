import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { FunctionComponent } from 'react';

type Props = {
  width?: React.CSSProperties['width'];
  height?: React.CSSProperties['height'];
};

const useStyles = makeStyles<Theme, Props>((theme: Theme) =>
  createStyles({
    spacer: {
      width: ({ width }) => width || theme.spacing(3),
      height: ({ height }) => height || 0,
    },
  }),
);

const Spacer: FunctionComponent<Props> = ({ width, height }) => {
  const classes = useStyles({ width, height });

  return <div className={classes.spacer} />;
};

export default Spacer;
