import { TextareaAutosize } from '@material-ui/core';
import { Edit, KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { TFunction } from 'i18next';
import { ChangeEventHandler, FC, useState } from 'react';
import { Dispatch } from 'redux';
import { loaderSlice, messageSlice } from 'core/store';
import { Button, Card } from 'shared/components';
import { BoothTypeAbout, BoothTypeStatusText } from 'shared/types';
import useStyles from './useStyles';
import { AxiosResponse } from 'axios';

type Props = {
  isEditable: boolean;
  languageCode: string;
  t: TFunction;
  text: string;
  dispatch: Dispatch;
  onSave: (
    payload: BoothTypeStatusText | BoothTypeAbout,
  ) => Promise<AxiosResponse<unknown>>;
  errorMessage: string | null;
  fetchMerchantBoothInfo: () => Promise<void>;
};

const EditableCard: FC<Props> = ({
  isEditable,
  languageCode,
  t,
  text,
  dispatch,
  onSave,
  errorMessage,
  fetchMerchantBoothInfo,
}) => {
  const classes = useStyles();

  const [isExpanded, setIsExpanded] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editableText, setEditableText] = useState(text);

  const handleExpandClick = () => {
    if (!isEditing) {
      setIsExpanded((current) => !current);
    }
  };

  const handleEditClick = () => {
    if (!isExpanded) {
      setIsExpanded(true);
    }
    setIsEditing((current) => !current);
  };

  const handleAboutTextChange: ChangeEventHandler<HTMLTextAreaElement> = (
    event,
  ) => {
    setEditableText(event.target.value);
  };

  const handleClickSave = async () => {
    dispatch(loaderSlice.actions.setLoaderState({ loading: true }));
    try {
      const payload: BoothTypeStatusText = {
        languageCode,
        text: editableText,
      };
      await onSave(payload);
      fetchMerchantBoothInfo();
      setIsEditing(false);
    } catch {
      dispatch(
        messageSlice.actions.showMessage({
          text: errorMessage,
          type: 'error',
        }),
      );
    } finally {
      dispatch(loaderSlice.actions.setLoaderState({ loading: false }));
    }
  };

  return (
    <Card className={classes.editableCard} altShadow>
      <div className={classes.controlsRow}>
        <div className={classes.centeredRow}>
          <span className={classes.languageText}>{languageCode}</span>
          {isEditable && (
            <Edit className={classes.editIcon} onClick={handleEditClick} />
          )}
        </div>
        {isExpanded ? (
          <KeyboardArrowUp
            className={classes.collapseIcon}
            onClick={handleExpandClick}
          />
        ) : (
          <KeyboardArrowDown
            className={classes.collapseIcon}
            onClick={handleExpandClick}
          />
        )}
      </div>
      {isEditing ? (
        <TextareaAutosize
          value={editableText}
          onChange={handleAboutTextChange}
          className={classes.textInput}
          autoFocus
        />
      ) : (
        <p className={isExpanded ? classes.text : classes.collapsedAboutText}>
          {editableText}
        </p>
      )}
      {isEditing && (
        <Button
          small
          color="#7961B9"
          primary
          text={t('merchantProfile.saveButton')}
          onClick={handleClickSave}
        />
      )}
    </Card>
  );
};

export default EditableCard;
