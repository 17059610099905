import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ScreenFooter } from 'shared/components';
import useStyles from './useStyles';

type Props = {
  onClickActivate: () => void;
};

const EventSelectionFooter: FC<Props> = ({ onClickActivate }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <ScreenFooter fixed transparent>
      <div className={classes.footerContainer}>
        <Button
          text={t('eventSelection.submitButtonLabel')}
          primary
          onClick={onClickActivate}
        />
      </div>
    </ScreenFooter>
  );
};

export default EventSelectionFooter;
