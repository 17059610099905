import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { putBoothProductAvailability } from 'core/requests';
import { loaderSlice, messageSlice } from 'core/store';
import { Card, Switch } from 'shared/components';
import { getValueWithCurrencyString } from 'shared/helpers';
import { Product } from 'shared/types';
import ProductItemHeader from './ProductItemHeader';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      width: '100%',
      margin: '0 17px 25px 17px',
      padding: '18px 21px',
    },
    title: {
      fontSize: '17px',
      color: theme.customPalette.textPrimary,
    },
    itemBody: {
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: '14px',
      color: theme.customPalette.labelGray,
    },
  }),
);

type Props = {
  merchantBoothId?: number;
  product: Product;
};

const CardItem: FC<Props> = ({ merchantBoothId, product }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isAvailable, setAvailability] = useState(product.available);
  const dispatch = useDispatch();

  const handleChangeAvailability = async () => {
    if (merchantBoothId) {
      const isProductAvailable = isAvailable;
      setAvailability(!isProductAvailable);
      dispatch(loaderSlice.actions.setLoaderState({ loading: true }));
      try {
        await putBoothProductAvailability(merchantBoothId, product.uuid);
      } catch (error) {
        setAvailability(isProductAvailable);
        dispatch(
          messageSlice.actions.showMessage({
            text: error.message,
            type: 'error',
          }),
        );
      } finally {
        dispatch(loaderSlice.actions.setLoaderState({ loading: false }));
      }
    }
  };

  return (
    <Card className={classes.card}>
      <ProductItemHeader
        leftSlotContent={<span className={classes.title}>{product.name}</span>}
        rightSlotContent={
          <Switch
            checked={isAvailable}
            onChange={handleChangeAvailability}
            name="availability"
            inputProps={{ 'aria-label': 'availability' }}
            edge="end"
          />
        }
      />
      <div className={classes.itemBody}>
        <div>
          {t('merchant.productOverview.price', {
            price: getValueWithCurrencyString(product.amount),
          })}
        </div>
      </div>
    </Card>
  );
};

export default CardItem;
