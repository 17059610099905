import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      borderRadius: '25px',
      padding: '37px 22px 24px 22px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    dialogTitle: {
      fontSize: '21px',
      fontWeight: 800,
      lineHeight: '28px',
      color: theme.customPalette.textPrimary,
      marginBottom: '12px',
    },
    dialogDescription: {
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '19px',
      color: theme.customPalette.accentText,
      marginBottom: '36px',
    },
    centeredRow: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    checkbox: {
      marginLeft: '-9px',
    },
    eventDayText: {
      fontSize: '16px',
      color: theme.customPalette.textPrimary,
    },
    openingDateContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: '20px',
    },
    openingDateText: {
      fontSize: '17px',
      color: '#5e7b95',
      marginRight: '6px',
    },
    button: { width: '240px' },
    dateTimeSelectContainer: {
      width: '210px',
      height: '44px',
      marginBottom: '16px',
      backgroundColor: '#f8f8f8',
      borderRadius: '12px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    dateTimeSelectLeft: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    dateTimeIcon: {
      fontSize: '14px',
      color: theme.customPalette.textPrimary,
      marginRight: '12px',
      marginLeft: '8px',
    },
    dateTimeText: {
      fontSize: '14px',
      color: theme.customPalette.accentText,
    },
    dateTimeRightIcon: {
      fontSize: '24px',
      marginRight: '12px',
      color: theme.customPalette.textPrimary,
    },
    datePicker: {
      display: 'none',
    },
  }),
);

export default useStyles;
