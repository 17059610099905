import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { cancelDraft, putOrderAsCancelled } from 'core/requests';
import {
  loaderSlice,
  messageSlice,
  orderedProductsSlice,
  RootState,
} from 'core/store';
import { Button, ConfirmDialog } from 'shared/components';
import {
  CHECKOUT_ROUTE,
  HOME_ROUTE,
  ORDER_HISTORY_ROUTE,
  PAYMENT_METHOD_SELECTION_ROUTE,
} from 'shared/constants';
import { useRoleNavigation } from 'shared/helpers';
import { Order, OrderStatusExtended, PaymentStatus } from 'shared/types';
import CancelSuccessDialog from './CancelSuccessDialog';
import useStyles from './useStyles';

type Props = {
  orderStatus: OrderStatusExtended;
  paymentStatus: PaymentStatus;
  cancelPaidOrders?: boolean;
  order: Order | null;
  fetchOrder: () => Promise<void>;
  draftUuid?: string;
};

const OrderDetailsFooter: FunctionComponent<Props> = ({
  orderStatus,
  paymentStatus,
  cancelPaidOrders,
  order,
  fetchOrder,
  draftUuid,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const orderedProducts = useSelector(
    (state: RootState) => state.orderedProducts,
  );
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [isSuccessDialogOpen, setSuccessDialogOpen] = useState(false);
  const { push } = useRoleNavigation();
  const dispatch = useDispatch();
  const entity = draftUuid ? 'Draft' : 'Order';

  const handleConfirmCancelOrder = async () => {
    setConfirmDialogOpen(false);

    const cancelOrderDraft = async () => {
      dispatch(loaderSlice.actions.setLoaderState({ loading: true }));
      try {
        if (draftUuid) {
          await cancelDraft(draftUuid!);
          setSuccessDialogOpen(true);
        }
      } catch (error) {
        dispatch(
          messageSlice.actions.showMessage({
            text: t('orderDetails.message.cancelOrderDraftFail'),
            type: 'error',
          }),
        );
      } finally {
        dispatch(loaderSlice.actions.setLoaderState({ loading: false }));
      }
    };

    const cancelOrder = async () => {
      dispatch(loaderSlice.actions.setLoaderState({ loading: true }));
      try {
        if (order?.id) {
          await putOrderAsCancelled(order.id);
          setSuccessDialogOpen(true);
        }
      } catch (error) {
        if (error?.response?.status === 422) {
          dispatch(
            messageSlice.actions.showMessage({
              text: t('orderDetails.message.cancelRetrievedOrderFail'),
              type: 'error',
            }),
          );
          fetchOrder();
        } else {
          dispatch(
            messageSlice.actions.showMessage({
              text: t('orderDetails.message.cancelOrderFail'),
              type: 'error',
            }),
          );
        }
      } finally {
        dispatch(loaderSlice.actions.setLoaderState({ loading: false }));
      }
    };

    if (draftUuid) {
      cancelOrderDraft();
    } else {
      cancelOrder();
    }
  };

  const handleCancelCancelOrder = () => {
    setConfirmDialogOpen(false);
  };

  const handleClickEditDraft = () => {
    if (!orderedProducts.total) {
      dispatch(orderedProductsSlice.actions.getOrderDraftRequest());
    }
    push(CHECKOUT_ROUTE);
  };

  const handleClickPay = () => {
    push(`${PAYMENT_METHOD_SELECTION_ROUTE}/draft?draftUuid=${draftUuid}`);
  };

  const handleClickCancel = () => {
    setConfirmDialogOpen(true);
  };

  const handleClickPlaceNewOrder = () => {
    push(HOME_ROUTE);
  };

  const handleClickBackButton = () => {
    push(ORDER_HISTORY_ROUTE);
  };

  const paymentButtonText = t(
    !paymentStatus || paymentStatus !== PaymentStatus.None
      ? 'orderDetails.pay'
      : 'orderDetails.retryPayment',
  );

  const renderButtons = () => {
    switch (orderStatus) {
      case OrderStatusExtended.Draft:
        return (
          <>
            {(!paymentStatus || paymentStatus !== PaymentStatus.None) && (
              <Button
                text={t('orderDetails.editDraft')}
                onClick={handleClickEditDraft}
                rounded
              />
            )}
            <Button text={paymentButtonText} onClick={handleClickPay} rounded />
            <Button
              text={t('orderDetails.cancelOrderDraft')}
              onClick={handleClickCancel}
            />
          </>
        );
      case OrderStatusExtended.Pending: {
        if (paymentStatus === PaymentStatus.Paid && cancelPaidOrders) {
          return (
            <Button
              text={t('orderDetails.cancelOrder')}
              onClick={handleClickCancel}
            />
          );
        }
        return null;
      }
      default:
        return null;
    }
  };

  const getConfirmDialogProps = () => ({
    title: t(`orderDetails.cancel${entity}Dialog.title`),
    description: t(`orderDetails.cancel${entity}Dialog.description`),
    okText: t(`orderDetails.cancel${entity}Dialog.confirmButtonText`),
    cancelText: t(`orderDetails.cancel${entity}Dialog.cancelButtonText`),
    open: isConfirmDialogOpen,
    onConfirm: handleConfirmCancelOrder,
    onCancel: handleCancelCancelOrder,
  });

  return (
    <div className={classes.footerContainer}>
      {renderButtons()}
      {orderStatus !== OrderStatusExtended.Draft && (
        <Button
          text={t('common.placeNewOrder')}
          primary
          onClick={handleClickPlaceNewOrder}
        />
      )}
      <ConfirmDialog {...getConfirmDialogProps()} />
      <CancelSuccessDialog
        open={isSuccessDialogOpen}
        onClickButton={handleClickBackButton}
        entity={entity}
      />
    </div>
  );
};

export default OrderDetailsFooter;
