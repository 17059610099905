import { PayloadAction } from '@reduxjs/toolkit';
import { LoaderState } from './index';

const setLoader = (
  _: LoaderState,
  { payload }: PayloadAction<LoaderState>,
): LoaderState => ({
  ...payload,
});

export default setLoader;
