const AUTH_TOKEN_KEY = 'authToken';

const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
};

const getAuthToken = () => {
  return localStorage.getItem(AUTH_TOKEN_KEY);
};

const setAuthToken = (token: string) => {
  localStorage.setItem(AUTH_TOKEN_KEY, token);
};

const removeAuthToken = () => {
  localStorage.removeItem(AUTH_TOKEN_KEY);
};

const getUserRoles = () => {
  const token = localStorage.getItem(AUTH_TOKEN_KEY);
  if (token) {
    const parsedToken = parseJwt(token);
    return parsedToken?.roles;
  }
  return;
};

export default {
  getToken: getAuthToken,
  setToken: setAuthToken,
  remove: removeAuthToken,
  getUserRoles,
};
