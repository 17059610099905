import { FC } from 'react';
import { Order } from 'shared/types';
import useStyles from './useStyles';
import CrewOrderSummaryItem from './CrewOrderSummaryItem';

type Props = {
  textColor: React.CSSProperties['color'];
  items: Order['products'];
};

const CrewOrderSummary: FC<Props> = ({ items, textColor }) => {
  const classes = useStyles({ textColor });

  return (
    <div className={classes.orderSummary}>
      {items.map((product) => (
        <CrewOrderSummaryItem
          key={product.uuid}
          textColor={textColor}
          item={product}
        />
      ))}
    </div>
  );
};

export default CrewOrderSummary;
