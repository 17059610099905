import clsx from 'clsx';
import { FC } from 'react';
import { createStyles, Link, makeStyles, Theme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { InfoWithIcon } from 'shared/components';
import { PROFILE_ROUTE } from 'shared/constants';
import { useRoleNavigation } from 'shared/helpers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    headerContainer: {
      margin: '15px 25px 0 16px',
    },
    topHeading: {
      fontSize: '21px',
      fontWeight: 700,
      color: 'white',
    },
    topSubheading: {
      fontSize: '16px',
      color: theme.palette.primary.main,
    },
    heading: {
      margin: theme.spacing(3, 0, 1, 0),
      fontSize: '21px',
      fontWeight: 700,
      color: 'white',
      textAlign: 'center',
    },
  }),
);

type Props = {
  heading: string;
};

const MerchantHeader: FC<Props> = ({ heading }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { push } = useRoleNavigation();
  const { currentBooth } = useSelector((state: RootState) => ({
    currentBooth: state.merchantBooths.data?.items.find(
      (booth) => booth.id === state.currentUser.data?.merchantBoothId,
    ),
  }));

  const handleClickClosedBooth = () => {
    push(PROFILE_ROUTE);
  };

  return (
    <header className={classes.container}>
      <div className={classes.headerContainer}>
        <div className={classes.topHeading}>{t('merchant.home.heading')}</div>
        <div className={classes.topSubheading}>{currentBooth?.name || ''}</div>
        {currentBooth && !currentBooth.open && (
          <Link href="#" onClick={handleClickClosedBooth}>
            <InfoWithIcon text={t('merchant.home.closedBooth')} />
          </Link>
        )}
        <h2 className={clsx(classes.heading)}>{heading}</h2>
      </div>
    </header>
  );
};

export default MerchantHeader;
