import { OrderStatusExtended } from './types';

// routes
export const MERCHANT_LOGIN_ROUTE = '/merchant/login';
export const MERCHANT_SET_PASSWORD_ROUTE = '/merchant/set-password';
export const MERCHANT_FORGOT_PASSWORD_ROUTE = '/merchant/forgot-password';

export const EVENT_SELECTION_ROUTE = '/select-event';
export const LOGIN_ROUTE = '/login';
export const REGISTER_ROUTE = '/register';
export const FORGOT_PASSWORD_ROUTE = '/forgot-password';
export const HOME_ROUTE = '/';
export const BOOTH_SELECTION_ROUTE = '/select-booth';
export const BOOTH_MENU_ROUTE = '/booth-menu';
export const BOOTH_INFO_ROUTE = '/booth-info';
export const CHECKOUT_ROUTE = '/checkout';
export const SET_PASSWORD_ROUTE = '/set-password';
export const PRODUCT_OVERVIEW_ROUTE = '/product-overview';
export const PROFILE_ROUTE = '/profile';
export const CHANGE_PASSWORD_ROUTE = '/change-password';
export const CHANGE_PROFILE_DATA_ROUTE = '/change-profile';
export const QR_SCAN_ROUTE = '/qr-scan';
export const ORDER_BY_BOOTH_NUMBER_ROUTE = '/order-by-booth-number';
export const ORDER_DETAILS_ROUTE = '/order-details';
export const PAYMENT_METHOD_SELECTION_ROUTE = '/select-payment-method';
export const MY_QR_ROUTE = '/my-qr';
export const MERCHANT_QR_FOR_CREW = '/qr-crew';
export const SCAN_ORDER_RESULT_ROUTE = '/order-scan-result';
export const ORDER_HISTORY_ROUTE = '/order-history';
export const MY_CREDITS_ROUTE = '/my-credits';
export const TOP_UP_WALLET_ROUTE = '/top-up-wallet';
export const PAYMENT_REDIRECT_ROUTE = '/payment';
export const TOP_UP_PAYMENT_REDIRECT_ROUTE = '/top-up-payment';

export const CREW_HOME_ROUTE = '/crew';
export const CREW_ACTIVATE_DEVICE_ROUTE = '/crew/activate-device';

// style values
export const TOP_BAR_HEIGHT = 49;
export const CREW_TOP_BAR_HEIGHT = 68;
export const MAX_INTERACTIVE_ELEMENT_WIDTH = 300;
export const MAX_LARGE_INTERACTIVE_ELEMENT_WIDTH = 460;

// other
export const SUPER_ADMIN_EMAIL = 'info@bar-e.be';

export const languages = [
  { name: 'EN', code: 'en' },
  { name: 'NL', code: 'nl' },
  { name: 'FR', code: 'fr' },
  { name: 'DE', code: 'de' },
];

export const STATUS_BACKGROUND_COLORS: { [key: string]: string } = {
  [OrderStatusExtended.Paid]: '#00cc79',
  [OrderStatusExtended.Helpdesk]: '#ff9400',
  [OrderStatusExtended.Retrieved]: '#ff4949',
  [OrderStatusExtended.WrongBooth]: '#ff4949',
  [OrderStatusExtended.Unknown]: '#ff4949',
};
