import { ComponentProps, FC } from 'react';
import {
  Button as MaterialButton,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import {
  MAX_INTERACTIVE_ELEMENT_WIDTH,
  MAX_LARGE_INTERACTIVE_ELEMENT_WIDTH,
} from 'shared/constants';
import clsx from 'clsx';

const useNormalStyles = makeStyles<
  Theme,
  {
    color?: string;
    primary?: boolean;
    rounded?: boolean;
    custom: boolean;
  }
>((theme) =>
  createStyles({
    button: {
      borderRadius: ({ primary, rounded, custom }) =>
        primary || rounded || custom ? '22px' : '14px',
      borderColor: ({ primary }) =>
        primary ? 'transparent' : theme.palette.grey['200'],
      height: '44px',
      width: '100%',
      maxWidth: `${MAX_INTERACTIVE_ELEMENT_WIDTH}px`,
      textTransform: 'none',
      backgroundColor: ({ primary, color }) => (primary ? color : undefined),
      lineHeight: 'normal',
    },
    text: {
      fontSize: ({ primary, rounded, custom }) =>
        primary || rounded || custom ? '16px' : '14px',
      fontWeight: 500,
    },
    outlined: {
      borderRadius: '14px',
      fontSize: '14px',
    },
  }),
);

const useLargeStyles = makeStyles<
  Theme,
  {
    color?: string;
    primary?: boolean;
  }
>(() =>
  createStyles({
    button: {
      borderRadius: '39px',
      borderColor: ({ primary, color }) => (primary ? 'transparent' : color),
      height: '78px',
      width: '100%',
      maxWidth: `${MAX_LARGE_INTERACTIVE_ELEMENT_WIDTH}px`,
      textTransform: 'none',
      backgroundColor: ({ primary, color }) => (primary ? color : undefined),
      lineHeight: 'normal',
    },
    text: {
      fontSize: '30px',
      fontWeight: 600,
    },
    outlined: {
      borderRadius: '14px',
      fontSize: '14px',
    },
  }),
);

const useSmallStyles = makeStyles<
  unknown,
  { color?: string; primary?: boolean }
>(() =>
  createStyles({
    button: {
      borderRadius: '16px',
      height: '33px',
      textTransform: 'none',
      backgroundColor: ({ primary, color }) => (primary ? color : undefined),
    },
    text: {
      fontSize: '14px',
      fontWeight: 500,
    },
  }),
);

type Props = {
  primary?: boolean;
  text: string;
  icon?: ComponentProps<typeof MaterialButton>['startIcon'];
  color?: string;
  rounded?: boolean;
  className?: string;
  small?: boolean;
  disabled?: boolean;
  custom?: boolean;
  large?: boolean;
  onClick?: () => void;
};

const Button: FC<Props> = ({
  primary,
  text,
  icon,
  color,
  rounded,
  small,
  className,
  disabled,
  large,
  custom = false,
  onClick,
}) => {
  const theme = useTheme();
  const getClasses = () => {
    if (large) {
      return useLargeStyles({ primary, color });
    }
    if (small) {
      return useSmallStyles({ color, primary });
    }
    return useNormalStyles({ color, primary, rounded, custom });
  };
  const classes = getClasses();

  const getButtonStyle = () => {
    if (disabled) {
      return undefined;
    }
    if (primary || custom) {
      return { color: 'white' };
    }
    return { color: color ?? theme.customPalette.labelGray };
  };

  return (
    <MaterialButton
      className={clsx(classes.button, className)}
      variant={primary || custom ? 'contained' : 'outlined'}
      color={primary ? 'primary' : 'inherit'}
      startIcon={icon}
      onClick={onClick}
      disabled={disabled}
      style={getButtonStyle()}
      disableElevation
    >
      <span className={classes.text}>{text}</span>
    </MaterialButton>
  );
};

export default Button;
