// import { useState } from 'react';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { SvgIcon, useMediaQuery, useTheme } from '@material-ui/core';
import { RootState } from 'core/store';
import { Button, OrDivider } from 'shared/components';
import {
  CREW_ACTIVATE_DEVICE_ROUTE,
  BOOTH_MENU_ROUTE,
  QR_SCAN_ROUTE,
} from 'shared/constants';
import { useRoleNavigation } from 'shared/helpers';
import { CrewLayout } from 'shared/layouts';
import { ReactComponent as QRCodeIcon } from 'assets/qr-code.svg';
import useStyles from './useStyles';
import { useTranslation } from 'react-i18next';

const CrewHome: FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  // const [scanText, setScanText] = useState('');
  const isTablet = useMediaQuery(useTheme().breakpoints.up('sm'));
  const { push } = useRoleNavigation();
  const isAuthenticatedCrewMember = useSelector(
    (state: RootState) =>
      state.currentUser.data?.role?.[0] === 'ROLE_CREW_MEMBER',
  );

  const handleClickActivateDevice = () => {
    push(CREW_ACTIVATE_DEVICE_ROUTE);
  };

  const handleClickScan = () => {
    push(QR_SCAN_ROUTE);
  };

  const handleClickPlaceOrder = () => {
    push(BOOTH_MENU_ROUTE);
  };

  return (
    <CrewLayout title={t('crew.home.title')} titleIcon={QRCodeIcon}>
      <div className={classes.container}>
        <div className={classes.contentContainer}>
          {!isAuthenticatedCrewMember ? (
            <Button
              text={t('crew.home.activateDevice')}
              large={isTablet}
              primary
              icon={
                <SvgIcon
                  component={QRCodeIcon}
                  className={classes.buttonIcon}
                />
              }
              onClick={handleClickActivateDevice}
            />
          ) : (
            <>
              {/* <div className={classes.codeContainer}>
                <span className={classes.codeText}>
                  {scanText || t('crew.home.codePlaceholder')}
                </span>
              </div> */}
              <Button
                className={classes.scanButton}
                text={t('crew.home.scanButtonText')}
                large={isTablet}
                primary
                icon={
                  <SvgIcon
                    component={QRCodeIcon}
                    className={classes.buttonIcon}
                  />
                }
                onClick={handleClickScan}
              />
              <OrDivider large={isTablet} />
              <Button
                className={classes.placeOrderButton}
                text={t('crew.home.placeOrderButtonText')}
                large={isTablet}
                color="#140048"
                rounded
                onClick={handleClickPlaceOrder}
              />
            </>
          )}
        </div>
      </div>
    </CrewLayout>
  );
};

export default CrewHome;
