import { SvgIcon } from '@material-ui/core';
import { Formik } from 'formik';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import * as Yup from 'yup';
import { ReactComponent as EmailIcon } from 'assets/email.svg';
import { postForgotPassword } from 'core/requests';
import { messageSlice } from 'core/store';
import { Input, Button } from 'shared/components';
import { CardLayout } from 'shared/layouts';
import { useLoginStyles } from 'shared/styles';

const initialValues = {
  email: '',
};

const ForgotPassword: FC = () => {
  const classes = useLoginStyles();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string()
          .required(t('validation.required'))
          .email(t('validation.invalidEmail')),
      }),
    [i18n.language],
  );

  const handleFormSubmit = async (values: typeof initialValues) => {
    try {
      await postForgotPassword({ ...values });
      dispatch(
        messageSlice.actions.showMessage({
          text: t('forgotPassword.message.resetRequestSuccess'),
          type: 'success',
        }),
      );
    } catch (err) {
      dispatch(
        messageSlice.actions.showMessage({
          text: t('forgotPassword.message.resetRequestFail'),
          type: 'error',
        }),
      );
    }
  };

  return (
    <CardLayout
      contentTitle={t('forgotPassword.title')}
      contentDescription={t('forgotPassword.description')}
      backgroundColor="white"
      onBackClick={history.goBack}
      maxContentWidth={340}
      centered
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={false}
        onSubmit={handleFormSubmit}
      >
        {({ errors, handleChange, handleSubmit }) => (
          <div className={classes.container}>
            <Input
              name="email"
              icon={
                <SvgIcon component={EmailIcon} className={classes.inputIcon} />
              }
              placeholder={t('forgotPassword.emailPlaceholder')}
              className={classes.input}
              onChange={handleChange}
              error={errors.email}
            />
            <Button
              className={classes.button}
              text={t('forgotPassword.sendButtonText')}
              primary
              onClick={handleSubmit}
            />
          </div>
        )}
      </Formik>
    </CardLayout>
  );
};

export default ForgotPassword;
