import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { ShoppingCart } from '@material-ui/icons';
import { RootState } from 'core/store';
import { useRoleNavigation, getValueWithCurrencyString } from 'shared/helpers';
import { CHECKOUT_ROUTE } from 'shared/constants';
import Button from './Button';
import { InfoWithIcon } from '.';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkoutContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '16px 17px 8px 17px',
    },
    totalContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginRight: '45px',
    },
    amountContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '8px',
    },
    cartIcon: {
      fontSize: '32px',
      color: 'white',
    },
    totalTitle: {
      fontSize: '12px',
      lineHeight: '14px',
      color: theme.customPalette.accentGreen,
      marginBottom: '4px',
      textTransform: 'uppercase',
    },
    totalNumber: {
      fontSize: '18px',
      color: 'white',
      lineHeight: '14px',
      whiteSpace: 'pre',
    },
    multipleLocations: {
      marginLeft: '20px',
    },
  }),
);

const CheckoutFooter: FC = () => {
  const classes = useStyles();
  const { push } = useRoleNavigation();
  const { t } = useTranslation();
  const { products, total } = useSelector(
    (state: RootState) => state.orderedProducts,
  );

  const multipleLocations = useMemo(
    () =>
      Object.values(products).some(
        (item, _, arr) => item.boothTypeId !== arr[0]?.boothTypeId,
      ),
    [products],
  );

  const handlePlaceOrderClick = () => {
    push(CHECKOUT_ROUTE);
  };

  const currency = Object.values(products)[0]?.amount?.currency || '';

  const priceWithCurrency = useMemo(
    () => getValueWithCurrencyString({ value: total || 0, currency }),
    [currency, total],
  );

  return (
    <div>
      <div className={classes.checkoutContainer}>
        <div className={classes.totalContainer}>
          <ShoppingCart className={classes.cartIcon} />
          <div className={classes.amountContainer}>
            <span className={classes.totalTitle}>total</span>
            <span className={classes.totalNumber}>{priceWithCurrency}</span>
          </div>
        </div>
        <Button primary text="Place order" onClick={handlePlaceOrderClick} />
      </div>
      {multipleLocations && (
        <InfoWithIcon
          className={classes.multipleLocations}
          text={t('checkout.multipleLocations')}
        />
      )}
    </div>
  );
};

export default CheckoutFooter;
