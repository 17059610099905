import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    ordersContainer: {
      '& > *:not(:last-child)': {
        marginBottom: 20,
      },
    },
  }),
);

export default useStyles;
