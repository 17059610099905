import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getUserIdentityToken } from 'core/requests';
import { loaderSlice, messageSlice } from 'core/store';
import { FlatLayout } from 'shared/layouts';
import { MerchantHeader, ScreenFooter, QrCode } from 'shared/components';
import { useRoleNavigation } from 'shared/helpers';
import { QrCodeType } from 'shared/types';
import useStyles from './styles';

const MerchantQrForCrew: FC = () => {
  const classes = useStyles();
  const { goBack } = useRoleNavigation();
  const { t } = useTranslation();
  const [qrData, setQrData] = useState<string | null>(null);
  const [seconds, setSeconds] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      (async () => {
        dispatch(loaderSlice.actions.setLoaderState({ loading: true }));
        try {
          const {
            data: { data },
          } = await getUserIdentityToken();
          setQrData(data.token);
        } catch {
          dispatch(
            messageSlice.actions.showMessage({
              text: t('myQr.message.fetchIdentityTokenFail'),
              type: 'error',
            }),
          );
        } finally {
          dispatch(loaderSlice.actions.setLoaderState({ loading: false }));
        }
      })();
      setSeconds(300);
    }
  }, [seconds]);

  return (
    <FlatLayout
      header={<MerchantHeader heading={t('merchant.qrCrew.title')} />}
      footer={<ScreenFooter fixed className={classes.footer} />}
      onBackClick={goBack}
    >
      <div className={classes.container}>
        <div className={classes.menuItemsContainer}>
          <QrCode type={QrCodeType.ActivateDevice} value={qrData} />
          <div>
            {t('myQr.tokenWillRefreshIn')} {seconds}
          </div>
          <div className={classes.descriptionContainer}>
            <h3 className={classes.descriptionTitle}>
              {t('merchant.qrCrew.descriptionTitle')}
            </h3>
            <div className={classes.description}>
              {t('merchant.qrCrew.description')}
            </div>
          </div>
        </div>
      </div>
    </FlatLayout>
  );
};

export default MerchantQrForCrew;
