import { ElementType, FC } from 'react';
import {
  createStyles,
  Link,
  makeStyles,
  SvgIcon,
  Theme,
} from '@material-ui/core';

const useStyles = makeStyles<Theme, { isActive: boolean | undefined }>(
  (theme: Theme) =>
    createStyles({
      menuItemContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: '17px',
      },
      menuItemIcon: {
        marginRight: '9px',
        fontSize: '18px',
        color: theme.palette.primary.main,
      },
      menuItemText: {
        fontSize: '15px',
        fontWeight: 700,
        color: ({ isActive }) =>
          isActive ? theme.palette.primary.main : 'white',
        lineHeight: '24px',
      },
    }),
);

type Props = {
  icon: ElementType;
  text: string;
  onClick?: () => void;
  isActive?: boolean;
};

const MenuItem: FC<Props> = ({ icon, text, onClick, isActive }) => {
  const classes = useStyles({ isActive });

  return (
    <Link className={classes.menuItemContainer} type="button" onClick={onClick}>
      <SvgIcon component={icon} className={classes.menuItemIcon} />
      <span className={classes.menuItemText}>{text}</span>
    </Link>
  );
};

export default MenuItem;
