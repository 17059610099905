import { call, put, takeEvery } from 'redux-saga/effects';
import { getMerchantBoothList } from 'core/requests';
import { PromiseResult } from 'shared/types';
import i18next from 'i18next';
import { merchantBoothsSlice } from './';
import { messageSlice } from '../message';

function* getMerchantBoothsSaga() {
  try {
    const {
      data: { data },
    }: PromiseResult<ReturnType<typeof getMerchantBoothList>> = yield call(
      getMerchantBoothList,
    );
    yield put(merchantBoothsSlice.actions.getMerchantBoothsSuccess(data));
  } catch {
    yield put(merchantBoothsSlice.actions.getMerchantBoothsFailure());
    yield put(
      messageSlice.actions.showMessage({
        text: i18next.t('merchant.message.fetchMerchantBoothsFail'),
        type: 'warning',
      }),
    );
  }
}

export function* getMerchantBoothsSagaWatcher() {
  yield takeEvery(
    merchantBoothsSlice.actions.getMerchantBoothsRequest,
    getMerchantBoothsSaga,
  );
}
