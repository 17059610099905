import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { parseISO, format } from 'date-fns';
import { Link } from '@material-ui/core';
import { ReactComponent as CalendarIcon } from 'assets/calendar.svg';
import { ReactComponent as EmailIcon } from 'assets/email.svg';
import { ReactComponent as KeyIcon } from 'assets/key.svg';
import { ReactComponent as UserIcon } from 'assets/user.svg';
import {
  CHANGE_PASSWORD_ROUTE,
  CHANGE_PROFILE_DATA_ROUTE,
} from 'shared/constants';
import { InfoItem } from 'shared/components';
import { CardLayout } from 'shared/layouts';
import { useRoleNavigation } from 'shared/helpers';
import { RootState } from 'core/store';
import useStyles from './useStyles';

const Profile: FC = () => {
  const { t } = useTranslation();
  const { goBack } = useHistory();
  const { push } = useRoleNavigation();
  const classes = useStyles();
  const currentUser = useSelector((state: RootState) => state.currentUser.data);

  const handleChangeProfileDataClick = () => {
    push(CHANGE_PROFILE_DATA_ROUTE);
  };

  const handleChangePasswordClick = () => {
    push(CHANGE_PASSWORD_ROUTE);
  };

  return (
    <CardLayout
      title={t('profile.title')}
      contentTitle={t('profile.contentTitle')}
      contentDescription={t('profile.contentDescription')}
      onBackClick={goBack}
      maxContentWidth={750}
    >
      <InfoItem
        icon={UserIcon}
        text={`${currentUser?.firstName || ''} ${currentUser?.lastName || ''}`}
      />
      <InfoItem icon={EmailIcon} text={currentUser?.email || ''} />
      <span className={classes.sectionTitle}>{t('profile.dob')}</span>
      <InfoItem
        icon={CalendarIcon}
        text={
          currentUser?.dateOfBirth
            ? format(parseISO(currentUser.dateOfBirth), 'dd / MM / yyyy')
            : ''
        }
      />
      <Link
        onClick={handleChangeProfileDataClick}
        className={classes.actionText}
      >
        {t('profile.changeData')}
      </Link>
      <span className={classes.sectionTitle}>{t('profile.password')}</span>
      <InfoItem icon={KeyIcon} text="**************" />
      <Link onClick={handleChangePasswordClick} className={classes.actionText}>
        {t('profile.changePassword')}
      </Link>
    </CardLayout>
  );
};

export default Profile;
