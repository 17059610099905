import { FC } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { ChevronRight, LocationOn } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { Card } from 'shared/components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      width: '100%',
      margin: '20px 20px 0 20px',
      padding: '23px 19px 23px 20px',
    },
    topRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '7px',
    },
    nameText: {
      fontSize: '17px',
      lineHeight: '14px',
      fontWeight: 600,
      color: theme.customPalette.textPrimary,
    },
    icon: {
      fontSize: '24px',
      color: theme.customPalette.textPrimary,
    },
    bottomRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    boothNumberContainer: {
      height: '22px',
      paddingLeft: '8px',
      paddingRight: '8px',
      borderRadius: '11px',
      backgroundColor: '#cde5f9',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: '12px',
    },
    boothNumberText: {
      fontSize: '12px',
      fontWeight: 600,
      color: theme.customPalette.blueText,
    },
    locationText: {
      fontSize: '14px',
      color: theme.customPalette.labelGray,
      marginRight: '4px',
      fontWeight: 600,
    },
  }),
);

type Props = {
  name: string;
  number: number;
  location?: string;
  id: number;
  onClick: () => void;
};

const BoothCard: FC<Props> = ({ name, number, location, id, onClick }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Card key={id} className={classes.card} onClick={onClick}>
      <div className={classes.topRow}>
        <span role="heading" className={classes.nameText}>
          {name}
        </span>
        <ChevronRight className={classes.icon} />
      </div>
      <div className={classes.bottomRow}>
        <div className={classes.boothNumberContainer}>
          <span className={classes.boothNumberText}>
            {t('booth.boothNumberText', { number })}
          </span>
        </div>
        {location && (
          <div className={classes.bottomRow}>
            <LocationOn className={classes.locationText} />
            <span className={classes.locationText}>{location}</span>
          </div>
        )}
      </div>
    </Card>
  );
};

export default BoothCard;
