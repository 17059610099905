import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { CheckCircle, Sync, HighlightOff } from '@material-ui/icons';
import { FC } from 'react';
import { theme } from 'theme';
import { getStatusColor } from 'shared/helpers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      color: theme.palette.primary.main,
    },
    icon: {
      fontSize: '14px',
      marginRight: 8,
    },
    text: {
      fontSize: '1.125rem',
      fontWeight: 'bold',
    },
  }),
);

type Props = {
  text: string;
  status: 'success' | 'warning' | 'failure';
};

const Status: FC<Props> = ({ text, status }) => {
  const classes = useStyles();

  const color = getStatusColor(status);

  return (
    <div className={classes.root} style={{ color }}>
      {status === 'success' && <CheckCircle className={classes.icon} />}
      {status === 'warning' && <Sync className={classes.icon} />}
      {status === 'failure' && <HighlightOff className={classes.icon} />}
      <span className={classes.text}>{text}</span>
    </div>
  );
};

export default Status;
