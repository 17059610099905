import { FC } from 'react';
import { createStyles, makeStyles, SvgIcon } from '@material-ui/core';
import { ReactComponent as Booth } from 'assets/booth-2.svg';
import { Button } from 'shared/components';
import { ORDER_BY_BOOTH_NUMBER_ROUTE } from 'shared/constants';
import { useTranslation } from 'react-i18next';
import { useRoleNavigation } from 'shared/helpers';

const useStyles = makeStyles(() =>
  createStyles({
    footerContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '16px',
    },
    boothNumberContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    footerTextContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginRight: '12px',
      marginLeft: '6px',
      paddingTop: '4px',
      lineHeight: 1.2,
    },
    footerUpperText: {
      fontSize: '15px',
      fontWeight: 200,
      whiteSpace: 'nowrap',
      color: 'white',
    },
    footerLowerText: {
      fontSize: '15px',
      fontWeight: 600,
      whiteSpace: 'nowrap',
      color: 'white',
    },
    boothImage: {
      fontSize: '40px',
    },
  }),
);

const PlaceOrderFooter: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { push } = useRoleNavigation();

  const handleClickPlaceOrder = () => {
    push(ORDER_BY_BOOTH_NUMBER_ROUTE);
  };

  return (
    <div className={classes.footerContainer}>
      <div className={classes.boothNumberContainer}>
        <SvgIcon className={classes.boothImage} component={Booth} />
        <div className={classes.footerTextContainer}>
          <span className={classes.footerUpperText}>
            {t('placeOrderFooter.footerTopText')}
          </span>
          <span className={classes.footerLowerText}>
            {t('placeOrderFooter.footerBottomText')}
          </span>
        </div>
      </div>
      <Button
        text={t('placeOrderFooter.placeOrderButtonText')}
        primary
        onClick={handleClickPlaceOrder}
      />
    </div>
  );
};

export default PlaceOrderFooter;
