import { ElementType, FC, ReactNode, useEffect, useRef, useState } from 'react';
import { createStyles, makeStyles, SvgIcon, Theme } from '@material-ui/core';
import logoIcon from 'assets/logo.svg';
import { CREW_TOP_BAR_HEIGHT } from 'shared/constants';

const useStyles = makeStyles<
  Theme,
  { backgroundColor?: string; centered?: boolean }
>((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      width: '100%',
      backgroundColor: ({ backgroundColor }) =>
        backgroundColor || theme.customPalette.background,
      [theme.breakpoints.up('sm')]: {
        height: '100vh',
      },
    },
    topBarContainer: {
      flex: '0 0 auto',
      width: '100%',
      height: `${CREW_TOP_BAR_HEIGHT}px`,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: theme.customPalette.background,
    },
    topBarTitleContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    topBarRightSpacer: {
      width: '40px',
      height: '40px',
      marginRight: '14px',
    },
    logoImage: {
      width: '40px',
      height: '40px',
      marginLeft: '14px',
    },
    title: {
      fontSize: '21px',
      fontWeight: 700,
      color: '#fff',
    },
    titleIcon: {
      fontSize: '21px',
      color: '#fff',
      marginRight: '15px',
    },
    bodyContainer: {
      flex: '1 0 auto',
      boxSizing: 'border-box',
      display: 'flex',
    },
    body: ({ centered }) => ({
      boxSizing: 'border-box',
      display: 'flex',
      justifyContent: 'center',
      alignItems: centered ? 'center' : 'flex-start',
      flexGrow: 1,
      flexWrap: 'wrap',
      overflowX: 'auto',
    }),
    footer: {
      boxSizing: 'border-box',
      minHeight: '140px',
      flex: '0 0 auto',
      backgroundColor: 'white',
      padding: '16px 9px',
      borderTopRightRadius: '22px',
      borderTopLeftRadius: '22px',
    },
  }),
);

type Props = {
  title: string;
  footer?: ReactNode;
  titleIcon?: ElementType;
  backgroundColor?: string;
  centered?: boolean;
};

const CrewLayout: FC<Props> = ({
  title,
  footer,
  titleIcon,
  children,
  backgroundColor,
  centered,
}) => {
  const classes = useStyles({ backgroundColor, centered });
  const [footerHeight, setFooterHeight] = useState(140);
  const footerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;
    if (footerRef.current) {
      const handleResize = () => {
        if (timeout) {
          clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
          setFooterHeight(footerRef.current!.clientHeight);
        }, 600);
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.topBarContainer}>
        <img className={classes.logoImage} src={logoIcon} />
        <div className={classes.topBarTitleContainer}>
          {titleIcon && (
            <SvgIcon className={classes.titleIcon} component={titleIcon} />
          )}
          <span className={classes.title}>{title}</span>
        </div>
        <div className={classes.topBarRightSpacer} />
      </div>
      <div
        className={classes.bodyContainer}
        style={{
          height: `calc(100% - ${CREW_TOP_BAR_HEIGHT}px - ${footerHeight}px)`,
        }}
      >
        <div className={classes.body}>{children}</div>
      </div>
      {footer && (
        <div ref={footerRef} className={classes.footer}>
          {footer}
        </div>
      )}
    </div>
  );
};

export default CrewLayout;
