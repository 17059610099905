import i18next from 'i18next';
import { call, put, takeEvery } from 'redux-saga/effects';
import { getCurrentUser } from 'core/requests';
import { signOut } from 'shared/helpers';
import { PromiseResult } from 'shared/types';
import { currentUserSlice } from './';
import { categoriesSlice } from '../categories';
import { merchantBoothsSlice } from '../merchantBooths';
import { orderedProductsSlice } from '../orderedProducts';

function* getOnlyCurrentUserSaga() {
  try {
    const {
      data: { data },
    }: PromiseResult<ReturnType<typeof getCurrentUser>> = yield call(
      getCurrentUser,
    );
    yield put(currentUserSlice.actions.getCurrentUserSuccess(data));
    if (data.role.includes('ROLE_MERCHANT')) {
      yield put(categoriesSlice.actions.getCategoriesRequest());
    }
  } catch (error) {
    yield put(currentUserSlice.actions.getCurrentUserFailure());
    signOut();
  }
}

export function* getOnlyCurrentUserSagaWatcher() {
  yield takeEvery(
    currentUserSlice.actions.getOnlyCurrentUserRequest,
    getOnlyCurrentUserSaga,
  );
}

function* getCurrentUserSaga() {
  try {
    const {
      data: { data },
    }: PromiseResult<ReturnType<typeof getCurrentUser>> = yield call(
      getCurrentUser,
    );
    yield put(currentUserSlice.actions.getCurrentUserSuccess(data));
    i18next.changeLanguage(data.language);
    yield put(categoriesSlice.actions.getCategoriesRequest());
    if (data.role.includes('ROLE_VISITOR')) {
      yield put(orderedProductsSlice.actions.getOrderDraftRequest());
    }
    if (data.role.includes('ROLE_MERCHANT')) {
      yield put(merchantBoothsSlice.actions.getMerchantBoothsRequest());
    }
  } catch (error) {
    yield put(currentUserSlice.actions.getCurrentUserFailure());
    signOut();
  }
}

export function* getCurrentUserSagaWatcher() {
  yield takeEvery(
    currentUserSlice.actions.getCurrentUserRequest,
    getCurrentUserSaga,
  );
}
