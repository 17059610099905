import { QrCodeType, QrCodeValue } from 'shared/types';

const getQrCodeValueString = (type: QrCodeType, value: number | string) => {
  const qrCodeValue: QrCodeValue = {
    type,
    value,
  };
  return JSON.stringify(qrCodeValue);
};

export default getQrCodeValueString;
