import { FC, useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState, orderedProductsSlice, messageSlice } from 'core/store';
import { getBoothTypeInfo, getBoothTypeMenuItems } from 'core/requests';
import { LocationOn, Warning } from '@material-ui/icons';
import { FlatLayout } from 'shared/layouts';
import {
  BoothHeader,
  BoothMenuItem,
  // Button,
  CheckoutFooter,
  ScreenFooter,
} from 'shared/components';
import { BoothTypeInfo as BoothTypeInfoType, Product } from 'shared/types';
import useStyles from './useStyles';
import Photo from './Photo';

const BoothTypeInfo: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { goBack } = useHistory();
  const dispatch = useDispatch();
  const { boothNumber } = useParams<{ boothNumber: string }>();
  const { products } = useSelector((state: RootState) => state.orderedProducts);
  const { closingHour } = {
    closingHour: 22,
  };
  const [boothMenuItems, setBoothMenuItems] = useState<Product[]>([]);
  const [focusedMenuItem, setFocusedMenuItem] = useState<string | null>(null);
  const [boothInfo, setBoothTypeInfo] =
    useState<BoothTypeInfoType | null>(null);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await getBoothTypeMenuItems(boothNumber);
        setBoothMenuItems(data.data.items);
      } catch {
        dispatch(
          messageSlice.actions.showMessage({
            text: t('message.boothInfoFetchError'),
            type: 'error',
          }),
        );
      }
    })();
    (async () => {
      try {
        const { data } = await getBoothTypeInfo(boothNumber);
        setBoothTypeInfo(data.data);
      } catch {
        dispatch(
          messageSlice.actions.showMessage({
            text: t('message.boothMenuFetchError'),
            type: 'error',
          }),
        );
      }
    })();
  }, []);

  const getCategoryId = () => {
    if (boothMenuItems.length) {
      const categoryId = boothMenuItems[0].categoryId;
      return boothMenuItems.some((item) => item.categoryId !== categoryId)
        ? undefined
        : categoryId;
    }
    return undefined;
  };

  const handleItemQuantityChange = (uuid: string, increase: boolean) => () => {
    if (increase) {
      dispatch(
        orderedProductsSlice.actions.addOrderedProduct(
          boothMenuItems.find((item) => item.uuid === uuid)!,
        ),
      );
    } else {
      const removedProduct = products[uuid];
      if (removedProduct?.quantity === 1) {
        dispatch(orderedProductsSlice.actions.deleteOrderedProduct(uuid));
      } else {
        dispatch(orderedProductsSlice.actions.subtractOrderedProduct(uuid));
      }
    }

    setFocusedMenuItem(uuid);
  };

  const handleItemQuantityClick = (uuid: string) => () => {
    setFocusedMenuItem(uuid);
  };

  const renderMenuItem = (item: Product) => (
    <BoothMenuItem
      key={item.uuid}
      {...item}
      quantity={products[item.uuid]?.quantity ?? 0}
      highlightSelected
      focused={item.uuid === focusedMenuItem}
      onQuantityClick={handleItemQuantityClick(item.uuid)}
      onIncrease={handleItemQuantityChange(item.uuid, true)}
      onDecrease={handleItemQuantityChange(item.uuid, false)}
    />
  );

  const renderTag = (tag: { id: number; name: string }) => (
    <span key={tag.id} className={classes.tag}>
      {tag.name}
    </span>
  );

  const renderPhoto = (photo: string) => <Photo key={photo} url={photo} />;

  return (
    <FlatLayout
      backgroundOverflow={192}
      brightBackground
      onBackClick={goBack}
      header={
        <BoothHeader
          name={boothInfo?.name ?? ''}
          number={Number(boothInfo?.number)}
          categoryId={getCategoryId()}
        />
      }
      footer={
        <ScreenFooter fixed>
          <CheckoutFooter />
        </ScreenFooter>
      }
    >
      <div className={classes.tagsContainer}>
        {boothInfo?.tags.map(renderTag)}
      </div>
      <div className={classes.photosContainer}>
        {boothInfo?.typeImages.map(renderPhoto)}
      </div>
      <span className={classes.sectionTitle}>{t('booth.about')}</span>
      <p className={classes.aboutText}>{boothInfo?.typeDescription}</p>
      <span className={classes.sectionTitle}>{t('booth.location')}</span>
      <div className={classes.locationContainer}>
        <div>
          <LocationOn className={classes.locationIcon} />
          <span className={classes.locationText}>{boothInfo?.zone}</span>
        </div>
        {/* <Button small text={t('booth.viewOnMap')} color="#979797" /> */}
      </div>
      <div className={classes.closingTimeContainer}>
        <Warning className={classes.closingTimeIcon} />
        <span className={classes.closingTimeText}>
          {t('booth.closesAt', {
            location: boothInfo?.zone ?? '',
            hour: closingHour,
          })}
        </span>
      </div>
      <div className={classes.menuContainer}>
        <span className={classes.menuTitle}>{t('booth.placeOrder')}</span>
        <div>{boothMenuItems.map(renderMenuItem)}</div>
      </div>
    </FlatLayout>
  );
};

export default BoothTypeInfo;
