import { createStyles, Link, makeStyles } from '@material-ui/core';
import { LocationOn } from '@material-ui/icons';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Chip } from 'shared/components';
import {
  OrderItem,
  Amount as AmountType,
  OrderDetailsBoothOrder,
} from 'shared/types';
import Amount from './Amount';
import LabeledValue from './LabeledValue';

const useStyles = makeStyles(() =>
  createStyles({
    section: {
      color: '#393f44',
      '& h3': {
        margin: '0 0 1.25rem 0',
        fontSize: '1.3125rem',
        fontWeight: 'bold',
      },
    },
    secondaryText: {
      paddingBottom: '1.25rem',
      fontSize: '0.9375rem',
    },
    boothInfo: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    linkWithIcon: {
      display: 'inline-flex',
      alignItems: 'center',
      fontSize: '0.875rem',
      color: '#4f329a',
      '& .MuiSvgIcon-root': {
        fontSize: '0.9375rem',
      },
    },
    list: {
      listStyle: 'none',
      margin: '0.625rem 0 2.125rem 0',
      padding: '0 0.625rem',
    },
  }),
);

type Props = {
  number: number;
  date?: string;
  boothOrders: OrderDetailsBoothOrder[];
  totalAmount: AmountType;
};

const OrderSection: FC<Props> = ({
  children,
  number,
  date,
  boothOrders,
  totalAmount,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const renderItem = (item: OrderItem) => (
    <li key={item.uuid}>
      <LabeledValue label={item.name} value={item.quantity} />
    </li>
  );

  const renderBoothOrder = (boothOrder: OrderDetailsBoothOrder) => (
    <div key={boothOrder.boothType}>
      <div className={classes.boothInfo}>
        <Chip
          label={t('booth.boothNumberTextWithColon', {
            number: boothOrder.boothType,
          })}
        />
        <Link className={classes.linkWithIcon}>
          <LocationOn />
          <span>{boothOrder.boothZone}</span>
        </Link>
      </div>
      <ul className={classes.list}>{boothOrder.items.map(renderItem)}</ul>
    </div>
  );

  return (
    <section className={classes.section}>
      <h3>{`${t('orderDetails.order')}: ${
        number === -1 ? t('orderDetails.draftNumberReplacement') : number
      }`}</h3>
      {date && (
        <div className={classes.secondaryText}>
          {t('orderDetails.orderedOn', { dateTime: date })}
        </div>
      )}
      {boothOrders.map(renderBoothOrder)}
      <LabeledValue
        label={t('orderDetails.totalAmount')}
        value={<Amount amount={totalAmount} />}
      />
      {children}
    </section>
  );
};

export default OrderSection;
