import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    contentBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    bodyButton: {
      marginTop: 20,
    },
  }),
);

export default useStyles;
