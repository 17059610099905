import BoothTypeInfo from './BoothTypeInfo';
import BoothMenu from './BoothMenu';
import BoothSelection from './BoothSelection';
import MerchantProductOverview from './MerchantProductOverview';
import OrderByBoothNumber from './OrderByBoothNumber';

export default {
  BoothTypeInfo,
  BoothMenu,
  BoothSelection,
  MerchantProductOverview,
  OrderByBoothNumber,
};
