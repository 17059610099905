import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'center',
    },
    menuItemsContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
      margin: '22px 0 0 0',
      maxWidth: '750px',
    },
    card: {
      width: '130px',
      height: '130px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '12px',
      color: theme.customPalette.textPrimary,
      '& svg': {
        fill: 'currentColor',
      },
    },
    longCard: {
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      width: '284px',
      margin: '12px 12px 24px 12px',
      padding: '12px 12px 12px 16px',
      color: '#220961',
      ['@media (min-width: 460px)']: {
        height: '130px',
      },
    },
    activateButtonLabel: {
      fontSize: '17px',
      color: theme.customPalette.background,
    },
    activateButtonDescription: {
      fontSize: '14px',
      color: '#7B8084',
    },
    menuItemImage: {
      marginBottom: '5px',
      fontSize: '60px',
    },
    longMenuItemImage: {
      marginRight: '11px',
      fontSize: '60px',
      color: '#000',
    },
    menuItemName: {
      fontSize: '15px',
      color: theme.customPalette.background,
      paddingLeft: '12px',
      paddingRight: '12px',
      textAlign: 'center',
    },
    footer: {
      height: 38,
    },
    cardBodyWithCount: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    topContainer: {
      padding: '18px 12px 12px 12px',
    },
    menuItemImageSmall: {
      display: 'block',
      fontSize: '24px',
      color: theme.customPalette.background,
    },
    menuItemNameSmall: {
      fontSize: '13px',
      color: theme.customPalette.background,
    },
    bottomContainer: {
      height: 30,
      padding: '0 12px 12px 12px',
      fontSize: '15px',
      color: theme.customPalette.labelGray,
    },
    itemsCountValue: {
      color: theme.customPalette.textPrimary,
      fontWeight: theme.typography.fontWeightBold,
    },
  }),
);

export default useStyles;
