import { Typography } from '@material-ui/core';
import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SwipeableViews from 'react-swipeable-views';
import { Button } from 'shared/components';
import Pagination from './Pagination';
import screens from './screens';
import useStyles from './styles';

type Props = {
  onDismiss: () => void;
};

const IntroScreens: FunctionComponent<Props> = ({ onDismiss }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [index, setIndex] = useState(0);

  const handleChangeIndex = (idx: number) => {
    setIndex(idx);
  };

  const handleClickNext = () => {
    setIndex((currentIndex) => currentIndex + 1);
  };

  const renderScreen = ({ key, image }: typeof screens[0]) => (
    <div key={key} className={classes.slide}>
      <div key={key} className={classes.slideContainer}>
        <Typography className={classes.primaryHeading} variant="h2">
          {t(`introScreens.${key}.primaryHeading`)}
        </Typography>
        {<img src={image} width="125" height="125" alt={key} />}
        <Typography className={classes.secondaryHeading} variant="h3">
          {t(`introScreens.${key}.secondaryHeading`)}
        </Typography>
        <Typography className={classes.description}>
          {t(`introScreens.${key}.description`)}
        </Typography>
        {key === 'screen3' && (
          <Button
            primary
            text={t('introScreens.getStarted')}
            onClick={onDismiss}
          />
        )}
      </div>
    </div>
  );

  return (
    <div className={classes.root}>
      <SwipeableViews index={index} onChangeIndex={handleChangeIndex}>
        {screens.map(renderScreen)}
      </SwipeableViews>
      {index < 2 && (
        <div className={classes.footer}>
          <Pagination activeIndex={index} onClickNext={handleClickNext} />
        </div>
      )}
    </div>
  );
};

export default IntroScreens;
