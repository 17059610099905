import { FC } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import clsx from 'clsx';

const useStyles = makeStyles(() =>
  createStyles({
    infoWithIcon: {
      fontSize: '13px',
      color: '#ff9400',
      display: 'flex',
      alignItems: 'center',
      marginBottom: '18px',
    },
    infoIcon: {
      fontSize: '13px',
      marginRight: '6px',
      paddingBottom: '3px',
    },
  }),
);

type Props = {
  className?: string;
  text: string;
};

const InfoWithIcon: FC<Props> = ({ className, text }) => {
  const classes = useStyles();

  return (
    <span className={clsx(classes.infoWithIcon, className)}>
      <Info className={classes.infoIcon} />
      {text}
    </span>
  );
};

export default InfoWithIcon;
