import { createSlice } from '@reduxjs/toolkit';
import { LoaderState } from '.';
import loaderReducer from './reducer';

const initialState: LoaderState = {
  loading: false,
};

const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: { setLoaderState: loaderReducer },
});

export default loaderSlice;
