import { PayloadAction } from '@reduxjs/toolkit';
import { UserResponse } from 'shared/types';
import { CurrentUserState } from './index';

const reducers = {
  getOnlyCurrentUserRequest: (state: CurrentUserState) => ({
    ...state,
    loading: true,
  }),
  getCurrentUserRequest: (state: CurrentUserState) => ({
    ...state,
    loading: true,
  }),
  getCurrentUserSuccess: (
    state: CurrentUserState,
    { payload }: PayloadAction<UserResponse>,
  ) => ({ ...state, data: payload, loading: false }),
  getCurrentUserFailure: (state: CurrentUserState) => ({
    ...state,
    loading: false,
  }),
  resetCurrentUserState: () => ({
    data: undefined,
    loading: false,
  }),
};

export default reducers;
