import ChangePassword from './ChangePassword';
import ChangeProfileData from './ChangeProfileData';
import CrewHome from './CrewHome';
import MerchantHome from './MerchantHome';
import MerchantProfile from './MerchantProfile';
import MerchantQrForCrew from './MerchantQrForCrew';
import MyQr from './MyQr';
import Profile from './Profile';
import QrScan from './QrScan';
import VisitorHome from './VisitorHome';
import MyCredits from './MyCredits';

export default {
  ChangePassword,
  ChangeProfileData,
  CrewHome,
  MerchantHome,
  MerchantProfile,
  MerchantQrForCrew,
  Profile,
  MyQr,
  QrScan,
  VisitorHome,
  MyCredits,
};
