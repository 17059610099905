import {
  createStyles,
  makeStyles,
  Link,
  LinkTypeMap,
  Theme,
} from '@material-ui/core';
import { FC } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      color: theme.palette.primary.main,
    },
  }),
);

type Props = {
  onClick: () => void;
  text: string;
};

const LinkButton: FC<
  Props & Partial<LinkTypeMap<Record<string, unknown>, 'a'>>
> = ({ onClick, text, ...props }) => {
  const classes = useStyles();

  return (
    <Link
      classes={classes}
      component="button"
      variant="body2"
      color="inherit"
      onClick={onClick}
      {...props}
    >
      {text}
    </Link>
  );
};

export default LinkButton;
