import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Button, ScreenFooter } from 'shared/components';
import useStyles from './useStyles';

const Footer: FC = () => {
  const classes = useStyles({});
  const { t } = useTranslation();
  const { goBack } = useHistory();

  const handleClickCancel = () => {
    goBack();
  };
  return (
    <ScreenFooter fixed className={classes.footer} transparent>
      <Button text={t('common.cancel')} onClick={handleClickCancel} />
    </ScreenFooter>
  );
};

export default Footer;
