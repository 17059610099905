import { createSlice } from '@reduxjs/toolkit';
import { MessageState } from '.';
import messageReducer from './reducer';

const initialState: MessageState = {
  text: null,
  type: undefined,
};

const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: { showMessage: messageReducer },
});

export default messageSlice;
