import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getDraft, getVisitorOrders } from 'core/requests';
import { loaderSlice, messageSlice } from 'core/store';
import { MerchantFooter, ScreenFooter } from 'shared/components';
import { HOME_ROUTE } from 'shared/constants';
import { useRoleNavigation } from 'shared/helpers';
import { CardLayout } from 'shared/layouts';
import { OrderDraft, OrderStatus, OrderHistoryItem } from 'shared/types';
import CardBody from './CardBody';
import Footer from './Footer';

const OrderHistory: FC = () => {
  const { t } = useTranslation();
  const { push } = useRoleNavigation();
  const [outstandingOrders, setOutsatandingOrders] = useState<
    OrderHistoryItem[]
  >([]);
  const [completedOrders, setCompletedOrders] = useState<OrderHistoryItem[]>(
    [],
  );
  const [orderDraft, setOrderDraft] = useState<OrderDraft | null>(null);
  const dispatch = useDispatch();

  const fetchOrders = async () => {
    try {
      const {
        data: { data },
      } = await getVisitorOrders();
      setOutsatandingOrders(
        data.items.filter(
          (order) =>
            ![OrderStatus.Retrieved, OrderStatus.Cancelled].includes(
              order.deliveryStatus,
            ),
        ),
      );
      setCompletedOrders(
        data.items.filter((order) =>
          [OrderStatus.Retrieved, OrderStatus.Cancelled].includes(
            order.deliveryStatus,
          ),
        ),
      );
    } catch (error) {
      dispatch(
        messageSlice.actions.showMessage({
          text: t('orderHistory.message.fetchOutstandingOrdersFail'),
          type: 'error',
        }),
      );
    }
  };

  const fetchOrderDraft = async () => {
    try {
      const response = await getDraft();
      setOrderDraft(response.data?.data);
    } catch (error) {
      if (error?.response?.status !== 404) {
        dispatch(
          messageSlice.actions.showMessage({
            text: t('message.draftError'),
            type: 'error',
          }),
        );
      }
    }
  };

  useEffect(() => {
    (async () => {
      dispatch(loaderSlice.actions.setLoaderState({ loading: true }));
      await Promise.allSettled([fetchOrders(), fetchOrderDraft()]);
      dispatch(loaderSlice.actions.setLoaderState({ loading: false }));
    })();
  }, []);

  const handleBackClick = () => {
    push(HOME_ROUTE);
  };

  return (
    <CardLayout
      title={t('orderHistory.title')}
      footer={<Footer />}
      onBackClick={handleBackClick}
      centered
      maxContentWidth={750}
    >
      <CardBody
        outstandingOrders={outstandingOrders}
        completedOrders={completedOrders}
        orderDraft={orderDraft}
      />
    </CardLayout>
  );
};

export default OrderHistory;
