// import { Link } from '@material-ui/core';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
// import { messageSlice } from 'core/store';
import { RootState, loaderSlice, messageSlice } from 'core/store';
import { getBoothMenuItemsForOverview } from 'core/requests';
// import { usePermissions } from 'features/Auth';
import { FlatLayout } from 'shared/layouts';
import {
  MerchantFooter,
  MerchantHeader,
  ScreenFooter,
} from 'shared/components';
// import { notAvailableYet } from 'shared/helpers';
import { Product } from 'shared/types';
import CardItem from './CardItem';
import useStyles from './useStyles';

const MerchantProductOverview: FC = () => {
  // const { isAllowedTo } = usePermissions();
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { goBack } = useHistory();
  const { currentUser } = useSelector((state: RootState) => ({
    currentUser: state.currentUser.data,
  }));
  const [boothMenuItems, setBoothMenuItems] = useState<Product[]>([]);
  const merchantBoothId = currentUser?.merchantBoothId;

  useEffect(() => {
    (async () => {
      if (merchantBoothId) {
        dispatch(loaderSlice.actions.setLoaderState({ loading: true }));
        try {
          const { data } = await getBoothMenuItemsForOverview(merchantBoothId);
          setBoothMenuItems(data.data.items);
        } catch {
          dispatch(
            messageSlice.actions.showMessage({
              text: t('message.boothMenuFetchError'),
              type: 'error',
            }),
          );
        } finally {
          dispatch(loaderSlice.actions.setLoaderState({ loading: false }));
        }
      }
    })();
  }, [merchantBoothId]);

  const renderItem = (item: Product) => (
    <CardItem
      key={item.uuid}
      product={item}
      merchantBoothId={merchantBoothId}
    />
  );

  /* const handleClickAdd = async () => {
    // TODO: handle adding product
    try {
      await notAvailableYet();
    } catch (error) {
      dispatch(
        messageSlice.actions.showMessage({
          text: error.message,
          type: 'error',
        }),
      );
    }
  }; */

  return (
    <FlatLayout
      header={
        <MerchantHeader heading={t('merchant.productOverview.heading')} />
      }
      footer={
        <ScreenFooter fixed>
          <MerchantFooter />
        </ScreenFooter>
      }
      onBackClick={goBack}
    >
      <div className={classes.container}>
        <div className={classes.cardItemsContainer}>
          {boothMenuItems.map(renderItem)}
        </div>
      </div>
      {/* TODO: uncomment when the backend will be ready */}
      {/* {isAllowedTo('boothType.products.add') && (
        <div className={classes.linkContainer}>
          <Link
            component="button"
            variant="body2"
            color="inherit"
            onClick={handleClickAdd}
          >
            {t('merchant.productOverview.addAProduct')}
          </Link>
        </div>
      )} */}
    </FlatLayout>
  );
};

export default MerchantProductOverview;
