import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    slide: {
      boxSizing: 'border-box',
      height: '100vh',
      padding: theme.spacing(0, 3, 0, 3),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#fff',
      backgroundColor: theme.customPalette.background,
      textAlign: 'center',
      overflow: 'hidden',
      '& img': {
        display: 'block',
        width: 100,
        height: 100,
        margin: theme.spacing(1, 0),
      },
      ['@media (min-height: 460px)']: {
        padding: theme.spacing(13, 3, 3, 3),
        '& img': {
          width: 125,
          height: 125,
          margin: theme.spacing(3, 0),
        },
      },
    },
    slideContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      maxWidth: 348,
      ['@media (min-height: 460px)']: {
        minHeight: 397,
      },
    },
    primaryHeading: {
      fontSize: '1.32em',
      fontWeight: 'bold',
    },
    secondaryHeading: {
      paddingBottom: theme.spacing(1.25),
      color: theme.customPalette.introAdditional,
      fontSize: '1.38em',
      fontWeight: 'bold',
    },
    description: {
      paddingBottom: '20px',
      color: theme.customPalette.introScreenBodyText,
      fontSize: '1em',
      lineHeight: '18px',
      ['@media (min-height: 460px)']: {
        height: 100,
      },
    },
    footer: {
      width: '100%',
      position: 'absolute',
      bottom: '4%',
      left: 0,
      display: 'flex',
      justifyContent: 'center',
      ['@media (min-height: 460px)']: {
        bottom: '8%',
      },
    },
    pagination: {
      flex: '1 0 auto',
      maxWidth: '90%',
      color: theme.customPalette.introScreenBodyText,
      '& ul': {
        margin: 0,
        display: 'flex',
        padding: 0,
        flexWrap: 'wrap',
        listStyle: 'none',
        alignItems: 'center',
        justifyContent: 'flex-end',
      },
      '& li': {
        listStyle: 'none',
        '& > span': {
          display: 'block',
          width: 20,
          height: 30,
          textAlign: 'center',
          lineHeight: '30px',
          fontSize: '0.8125em',
          '&.active': {
            fontWeight: 'bold',
            fontSize: '1em',
            color: theme.palette.common.white,
            lineHeight: '28px',
          },
        },
      },
    },
    arrowButton: {
      color: theme.palette.common.white,
    },
  }),
);

export default useStyles;
