import { useTheme } from '@material-ui/core/styles';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { putOrderAsHelpdesk } from 'core/requests';
import { loaderSlice, messageSlice, RootState } from 'core/store';
import {
  Button,
  ConfirmDialog,
  CreditCount,
  OrderSummary,
} from 'shared/components';
import { Order } from 'shared/types';
import useStyles from '../useStyles';
import CrewOrderSummary from '../CrewOrderSummary';

type Props = {
  order: Order;
  remainingCredits: number;
  fetchOrderDetails: () => Promise<void>;
  isCrew: boolean;
};

const Paid: FC<Props> = ({
  fetchOrderDetails,
  order,
  remainingCredits,
  isCrew,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles({});
  const dispatch = useDispatch();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const currency = useSelector(
    (state: RootState) => state.currentEvent.data?.currency || '',
  );

  const handleSendToHelpdesk = async () => {
    dispatch(loaderSlice.actions.setLoaderState({ loading: true }));
    try {
      await putOrderAsHelpdesk(order.id);
      fetchOrderDetails();
    } catch (error) {
      dispatch(
        messageSlice.actions.showMessage({
          text: error.message,
          type: 'error',
        }),
      );
    } finally {
      dispatch(loaderSlice.actions.setLoaderState({ loading: false }));
      setIsConfirmDialogOpen(false);
    }
  };

  const handleClickConfirmOk = () => {
    handleSendToHelpdesk();
  };

  const handleClickConfirmCancel = () => {
    setIsConfirmDialogOpen(false);
  };

  const handleClickSendToHelpdesk = () => {
    setIsConfirmDialogOpen(true);
  };

  if (isCrew) {
    return (
      <CrewOrderSummary
        textColor={theme.customPalette.textPrimary}
        items={order.products}
      />
    );
  }

  return (
    <div className={classes.paidContainer}>
      <OrderSummary
        backgroundColor={theme.customPalette.highlightGreen}
        textColor={theme.customPalette.textPrimary}
        items={order.products}
      />
      <Button
        text={t('scanOrderResult.sendToHelpdesk')}
        color="white"
        className={classes.helpdeskButton}
        onClick={handleClickSendToHelpdesk}
      />
      <CreditCount
        className={classes.remainingCredits}
        title={t('scanOrderResult.creditCountTitle')}
        currency={currency}
        amount={remainingCredits}
        coloredBackground
      />
      <ConfirmDialog
        open={isConfirmDialogOpen}
        title={t('scanOrderResult.confirmSendToHelpdeskTitle')}
        description={t('scanOrderResult.confirmSendToHelpdeskDescription')}
        okText={t('common.yes')}
        cancelText={t('common.no')}
        onCancel={handleClickConfirmCancel}
        onConfirm={handleClickConfirmOk}
      />
    </div>
  );
};

export default Paid;
