import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'center',
    },
    cardItemsContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      marginTop: '31px',
      width: '750px',
      maxWidth: '100%',
    },
    linkContainer: {
      padding: '0 18px 25px 18px',
      textAlign: 'center',
      color: theme.customPalette.background,
      '& .MuiLink-button': {
        fontWeight: 'bold',
      },
    },
  }),
);

export default useStyles;
