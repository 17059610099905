import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sectionTitleText: {
      display: 'block',
      fontSize: '21px',
      lineHeight: '28px',
      color: theme.customPalette.textPrimary,
      marginBottom: '16px',
      fontWeight: 800,
    },
    nameText: {
      color: theme.customPalette.blueText,
    },
    boothInfoContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: '20px',
    },
    avatarContainer: {
      height: '41px',
      width: '41px',
      borderRadius: '50%',
      backgroundColor: theme.customPalette.highlightGreen,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: '11px',
    },
    avatarIcon: {
      fontSize: '30px',
      color: theme.customPalette.backgroundGreen,
    },
    signOutButton: {
      width: '120px',
      marginBottom: '16px',
    },
    closeBoothText: {
      fontSize: '17px',
      color: theme.customPalette.textPrimary,
    },
    switchRoot: {
      width: 66,
      height: 24,
      padding: 0,
      margin: '0 16px',
      transform: 'rotate(180deg)',
    },
    switchBase: {
      padding: 3,
      fontSize: '19px',
      color: theme.palette.common.white,
      '&$checked': {
        opacity: 0.75,
        color: theme.palette.common.white,
        transform: 'rotate(180deg) translateX(-42px)',
        '& + $track': {
          backgroundColor: theme.palette.primary.light,
          opacity: 1,
          border: 'none',
        },
      },
    },
    thumb: {
      width: 20,
      height: 20,
    },
    track: {
      borderRadius: 12,
      backgroundColor: '#dcdcdc',
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    focusVisible: {},
    checked: {},
    card: {
      display: 'flex',
      flexDirection: 'column',
      padding: '21px 18px',
      marginBottom: '19px',
    },
    switchContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: '13px',
    },
    switchTextActive: {
      fontSize: '15px',
      fontWeight: 700,
      color: theme.customPalette.backgroundGreen,
      textTransform: 'uppercase',
    },
    switchTextInactive: {
      fontSize: '15px',
      fontWeight: 700,
      color: theme.customPalette.labelGray,
      textTransform: 'uppercase',
    },
    pictureRow: {
      marginBottom: '12px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      overflowX: 'scroll',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    pictureBox: {
      position: 'relative',
      border: '2px solid transparent',
      marginRight: '10px',
    },
    picture: {
      display: 'block',
      width: '60px',
      height: '60px',
      objectFit: 'cover',
      borderRadius: '12px',
      border: `1px solid ${theme.customPalette.accentText}`,
    },
    selected: {
      border: `2px solid ${theme.palette.primary.main}`,
      borderRadius: '14px',
    },
    addPicureIcon: {
      fontSize: '24px',
      color: theme.customPalette.blueText,
    },
    orderHoursDescriptionContainer: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: '26px',
    },
    orderHoursIcon: {
      fontSize: '14px',
      color: '#97aaba',
      marginRight: '4px',
    },
    orderHoursDescriptionText: {
      fontSize: '14px',
      fontStyle: 'italic',
      lineHeight: '18px',
      color: '#97aaba',
      margin: 0,
    },
    festivalInformationLabeledInfo: {
      marginTop: '6px',
    },
    categoryContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: '23px',
      marginBottom: '56px',
    },
    categoryIconContainer: {
      width: '63px',
      height: '64px',
      borderRadius: '50%',
      backgroundColor: theme.customPalette.highlightGreen,
      marginRight: '9px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    upload: {
      margin: '2px',
    },
    section: {
      marginTop: '16px',
      marginBottom: '39px',
    },
  }),
);

export default useStyles;
