import DateFnsUtils from '@date-io/date-fns';
import { Checkbox, Dialog, SvgIcon } from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import {
  format,
  addDays,
  setHours,
  setMinutes,
  getUnixTime,
  getHours,
  getMinutes,
} from 'date-fns';
import { ChangeEvent, FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ReactComponent as CalendarIcon } from 'assets/calendar.svg';
import { messageSlice } from 'core/store';
import { Button } from 'shared/components';
import { formatTextDate } from 'shared/helpers';
import DateTimeSelect from './DateTimeSelect';
import useStyles from './useStyles';

type Props = {
  active: boolean;
  open: boolean;
  openingDate: Date;
  closingDate?: Date;
  dayNumber: number;
  onSave: (active: boolean, openingDate: Date, closingDate: Date) => void;
  onClose: () => void;
};

const OrderHoursDialog: FC<Props> = ({
  active,
  open,
  openingDate,
  closingDate,
  dayNumber,
  onSave,
  onClose,
}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [newActive, setNewActive] = useState(active);
  const [newOpeningDate, setNewOpeningDate] = useState(openingDate);
  const [newClosingDate, setNewClosingDate] = useState(closingDate);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [isTimePickerOpen, setIsTimePickerOpen] = useState(false);
  const [timeDateType, setTimeDateType] =
    useState<'opening' | 'closing'>('opening');

  const handleTimePickerClose = () => {
    setIsTimePickerOpen(false);
  };
  const handleDatePickerClose = () => {
    setIsDatePickerOpen(false);
  };

  const handleTimeSelectClick = (dateType: 'opening' | 'closing') => () => {
    setTimeDateType(dateType);
    setIsTimePickerOpen(true);
  };

  const handleDateSelectClick = () => {
    setIsDatePickerOpen(true);
  };

  const handleSaveClick = () => {
    if (newClosingDate) {
      onSave(newActive, newOpeningDate, newClosingDate);
    }
  };

  const handleChangeActive = (event: ChangeEvent<HTMLInputElement>) => {
    setNewActive(event.target.checked);
  };

  const handleTimeChange = useCallback(
    (date: MaterialUiPickersDate) => {
      if (timeDateType === 'closing' && date) {
        const secondDifference =
          getUnixTime(date) - getUnixTime(newOpeningDate);
        const hourDifference = secondDifference / 3600;

        if (hourDifference < 0) {
          dispatch(
            messageSlice.actions.showMessage({
              text: t('message.cantCloseBeforeOpening'),
              type: 'error',
            }),
          );
          return;
        }
        if (hourDifference > 24) {
          dispatch(
            messageSlice.actions.showMessage({
              text: t('message.cantBeOpenForOver24'),
              type: 'error',
            }),
          );
          return;
        }

        setNewClosingDate(date);
      } else if (date) {
        setNewOpeningDate(date);
      }
    },
    [newOpeningDate, timeDateType],
  );

  const handleDateChange = useCallback(
    (date: MaterialUiPickersDate) => {
      if (date) {
        setNewClosingDate(
          setMinutes(
            setHours(date, getHours(newOpeningDate)),
            getMinutes(newOpeningDate),
          ),
        );
      }
    },
    [newOpeningDate],
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ className: classes.container }}
    >
      <span className={classes.dialogTitle}>
        {t('merchantProfile.orderHours')}
      </span>
      <span className={classes.dialogDescription}>
        {t('merchantProfile.orderHoursDialogDescription')}
      </span>
      <div className={classes.centeredRow}>
        <label>
          <Checkbox
            className={classes.checkbox}
            checked={newActive}
            color="primary"
            onChange={handleChangeActive}
          />
          <span className={classes.eventDayText}>
            {t('merchantProfile.eventDay', { number: dayNumber })}
          </span>
        </label>
      </div>
      <div className={classes.openingDateContainer}>
        <SvgIcon component={CalendarIcon} className={classes.openingDateText} />
        <span className={classes.openingDateText}>
          {formatTextDate(newOpeningDate, i18n.language)}
        </span>
      </div>
      <DateTimeSelect
        text={format(newOpeningDate, 'HH:mm')}
        onClick={handleTimeSelectClick('opening')}
      />
      <DateTimeSelect
        showDate
        onClick={handleDateSelectClick}
        text={
          newClosingDate ? formatTextDate(newClosingDate, i18n.language) : ''
        }
      />
      <DateTimeSelect
        onClick={handleTimeSelectClick('closing')}
        text={newClosingDate ? format(newClosingDate, 'HH:mm') : ''}
      />

      <Button
        text="Update hours"
        primary
        className={classes.button}
        onClick={handleSaveClick}
        disabled={!newClosingDate}
      />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <TimePicker
          className={classes.datePicker}
          ampm={false}
          open={isTimePickerOpen}
          margin="normal"
          id="time-picker-dialog"
          value={timeDateType === 'opening' ? newOpeningDate : newClosingDate}
          onChange={handleTimeChange}
          onClose={handleTimePickerClose}
        />
        <KeyboardDatePicker
          className={classes.datePicker}
          open={isDatePickerOpen}
          margin="normal"
          id="date-picker-dialog"
          minDate={openingDate}
          maxDate={addDays(openingDate, 1)}
          value={closingDate}
          onChange={handleDateChange}
          onClose={handleDatePickerClose}
        />
      </MuiPickersUtilsProvider>
    </Dialog>
  );
};

export default OrderHoursDialog;
