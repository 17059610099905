import { FC } from 'react';
import { SvgIcon } from '@material-ui/core';
import { AccessTime } from '@material-ui/icons';
import { ReactComponent as CalendarIcon } from 'assets/calendar.svg';
import useStyles from './useStyles';

type Props = {
  date: string;
  time: string;
};

const DateTimeRow: FC<Props> = ({ date, time }) => {
  const classes = useStyles();

  return (
    <div className={classes.orderCardRow}>
      <div className={classes.centeredRow}>
        <SvgIcon component={CalendarIcon} className={classes.dateText} />
        <span className={classes.dateText}>{date}</span>
      </div>
      <div className={classes.centeredRow}>
        <AccessTime className={classes.dateText} />
        <span className={classes.dateText}>{time}</span>
      </div>
    </div>
  );
};

export default DateTimeRow;
