import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { MAX_INTERACTIVE_ELEMENT_WIDTH } from 'shared/constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subcontainer: {
      boxSizing: 'border-box',
      width: '100%',
      maxWidth: MAX_INTERACTIVE_ELEMENT_WIDTH + 40,
      minHeight: 324,
      margin: '0 auto',
      padding: '30px 20px 20px 20px',
      [theme.breakpoints.up('sm')]: {
        maxWidth: 500,
      },
    },
    footerContainer: {
      padding: '0 20px 10px 20px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& > *': {
        marginBottom: 20,
      },
    },
    message: {
      marginBottom: 16,
      paddingTop: 26,
      color: '#4f329a',
    },
    inlineContent: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '& > *:not(:last-child)': {
        marginRight: '8px',
      },
    },
    qrCodeContainer: {
      boxSizing: 'border-box',
      width: '86%',
      maxWidth: 'calc(100vh - 60px)',
      margin: '24px auto 10px auto',
      padding: '30px',
      borderRadius: '8px',
      backgroundColor: theme.palette.common.white,
      boxShadow: '0 0 21px -2px rgba(0,0,0,0.2)',
    },
    qrCode: {
      width: '100%',
      height: 'auto',
    },
  }),
);

export default useStyles;
