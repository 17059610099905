import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import clsx from 'clsx';

const useStyles = makeStyles(() =>
  createStyles({
    multipleLocationsWarningText: {
      fontSize: '13px',
      color: '#ff9400',
      display: 'flex',
      alignItems: 'center',
      marginBottom: '18px',
    },
    infoIcon: {
      fontSize: '13px',
      marginRight: '6px',
      paddingBottom: '3px',
    },
  }),
);

type Props = {
  className?: string;
};

const MultipleLocations: FC<Props> = ({ className }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <span className={clsx(classes.multipleLocationsWarningText, className)}>
      <Info className={classes.infoIcon} />
      {t('checkout.multipleLocations')}
    </span>
  );
};

export default MultipleLocations;
