import { RootState } from 'core/store';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Link, LinkProps } from 'react-router-dom';

const RoleLink: FC<LinkProps> = ({ to, ...props }) => {
  const { data } = useSelector((state: RootState) => state.currentUser);

  return (
    <Link
      to={data?.role.includes('ROLE_MERCHANT') ? `/merchant${to}` : to}
      {...props}
    />
  );
};

export default RoleLink;
