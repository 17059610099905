import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tagsContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      margin: '0px 20px 7px 20px',
    },
    tag: {
      height: '21px',
      borderRadius: '10.5px',
      backgroundColor: '#e9eef5',
      color: theme.customPalette.background,
      fontSize: '15px',
      fontWeight: 600,
      marginRight: '9px',
      padding: '0 8px',
    },
    photosContainer: {
      minHeight: '144px',
      display: 'flex',
      flexDirection: 'row',
      overflowX: 'scroll',
      padding: '30px 20px',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    photo: {
      width: 'auto',
      height: '174px',
      aspectRatio: 'auto',
      marginRight: '27px',
      borderRadius: '20px',
      boxShadow: '0 0 7px 4px rgba(0,0,0,0.3)',
    },
    sectionTitle: {
      fontSize: '15px',
      fontWeight: 600,
      color: theme.customPalette.textPrimary,
      marginLeft: '20px',
    },
    aboutText: {
      fontSize: '15px',
      color: theme.customPalette.labelGray,
      margin: '15px 24px 30px 24px',
      lineHeight: '26px',
    },
    locationContainer: {
      margin: '6px 20px 28px 20px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    locationIcon: {
      fontSize: '14px',
      color: theme.customPalette.textPrimary,
      marginRight: '2px',
    },
    locationText: {
      fontSize: '14px',
      fontWeight: 600,
      color: theme.customPalette.labelGray,
    },
    closingTimeContainer: {
      height: '54px',
      margin: '28px 21px 34px 21px',
      backgroundColor: '#fff3cd',
      border: '1px solid #f2c769',
      display: 'flex',
      alignItems: 'center',
    },
    closingTimeText: {
      fontSize: '14px',
      color: '#0c0c0b',
    },
    closingTimeIcon: {
      fontSize: '19px',
      color: '#7f7538',
      marginRight: '8px',
      marginLeft: '15px',
    },
    menuContainer: {
      borderTopLeftRadius: '26px',
      borderTopRightRadius: '26px',
      backgroundColor: theme.customPalette.lightGrayBackground,
      paddingTop: '31px',
    },
    menuTitle: {
      fontSize: '21px',
      lineHeight: '28px',
      color: theme.customPalette.textPrimary,
      fontWeight: 900,
      marginLeft: '25px',
    },
  }),
);

export default useStyles;
