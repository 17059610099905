import { SvgIcon } from '@material-ui/core';
import { FC } from 'react';
import { ReactComponent as UserIcon } from 'assets/user.svg';
import useStyles from './useStyles';
import clsx from 'clsx';

const TripleCircle: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.tripleCircleContainer}>
      <div
        className={clsx(
          classes.userIconContainer,
          classes.userIconContainerSmall,
        )}
      />
      <div
        className={clsx(
          classes.userIconContainer,
          classes.userIconContainerSmall,
        )}
      />
      <div
        className={clsx(
          classes.userIconContainer,
          classes.userIconContainerSmall,
        )}
      >
        <SvgIcon component={UserIcon} className={classes.userIcon} />
      </div>
    </div>
  );
};

export default TripleCircle;
