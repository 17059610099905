import { FunctionComponent } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Amount as AmountType } from 'shared/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    amountContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    currencyText: {
      fontSize: '14px',
      lineHeight: '19px',
      color: theme.customPalette.accentText,
      marginRight: '4px',
      marginBottom: '8px',
    },
    amountText: {
      fontSize: '20px',
      fontWeight: 700,
      color: theme.customPalette.textPrimary,
      marginRight: '4px',
    },
    amountDecoration: {
      height: '36px',
      width: '2px',
      backgroundColor: '#00ff98',
    },
  }),
);

type Props = {
  amount: AmountType;
};

const Amount: FunctionComponent<Props> = ({ amount }) => {
  const classes = useStyles();

  return (
    <div className={classes.amountContainer}>
      <span className={classes.currencyText}>{amount?.currency}</span>
      <span className={classes.amountText}>{amount?.value.toFixed(2)}</span>
      <div className={classes.amountDecoration} />
    </div>
  );
};

export default Amount;
