import { createSlice } from '@reduxjs/toolkit';
import { CategoriesState } from '.';
import reducers from './reducers';

const initialState: CategoriesState = {
  loading: false,
  data: [],
};

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers,
});

export default categoriesSlice;
