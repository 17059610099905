import { FC, useLayoutEffect, useState } from 'react';
import { getImageOrFallback, getImageUrl } from 'shared/helpers';
import useStyles from './useStyles';

const BOOTH_TYPE_IMAGES = '/images/boothType';

type Props = {
  url: string;
};

const Photo: FC<Props> = ({ url }) => {
  const { photo } = useStyles();
  const [src, setSrc] = useState(() =>
    getImageUrl(`${BOOTH_TYPE_IMAGES}/booth-type-dummy.png`),
  );

  useLayoutEffect(() => {
    (async () => {
      const result = await getImageOrFallback(
        `${BOOTH_TYPE_IMAGES}${src}`,
        `${BOOTH_TYPE_IMAGES}/booth-type-dummy.png`,
      );
      setSrc(result as string);
    })();
  }, [url]);

  return <img className={photo} src={src} width="203px" height="174px" />;
};

export default Photo;
