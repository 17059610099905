import { FC } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    footerContainer: {
      padding: '20px 20px 0 20px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& > *': {
        marginBottom: 20,
      },
    },
  }),
);

const FooterButtonsContainer: FC = (props) => {
  const classes = useStyles();

  return <div className={classes.footerContainer} {...props} />;
};

export default FooterButtonsContainer;
