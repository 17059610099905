import { PayloadAction } from '@reduxjs/toolkit';
import { MerchantBoothsResponse } from 'shared/types';
import { MerchantBoothsState } from './index';

const reducers = {
  getMerchantBoothsRequest: (state: MerchantBoothsState) => ({
    ...state,
    loading: true,
  }),
  getMerchantBoothsSuccess: (
    state: MerchantBoothsState,
    { payload }: PayloadAction<MerchantBoothsResponse>,
  ) => ({ ...state, data: payload, loading: false }),
  getMerchantBoothsFailure: (state: MerchantBoothsState) => ({
    ...state,
    loading: false,
  }),
  resetMerchantBoothsState: () => ({
    data: undefined,
    loading: false,
  }),
};

export default reducers;
