import {
  createStyles,
  IconButton,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import { format, parseISO } from 'date-fns';
import { useState, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Chip, OrderSummary, RoleLink } from 'shared/components';
import { ORDER_DETAILS_ROUTE } from 'shared/constants';
import {
  getOrderStatusExtended,
  getValueWithCurrencyString,
} from 'shared/helpers';
import {
  Amount,
  OrderItem,
  OrderStatus,
  OrderStatusExtended,
  PaymentStatus,
} from 'shared/types';

const statusChipBackgroundColors: { [key: string]: string } = {
  [OrderStatusExtended.Draft]: '#ffdaadff', // '#ffe2e2'
  [OrderStatusExtended.Pending]: '#ffdaadff',
  [OrderStatusExtended.Paid]: '#daefc1',
  [OrderStatusExtended.Cancelled]: '#ffe2e2',
  [OrderStatusExtended.Helpdesk]: '#cde5f9',
};

const statusChipTextColors: { [key: string]: string } = {
  [OrderStatusExtended.Draft]: '#ff9400', // '#ff5454'
  [OrderStatusExtended.Pending]: '#ff9400',
  [OrderStatusExtended.Paid]: '#32bf76',
  [OrderStatusExtended.Cancelled]: '#ff4949',
  [OrderStatusExtended.Helpdesk]: '#0459c1',
};

const useStyles = makeStyles<
  Theme,
  {
    variant: 'visitor' | 'merchant' | undefined;
    orderStatusExtended: OrderStatusExtended;
    collapsed: boolean | undefined;
  }
>((theme: Theme) =>
  createStyles({
    root: {
      boxSizing: 'border-box',
      paddingBottom: 23,
      backgroundColor: ({ orderStatusExtended, variant }) =>
        orderStatusExtended === OrderStatusExtended.Helpdesk &&
        variant === 'visitor'
          ? '#e9eef5'
          : 'white',
    },
    header: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '23px 20px 0 20px',
      fontSize: '17px',
      fontWeight: 600,
      color: theme.customPalette.textPrimary,
    },
    rightSlot: {
      paddingRight: 22,
      display: 'flex',
    },
    cardSubcontainer: {
      minHeight: 30,
      alignItems: 'center',
      paddingBottom: 3,
      display: 'flex',
    },
    arrowButton: {
      position: 'absolute',
      top: 10,
      right: 0,
    },
    body: {
      padding: '0px 20px 0 20px',
      color: theme.customPalette.accentText,
      fontSize: '14px',
      lineHeight: '14px',
      '& > div:first-child': {
        paddingBottom: ({ variant }) => (variant === 'visitor' ? '7px' : 0),
      },
    },
    boothChip: {
      verticalAlign: 'baseline',
      marginRight: 12,
      marginBottom: 2,
    },
    statusChip: ({ orderStatusExtended }) =>
      statusChipBackgroundColors[orderStatusExtended]
        ? {
            backgroundColor: statusChipBackgroundColors[orderStatusExtended],
            color: statusChipTextColors[orderStatusExtended],
          }
        : {},
    footer: {
      position: 'relative',
      bottom: '-8px',
      textAlign: 'right',
      '& a': {
        color: theme.palette.primary.main,
      },
    },
  }),
);

type Props = {
  orderStatus?: OrderStatus;
  paymentStatus: PaymentStatus;
  isDraft?: boolean;
  orderNumber?: number;
  orderDate?: string;
  orderBoothTypes: number[];
  orderItems: OrderItem[];
  orderTotalAmount: Amount;
  initiallyCollapsed?: boolean;
  variant?: 'visitor' | 'merchant';
  showStatus?: boolean;
  showSummary?: boolean;
  alwaysExpanded?: boolean;
  noAvailability?: boolean;
};

const OrderCard: FC<Props> = ({
  orderStatus,
  paymentStatus,
  isDraft,
  orderNumber,
  orderDate,
  orderBoothTypes,
  orderItems,
  orderTotalAmount,
  initiallyCollapsed,
  showStatus,
  showSummary,
  variant,
  alwaysExpanded,
  noAvailability,
}) => {
  const [collapsed, setCollapsed] = useState(initiallyCollapsed);
  const orderStatusExtended = getOrderStatusExtended(
    orderStatus,
    paymentStatus,
    isDraft,
  );
  const classes = useStyles({ variant, orderStatusExtended, collapsed });
  const { t } = useTranslation();
  const theme = useTheme();
  const isPendingNotPaid =
    orderStatusExtended === OrderStatusExtended.Pending &&
    paymentStatus !== PaymentStatus.Paid;

  const renderBoothType = (boothType: number) => (
    <Chip
      key={boothType}
      className={classes.boothChip}
      label={t('booth.boothNumberTextWithColon', {
        number: boothType,
      })}
    />
  );

  const isStatusChipVisible =
    showStatus ||
    (variant === 'visitor' &&
      [OrderStatusExtended.Helpdesk, OrderStatusExtended.Draft].includes(
        orderStatusExtended,
      ));

  const isBoothChipVisible =
    (variant === 'visitor' &&
      (
        [
          OrderStatusExtended.Cancelled,
          OrderStatusExtended.Retrieved,
        ] as Array<OrderStatusExtended>
      ).includes(orderStatusExtended)) ||
    variant === 'merchant' ||
    isDraft ||
    isPendingNotPaid;

  return (
    <Card className={classes.root}>
      <div className={classes.header}>
        <div className={classes.leftSlot}>
          {t('orderHistory.labeledOrderNumber', {
            number: isDraft
              ? t('orderDetails.draftNumberReplacement')
              : orderNumber,
          })}
        </div>
        <div className={classes.rightSlot}>
          {isStatusChipVisible && (
            <Chip
              className={classes.statusChip}
              label={t(
                `order.status.${
                  isDraft ? OrderStatusExtended.Pending : orderStatusExtended
                }`,
              )}
            />
          )}
          {!alwaysExpanded && (
            <RoleLink
              to={`${ORDER_DETAILS_ROUTE}/${isDraft ? 'Draft' : orderNumber}`}
            >
              <IconButton
                className={classes.arrowButton}
                aria-label="order details"
                size="medium"
              >
                <ChevronRight fontSize="inherit" />
              </IconButton>
            </RoleLink>
          )}
        </div>
      </div>
      {!collapsed && (
        <div className={classes.body}>
          <div className={classes.cardSubcontainer}>
            {!isBoothChipVisible && orderBoothTypes.map(renderBoothType)}
            {orderDate && (
              <span>{format(parseISO(orderDate), 'dd/MM/yy - HH:mm')}</span>
            )}
          </div>
          {showSummary && (
            <OrderSummary
              backgroundColor="transparent"
              textColor={theme.customPalette.textPrimary}
              items={orderItems}
              noCard
              noAvailability={noAvailability}
            />
          )}
          <div>
            {t('orderHistory.totalAmount', {
              amount: orderTotalAmount
                ? getValueWithCurrencyString(orderTotalAmount)
                : '',
            })}
          </div>
          {!alwaysExpanded && <div className={classes.footer} />}
        </div>
      )}
    </Card>
  );
};

export default OrderCard;
