import { format } from 'date-fns';
import { enGB, nlBE, fr, de } from 'date-fns/locale';

const languageCodeMap: { [key: string]: Locale } = {
  en: enGB,
  nl: nlBE,
  fr,
  de,
};

const formatTextDate = (date: Date, locale: string) =>
  format(date, 'EEEE, MMMM dd', {
    locale: languageCodeMap[locale],
  });

export default formatTextDate;
