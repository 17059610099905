import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    centeredRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    orderCardRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '9px',
    },
    dateText: {
      fontSize: '13px',
      color: '#5e7b85',
      marginRight: '4px',
    },
  }),
);

export default useStyles;
