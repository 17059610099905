import clsx from 'clsx';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { LocationOn } from '@material-ui/icons';
import { Order } from 'shared/types';
import { parseISO } from 'date-fns';
import OrderDetails from './OrderDetails';
import useStyles from '../useStyles';

type Props = {
  order: Order;
  isCrew: boolean;
};

const WrongBooth: FC<Props> = ({ order, isCrew }) => {
  const { t } = useTranslation();
  const classes = useStyles({ isCrew });

  return (
    <div
      className={clsx(
        classes.wrongBoothContainer,
        isCrew && classes.crewContainer,
      )}
    >
      {isCrew && <h3>{t('scanOrderResult.wrongBooth')}</h3>}
      <OrderDetails
        className={classes.orderDetails}
        boothType={order.booth.type}
        boothLocation={order.booth.zone}
        date={parseISO(order.date)}
        isCrew={isCrew}
      />
      <div className={classes.secondaryContainer}>
        <LocationOn className={classes.wrongBoothIcon} />
        {!isCrew && (
          <span className={classes.helpdeskText}>
            {t('scanOrderResult.wrongBooth')}
          </span>
        )}
      </div>
    </div>
  );
};

export default WrongBooth;
