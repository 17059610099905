import { push } from 'connected-react-router';
import { call, put, takeEvery } from 'redux-saga/effects';
import { getCurrentEvent } from 'core/requests';
import { EVENT_SELECTION_ROUTE } from 'shared/constants';
import { authToken } from 'shared/helpers';
import { PromiseResult } from 'shared/types';
import i18next from 'i18next';
import { currentEventSlice, currentUserSlice } from 'core/store';
import { messageSlice } from '../message';

function* getCurrentEventSaga() {
  try {
    const userRoles = authToken.getUserRoles();
    const isMerchant = userRoles && userRoles.includes('ROLE_MERCHANT');
    const {
      data: { data },
    }: PromiseResult<ReturnType<typeof getCurrentEvent>> = yield call(
      getCurrentEvent,
    );
    yield put(currentEventSlice.actions.getCurrentEventSuccess(data));
    if (data && data.active) {
      yield put(currentUserSlice.actions.getCurrentUserRequest());
    } else if (isMerchant) {
      yield put(push(`/merchant${EVENT_SELECTION_ROUTE}`));
    } else {
      yield put(push(EVENT_SELECTION_ROUTE));
    }
  } catch {
    yield put(currentEventSlice.actions.getCurrentEventFailure());
    yield put(
      messageSlice.actions.showMessage({
        text: i18next.t('event.message.fetchCurrentEventFail'),
        type: 'warning',
      }),
    );
  }
}

export function* getCurrentEventSagaWatcher() {
  yield takeEvery(
    currentEventSlice.actions.getCurrentEventRequest,
    getCurrentEventSaga,
  );
}
