import clsx from 'clsx';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { OrderItem } from 'shared/types';
import useStyles from './useStyles';

type Props = {
  textColor: React.CSSProperties['color'];
  item: OrderItem;
};

const CrewOrderSummaryItem: FC<Props> = ({ item, textColor }) => {
  const classes = useStyles({ textColor });
  const { t } = useTranslation();

  return (
    <div key={item.uuid} className={classes.itemContainer}>
      <div
        className={clsx(
          classes.orderSummaryItem,
          !item.available && classes.notAvailable,
        )}
      >
        <span className={classes.orderSummaryQuantityText}>
          {item.quantity} x
        </span>
        <span>{item.name}</span>
        {!item.available && (
          <span className={classes.soldOut}>{t('order.summary.soldOut')}</span>
        )}
      </div>
    </div>
  );
};

export default CrewOrderSummaryItem;
