import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      fontSize: '14px',
      lineHeight: '17px',
      color: theme.customPalette.textPrimary,
    },
    collapsedAboutText: {
      fontSize: '14px',
      lineHeight: '17px',
      color: theme.customPalette.textPrimary,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
    },
    languageText: {
      fontSize: '17px',
      fontWeight: 600,
      color: theme.customPalette.labelGray,
      textTransform: 'uppercase',
      marginRight: '9px',
    },
    editIcon: {
      fontSize: '17px',
      color: theme.customPalette.labelGray,
    },
    collapseIcon: {
      fontSize: '32px',
      color: theme.customPalette.textPrimary,
    },
    controlsRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    centeredRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    editableCard: {
      borderRadius: '22px',
      padding: '14px 20px 23px 20px',
      marginBottom: '18px',
    },
    textInput: {
      fontSize: '14px',
      lineHeight: '17px',
      color: theme.customPalette.textPrimary,
      padding: 0,
      border: 'none',
      width: '100%',
      fontFamily: 'Nunito Sans, sans-serif',
      marginBottom: '14px',
      marginTop: '14px',
      '&:focus': {
        outline: 'none',
      },
    },
  }),
);

export default useStyles;
