import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  currentUserSlice,
  loaderSlice,
  messageSlice,
  orderedProductsSlice,
  RootState,
} from 'core/store';
import {
  createOrder,
  createCrewOrder,
  createMerchantOrder,
  retryOrderPayment,
  topUpCredits,
} from 'core/requests';
import { Button, ConfirmDialog } from 'shared/components';
import {
  PAYMENT_REDIRECT_ROUTE,
  QR_SCAN_ROUTE,
  TOP_UP_PAYMENT_REDIRECT_ROUTE,
} from 'shared/constants';
import { getValueWithCurrencyString, useRoleNavigation } from 'shared/helpers';
import { OrderedProduct, PaymentMethodType } from 'shared/types';
import useStyles from './useStyles';

export type PaymentData = {
  total: number;
  defaultCurrencyTotal: number;
  products: { [key: string]: OrderedProduct };
};

type Props = {
  isCrew?: boolean;
  isMerchant: boolean;
  orderId: string | null;
  orderUuid: string | null;
  draftUuid: string | null;
  paymentMethodId?: PaymentMethodType;
  paymentData?: PaymentData;
  topUpAmount?: number;
  source?: 'draft';
};

const Footer: FC<Props> = ({
  isCrew,
  isMerchant,
  orderId,
  orderUuid,
  draftUuid,
  paymentMethodId,
  paymentData,
  topUpAmount,
  source,
}) => {
  const classes = useStyles({ isCrew });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { goBack, push } = useRoleNavigation();
  const [confirmContinueVisible, setConfirmContinueVisible] = useState(false);
  const orderedProducts = useSelector(
    (state: RootState) => state.orderedProducts,
  );
  const products = Object.values(orderedProducts.products).map((item) => ({
    uuid: item.uuid,
    quantity: item.quantity,
  }));

  const handleCreateOrder = async () => {
    if (paymentMethodId) {
      dispatch(loaderSlice.actions.setLoaderState({ loading: true }));
      if (paymentMethodId === PaymentMethodType.Credits) {
        try {
          await createOrder({
            paymentMethodId,
            products,
          });
          dispatch(orderedProductsSlice.actions.clearOrderedProducts());
          dispatch(currentUserSlice.actions.getOnlyCurrentUserRequest());
          setConfirmContinueVisible(false);
          push(PAYMENT_REDIRECT_ROUTE);
        } catch (error) {
          let message: string;
          const notAvailableProductName = error?.response?.data?.message;
          switch (error?.response?.status) {
            case 409:
              message = t('order.message.notEnoughCredits');
              dispatch(orderedProductsSlice.actions.clearOrderedProducts());
              break;
            case 503:
              message = t('order.message.paymentFail');
              dispatch(orderedProductsSlice.actions.clearOrderedProducts());
              break;
            default:
              message = `${notAvailableProductName} ${t(
                'order.message.notAvailableProduct',
              )}`;
          }
          dispatch(
            messageSlice.actions.showMessage({
              text: message,
              type: 'error',
            }),
          );
        }
      } else {
        try {
          const {
            data: { data },
          } = await createOrder({
            paymentMethodId,
            redirectUrl: `${window.location.origin}${PAYMENT_REDIRECT_ROUTE}`,
            products,
          });
          dispatch(orderedProductsSlice.actions.clearOrderedProducts());
          setConfirmContinueVisible(false);
          window.location.href = data.checkoutUrl;
        } catch {
          dispatch(
            messageSlice.actions.showMessage({
              text: t('message.paymentRedirectError'),
              type: 'error',
            }),
          );
        }
      }
      dispatch(loaderSlice.actions.setLoaderState({ loading: false }));
    }
  };

  const handleRetryOrderPayment = async () => {
    if (paymentMethodId) {
      dispatch(loaderSlice.actions.setLoaderState({ loading: true }));
      try {
        let redirectUrl = draftUuid
          ? `${window.location.origin}${PAYMENT_REDIRECT_ROUTE}`
          : `${window.location.origin}${PAYMENT_REDIRECT_ROUTE}?orderId=${orderUuid}`;
        if (isMerchant) {
          redirectUrl = `${window.location.origin}/merchant${PAYMENT_REDIRECT_ROUTE}?orderId=${orderUuid}`;
        }
        if (isCrew) {
          redirectUrl = `${window.location.origin}/crew${PAYMENT_REDIRECT_ROUTE}?orderId=${orderUuid}`;
        }
        const {
          data: { data },
        } = await retryOrderPayment({
          orderUuid: draftUuid || orderUuid!,
          paymentMethodId,
          redirectUrl,
        });
        dispatch(orderedProductsSlice.actions.clearOrderedProducts());
        setConfirmContinueVisible(false);
        window.location.href = data.checkoutUrl;
      } catch {
        dispatch(
          messageSlice.actions.showMessage({
            text: t('message.paymentRedirectError'),
            type: 'error',
          }),
        );
      }
      dispatch(loaderSlice.actions.setLoaderState({ loading: false }));
    }
  };

  const handleCreateMerchantOrder = async () => {
    if (paymentMethodId) {
      dispatch(loaderSlice.actions.setLoaderState({ loading: true }));
      try {
        const checkoutItems = Object.values(orderedProducts.products).map(
          (item) => ({
            uuid: item.uuid,
            quantity: item.quantity,
          }),
        );
        let redirectUrl = `${window.location.origin}/merchant${PAYMENT_REDIRECT_ROUTE}`;
        if (isCrew) {
          redirectUrl = `${window.location.origin}/crew${PAYMENT_REDIRECT_ROUTE}`;
          const {
            data: { data },
          } = await createCrewOrder({
            paymentMethodId,
            redirectUrl,
            products: checkoutItems,
          });
          window.location.href = data.checkoutUrl;
        } else {
          const {
            data: { data },
          } = await createMerchantOrder({
            paymentMethodId,
            redirectUrl,
            products: checkoutItems,
          });
          window.location.href = data.checkoutUrl;
        }
        dispatch(orderedProductsSlice.actions.clearOrderedProducts());
      } catch {
        dispatch(
          messageSlice.actions.showMessage({
            text: t('message.paymentRedirectError'),
            type: 'error',
          }),
        );
      }
      dispatch(loaderSlice.actions.setLoaderState({ loading: false }));
    }
  };

  const handleTopUpCredits = async () => {
    if (paymentMethodId && topUpAmount) {
      dispatch(loaderSlice.actions.setLoaderState({ loading: true }));
      try {
        const {
          data: { data },
        } = await topUpCredits({
          paymentMethodId,
          redirectUrl: `${window.location.origin}${TOP_UP_PAYMENT_REDIRECT_ROUTE}`, // paymentUuid query variable is added on the backend
          credits: topUpAmount,
        });
        window.location.href = data.checkoutUrl;
      } catch {
        dispatch(
          messageSlice.actions.showMessage({
            text: t('message.paymentRedirectError'),
            type: 'error',
          }),
        );
      }
      dispatch(loaderSlice.actions.setLoaderState({ loading: false }));
    }
  };

  const merchantQrScanRoute =
    orderId && orderUuid
      ? `${QR_SCAN_ROUTE}?orderUuid=${orderUuid}`
      : QR_SCAN_ROUTE;

  const handleClickContinue = () => {
    if (!isMerchant && !isCrew && !topUpAmount) {
      setConfirmContinueVisible(true);

      return;
    }

    if (!isMerchant && !isCrew && topUpAmount) {
      handleTopUpCredits();

      return;
    }

    if (paymentMethodId === PaymentMethodType.Credits) {
      push(merchantQrScanRoute);
    } else if (orderId && orderUuid) {
      handleRetryOrderPayment();
    } else {
      handleCreateMerchantOrder();
    }
  };

  const handleConfirmContinueClick = () => {
    if (orderUuid || draftUuid) {
      handleRetryOrderPayment();
    } else {
      handleCreateOrder();
    }
  };

  const handleCancelContinueClick = () => {
    setConfirmContinueVisible(false);
  };

  const handleClickCancel = () => {
    goBack();
  };

  const getAmount = () => {
    return {
      currency: Object.values(paymentData?.products ?? {})[0]?.amount.currency,
      value: paymentData?.total ?? 0,
    };
  };

  const getDefaultCurrencyAmount = () => {
    return {
      currency: Object.values(paymentData?.products ?? {})[0]
        ?.defaultCurrencyAmount.currency,
      value: paymentData?.defaultCurrencyTotal ?? 0,
    };
  };

  return (
    <div className={classes.footer}>
      <Button
        primary
        text={t('paymentSelection.continuePayment')}
        onClick={handleClickContinue}
        disabled={!paymentMethodId}
      />
      <Button
        text={t('paymentSelection.cancel')}
        onClick={handleClickCancel}
        rounded={Boolean(isCrew)}
        className={isCrew ? classes.crewCancelButton : undefined}
      />
      <ConfirmDialog
        open={confirmContinueVisible}
        title={t('paymentSelection.confirm.title')}
        description={
          <>
            {t('paymentSelection.confirm.description')}
            <br />
            <strong className={classes.amount}>
              {getValueWithCurrencyString(
                paymentMethodId === PaymentMethodType.Credits
                  ? getAmount()
                  : getDefaultCurrencyAmount(),
              )}
            </strong>
          </>
        }
        okText={t('common.yes')}
        cancelText={t('common.no')}
        onCancel={handleCancelContinueClick}
        onConfirm={handleConfirmContinueClick}
      />
    </div>
  );
};

export default Footer;
