import { createStyles, Link, makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { ExpandMore } from '@material-ui/icons';
import clsx from 'clsx';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles<Theme, { expanded: boolean | undefined }>(
  (theme: Theme) =>
    createStyles({
      root: {
        display: 'inline-flex',
        alignItems: 'center',
        fontSize: '0.75rem',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
        whiteSpace: 'nowrap',
        '& > span': {
          marginRight: 4,
        },
      },
      arrowIcon: {
        transform: ({ expanded }) => (expanded ? 'rotate(180deg)' : 'none'),
        transition: 'transform .2s',
      },
    }),
);

type Props = {
  className?: string;
  expanded?: boolean;
  onClick?: () => void;
};

const SeeAllButton: FC<Props> = ({ className, expanded, onClick }) => {
  const classes = useStyles({ expanded });
  const { t } = useTranslation();

  return (
    <Link
      component="button"
      className={clsx(classes.root, className)}
      onClick={onClick}
    >
      <span>
        {t(expanded ? 'orderHistory.seeLess' : 'orderHistory.seeAll')}
      </span>
      <ExpandMore className={classes.arrowIcon} fontSize="small" />
    </Link>
  );
};

export default SeeAllButton;
