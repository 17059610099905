import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles<Theme, { large?: boolean }>((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      width: ({ large }) => (large ? undefined : '100%'),
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    line: {
      height: '1px',
      backgroundColor: ({ large }) =>
        large ? theme.customPalette.blueGray : theme.palette.secondary.main,
      width: ({ large }) => (large ? '30px' : '100%'),
    },
    text: {
      fontSize: ({ large }) => (large ? '21px' : '14px'),
      color: ({ large }) =>
        large ? theme.customPalette.blueGray : theme.palette.secondary.main,
      padding: ({ large }) => (large ? '14px' : '9px'),
      fontWeight: ({ large }) => (large ? 900 : 600),
      textTransform: ({ large }) => (large ? 'uppercase' : undefined),
    },
  }),
);

type Props = {
  large?: boolean;
};

const OrDivider: FC<Props> = ({ large }) => {
  const classes = useStyles({ large });
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <div className={classes.line} />
      <span className={classes.text}>{t('common.or')}</span>
      <div className={classes.line} />
    </div>
  );
};

export default OrDivider;
