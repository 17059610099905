import {
  createStyles,
  Dialog,
  Fab,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'shared/components';
import { TOP_UP_WALLET_ROUTE } from 'shared/constants';
import { useRoleNavigation } from 'shared/helpers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      borderRadius: '25px',
      padding: '37px 22px 24px 22px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    dialogTitle: {
      width: '240px',
      fontSize: '21px',
      fontWeight: 800,
      lineHeight: '28px',
      color: theme.customPalette.textPrimary,
      marginBottom: '12px',
      textAlign: 'center',
    },
    dialogDescription: {
      width: '240px',
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '19px',
      color: theme.customPalette.accentText,
      marginBottom: '20px',
      textAlign: 'center',
    },
    button: {
      maxWidth: '240px',
      marginTop: '20px',
    },
    closeButton: {
      position: 'fixed',
      top: 9,
      right: 15,
      height: '40px',
      width: '40px',
    },
    closeIcon: {
      fontSize: '24px',
      color: 'white',
    },
  }),
);

type Props = {
  close: () => void;
  open: boolean;
};

const TopUpReminderDialog: FC<Props> = ({ close, open }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { push } = useRoleNavigation();

  const handleClickButton = () => {
    push(TOP_UP_WALLET_ROUTE);
  };

  return (
    <Dialog
      open={open}
      PaperProps={{ className: classes.container }}
      maxWidth="xs"
    >
      <div className={classes.dialogTitle}>
        {t('topUp.reminderDialog.title')}
      </div>
      <div className={classes.dialogDescription}>
        {t('topUp.reminderDialog.description')}
      </div>

      <Button
        text={t('topUp.reminderDialog.buttonText')}
        primary
        className={classes.button}
        onClick={handleClickButton}
      />
      <Fab
        className={classes.closeButton}
        size="small"
        color="primary"
        onClick={close}
      >
        <Close className={classes.closeIcon} />
      </Fab>
    </Dialog>
  );
};

export default TopUpReminderDialog;
