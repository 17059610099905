import { RootState } from 'core/store';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, CreditCount, Input } from 'shared/components';
import { PAYMENT_METHOD_SELECTION_ROUTE } from 'shared/constants';
import { useRoleNavigation } from 'shared/helpers';
import { CardLayout } from 'shared/layouts';
import useStyles from './useStyles';

const initialValues = {
  amount: '',
};

const TopUpWallet: FC = () => {
  const classes = useStyles();
  const { goBack } = useHistory();
  const { t, i18n } = useTranslation();
  const { currentUser, currency } = useSelector((state: RootState) => ({
    currentUser: state.currentUser.data,
    currency: state.currentEvent.data?.currency || '',
  }));
  const { push } = useRoleNavigation();

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        amount: Yup.string()
          .required(t('validation.required'))
          .matches(/^\d+$/g, t('validation.mustBeAWholeNumber')),
      }),
    [i18n.language],
  );

  const handleFormSubmit = (values: typeof initialValues) => {
    const amountNumber = Number(values.amount.replace(',', '.'));
    push(`${PAYMENT_METHOD_SELECTION_ROUTE}?topUp=${amountNumber}`);
  };

  return (
    <Formik<typeof initialValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleFormSubmit}
    >
      {({ errors, handleChange, handleSubmit }) => (
        <CardLayout
          title={t('topUpWallet.title')}
          contentTitle={t('topUpWallet.contentTitle')}
          contentDescription={t('topUpWallet.contentDescription')}
          onBackClick={goBack}
          backgroundColor="white"
          footer={
            <div className={classes.footer}>
              <Button
                className={classes.button}
                text={t('topUpWallet.buttonText')}
                primary
                onClick={handleSubmit}
              />
            </div>
          }
          maxContentWidth={750}
          centered
        >
          <div className={classes.container}>
            <div className={classes.creditsDescription}>
              {t('topUpWallet.creditCountDescription')}
            </div>
            <CreditCount
              title={t('topUpWallet.creditCountTitle')}
              amount={currentUser?.credits ?? 0}
              currency={currency}
            />
            <Input
              name="amount"
              placeholder={t('topUpWallet.inputPlaceholder')}
              onChange={handleChange}
              error={errors.amount}
              inputMode="numeric"
            />
          </div>
        </CardLayout>
      )}
    </Formik>
  );
};

export default TopUpWallet;
