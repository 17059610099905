import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { TOP_BAR_HEIGHT } from 'shared/constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: `calc(100% - ${TOP_BAR_HEIGHT}px)`,
    },
    largeText: {
      fontSize: '50px',
      fontWeight: 900,
      color: theme.customPalette.accentText,
    },
    smallText: {
      fontSize: '18px',
      fontWeight: 600,
      color: theme.customPalette.labelGray,
    },
  }),
);

export default useStyles;
