import { SvgIcon } from '@material-ui/core';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getOutstandingOrders } from 'core/requests';
import { loaderSlice, messageSlice } from 'core/store';
import { usePermissions } from 'features/Auth';
import { FlatLayout } from 'shared/layouts';
import { Card, MerchantHeader, ScreenFooter } from 'shared/components';
import { ReactComponent as BoothSvg } from 'assets/booth.svg';
import { ReactComponent as OrderSvg } from 'assets/order.svg';
import { ReactComponent as StockSvg } from 'assets/stock.svg';
import { ReactComponent as QRCodeSvg } from 'assets/qr-code.svg';
import { ReactComponent as ScanApp } from 'assets/scan-app.svg';
import {
  BOOTH_MENU_ROUTE,
  MERCHANT_QR_FOR_CREW,
  ORDER_HISTORY_ROUTE,
  PRODUCT_OVERVIEW_ROUTE,
  QR_SCAN_ROUTE,
} from 'shared/constants';
import { useRoleNavigation } from 'shared/helpers';
import { MerchantOrderHistoryItem } from 'shared/types';
import useStyles from './styles';

const renderPlacedOrders = (value: string, className: string) =>
  value.split(' ').map((segment) =>
    isNaN(+segment) ? (
      `${segment} `
    ) : (
      <span key={segment} className={className}>
        {segment}{' '}
      </span>
    ),
  );

type MenuItem = {
  name: string;
  Image: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  path: string;
};

const menuItems: Array<MenuItem> = [
  {
    name: 'Stock updates',
    Image: StockSvg,
    path: PRODUCT_OVERVIEW_ROUTE,
  },
  { name: 'Scannen', Image: QRCodeSvg, path: QR_SCAN_ROUTE },
  { name: 'Place an order', Image: OrderSvg, path: BOOTH_MENU_ROUTE },
  { name: 'Placed orders', Image: BoothSvg, path: ORDER_HISTORY_ROUTE },
];

const MerchantHome: FC = () => {
  const classes = useStyles();
  const { push } = useRoleNavigation();
  const { t } = useTranslation();
  const [outstandingOrders, setOutsatandingOrders] = useState<
    MerchantOrderHistoryItem[]
  >([]);
  const dispatch = useDispatch();
  const { isAllowedTo } = usePermissions();

  useEffect(() => {
    (async () => {
      dispatch(loaderSlice.actions.setLoaderState({ loading: true }));
      try {
        const response = await getOutstandingOrders();
        setOutsatandingOrders(response.data.data.items);
      } catch (error) {
        if (error?.response?.status !== 404) {
          dispatch(
            messageSlice.actions.showMessage({
              text: t(
                'merchantOrderHistory.message.fetchOutstandingOrdersFail',
              ),
              type: 'error',
            }),
          );
        }
      } finally {
        dispatch(loaderSlice.actions.setLoaderState({ loading: false }));
      }
    })();
  }, []);

  const handleClickActivateScanApp = () => {
    push(MERCHANT_QR_FOR_CREW);
  };

  const renderMenuItem = (item: MenuItem) => (
    <Card
      key={item.name}
      className={classes.card}
      onClick={() => {
        push(item.path);
      }}
      altShadow
    >
      {item.name === 'Placed orders' ? (
        <div className={classes.cardBodyWithCount}>
          <div className={classes.topContainer}>
            <SvgIcon
              className={classes.menuItemImageSmall}
              component={item.Image}
            />
            <span className={classes.menuItemNameSmall}>{item.name}</span>
          </div>
          <div className={classes.bottomContainer}>
            {renderPlacedOrders(
              t('merchant.home.itemWithCount', {
                smart_count: outstandingOrders.length,
              }),
              classes.itemsCountValue,
            )}
          </div>
        </div>
      ) : (
        <>
          <SvgIcon className={classes.menuItemImage} component={item.Image} />
          <span className={classes.menuItemName}>{item.name}</span>
        </>
      )}
    </Card>
  );

  return (
    <FlatLayout
      header={<MerchantHeader heading={t('merchant.home.menu')} />}
      footer={<ScreenFooter fixed className={classes.footer} />}
    >
      <div className={classes.container}>
        <div className={classes.menuItemsContainer}>
          {menuItems.map(renderMenuItem)}
          {isAllowedTo('crew.activeDevices.add') && (
            <Card
              className={classes.longCard}
              onClick={handleClickActivateScanApp}
              altShadow
            >
              <SvgIcon
                className={classes.longMenuItemImage}
                component={ScanApp}
              />
              <div>
                <div className={classes.activateButtonLabel}>
                  {t('merchant.home.activateButtonLabel')}
                </div>
                <div className={classes.activateButtonDescription}>
                  {t('merchant.home.activateButtonDescription')}
                </div>
              </div>
            </Card>
          )}
        </div>
      </div>
    </FlatLayout>
  );
};

export default MerchantHome;
