import { FC, ReactNode } from 'react';
import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import { ChevronLeft, Close } from '@material-ui/icons';
import { TOP_BAR_HEIGHT } from 'shared/constants';

const useStyles = makeStyles<
  Theme,
  {
    backgroundColor?: string;
    contentTitleColor?: string;
    centered?: boolean;
    maxContentWidth?: number;
  }
>((theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.customPalette.background,
      position: 'relative',
      minHeight: `calc(100% - ${TOP_BAR_HEIGHT}px)`,
    },
    secondaryHeader: {
      padding: '3px 15px 20px 15px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: theme.customPalette.background,
    },
    leftSlot: {
      flex: '1 0 auto',
      width: 26,
    },
    centerSlot: {
      flex: '1 1 auto',
      '& h2': {
        margin: 0,
        padding: theme.spacing(0, 1),
        fontSize: '1rem',
        fontWeight: 700,
        textAlign: 'center',
        color: theme.palette.common.white,
      },
    },
    rightSlot: {
      flex: '1 0 auto',
      width: 26,
    },
    cardContainer: ({ backgroundColor, centered }) => ({
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor:
        backgroundColor ?? theme.customPalette.lightGrayBackground,
      borderTopLeftRadius: '26px',
      borderTopRightRadius: '26px',
      '@media (min-height: 900px)': {
        justifyContent: centered ? 'center' : 'initial',
      },
    }),
    cardSubcontainer: ({ maxContentWidth }) => ({
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      maxWidth: maxContentWidth ?? 'none',
      boxSizing: 'border-box',
      padding: '30px 20px 20px 20px',
      color: '#393f44',
      '& h3': {
        margin: '0 0 20px 0',
        fontSize: '1.3125rem',
        fontWeight: 'bold',
      },
    }),
    centered: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      width: '100%',
      alignSelf: 'center',
      '@media (min-height: 900px)': {
        justifyContent: 'center',
      },
    },
    title: {
      alignSelf: 'center',
      fontSize: '16px',
      fontWeight: 700,
      color: 'white',
      marginBottom: '24px',
      marginTop: '6px',
    },
    titlePlaceholder: {
      height: '22px',
      marginBottom: '24px',
      marginTop: '6px',
    },
    button: {
      padding: 0,
      minWidth: 0,
    },
    buttonLabel: {
      padding: 0,
      margin: 0,
    },
    icon: {
      fontSize: '26px',
      color: 'white',
    },
    topContainer: {
      paddingBottom: '20px',
    },
    contentTitle: {
      color: (props) => props.contentTitleColor ?? '#000',
      fontSize: '21px',
      fontWeight: 800,
      display: 'block',
    },
    contentDescription: {
      color: (props) => props.contentTitleColor ?? '#888',
      fontSize: '16px',
      fontWeight: 600,
      margin: 0,
    },
    footerContainer: {
      backgroundColor: (props) =>
        props.backgroundColor ?? theme.customPalette.lightGrayBackground,
    },
  }),
);

type Props = {
  title?: string;
  contentTitle?: string;
  contentUnderTitle?: ReactNode;
  contentDescription?: string;
  footer?: ReactNode;
  backgroundColor?: string;
  contentTitleColor?: string;
  onBackClick?: () => void;
  onCloseClick?: () => void;
  centered?: boolean;
  maxContentWidth?: number;
};

const CardLayout: FC<Props> = ({
  title,
  contentTitle,
  contentUnderTitle,
  contentDescription,
  footer,
  backgroundColor,
  contentTitleColor,
  onBackClick,
  onCloseClick,
  children,
  centered,
  maxContentWidth,
}) => {
  const classes = useStyles({
    backgroundColor,
    contentTitleColor,
    centered,
    maxContentWidth,
  });

  return (
    <div className={classes.container}>
      <div className={classes.secondaryHeader}>
        <div className={classes.leftSlot}>
          {onBackClick && (
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              classes={{
                label: classes.buttonLabel,
              }}
              onClick={onBackClick}
            >
              <ChevronLeft className={classes.icon} />
            </Button>
          )}
          {onCloseClick && (
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              classes={{
                label: classes.buttonLabel,
              }}
              onClick={onCloseClick}
            >
              <Close className={classes.icon} />
            </Button>
          )}
        </div>
        <div className={classes.centerSlot}>
          <h2>{title}</h2>
        </div>
        <div className={classes.rightSlot} />
      </div>
      <div className={classes.cardContainer}>
        <div className={classes.cardSubcontainer}>
          {(contentTitle || contentUnderTitle || contentDescription) && (
            <div className={classes.topContainer}>
              {contentTitle && (
                <div className={classes.contentTitle}>{contentTitle}</div>
              )}
              {contentUnderTitle && <div>{contentUnderTitle}</div>}
              {contentDescription && (
                <p className={classes.contentDescription}>
                  {contentDescription}
                </p>
              )}
            </div>
          )}
          {children}
        </div>
      </div>
      <div className={classes.footerContainer}>{footer}</div>
    </div>
  );
};

export default CardLayout;
