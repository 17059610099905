import { FC, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as QRCodeIcon } from 'assets/qr-code.svg';
import {
  getOrderDetails,
  getVisitorRemainingCredits,
  putOrderAsRetrieved,
} from 'core/requests';
import { loaderSlice, messageSlice, RootState } from 'core/store';
import { STATUS_BACKGROUND_COLORS } from 'shared/constants';
import { getOrderStatusExtended } from 'shared/helpers';
import { CardLayout, CrewLayout } from 'shared/layouts';
import {
  Order,
  OrderStatus,
  OrderStatusExtended,
  PaymentStatus,
} from 'shared/types';
import CrewFooter from './CrewFooter';
import Footer from './Footer';
import Result from './Result';

const ScanOrderResult: FC = () => {
  const { goBack } = useHistory();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { orderId } = useParams<{ orderId: string }>();
  const [order, setOrder] = useState<Order>();
  const [remainingCredits, setRemainingCredits] = useState(0);
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state: RootState) => ({
    currentUser: state.currentUser.data,
  }));
  const merchantBoothId = currentUser?.merchantBoothId;
  const isCrew = pathname.includes('/crew');

  const fetchOrderDetails = async () => {
    dispatch(loaderSlice.actions.setLoaderState({ loading: true }));
    try {
      const {
        data: { data },
      } = await getOrderDetails(orderId);
      const response = await getVisitorRemainingCredits(data.authorUuid);
      setOrder(data);
      setRemainingCredits(response?.data?.data?.remainingCredits);

      if (
        data.status === OrderStatus.Pending &&
        data.paymentStatus === PaymentStatus.Paid
      ) {
        await putOrderAsRetrieved(data.id, merchantBoothId!);
      }
    } catch (error) {
      dispatch(
        messageSlice.actions.showMessage({
          text: error.message,
          type: 'error',
        }),
      );
    }
    dispatch(loaderSlice.actions.setLoaderState({ loading: false }));
  };

  useEffect(() => {
    if (merchantBoothId) {
      // fetchOrderDetails();
    }
    fetchOrderDetails();
  }, [merchantBoothId, orderId]);

  const orderViewType: OrderStatusExtended = useMemo(() => {
    return getOrderStatusExtended(order?.status, order?.paymentStatus);
  }, [orderId, order]);

  const getCardTitle = () => {
    switch (orderViewType) {
      case OrderStatusExtended.Paid:
        return t('scanOrderResult.title.paid');
      case OrderStatusExtended.Retrieved:
        return t('scanOrderResult.title.retrieved');
      default:
        return undefined;
    }
  };

  const getContentTitle = () =>
    orderViewType === OrderStatusExtended.Unknown
      ? t('scanOrderResult.contentTitleUnknown')
      : t('scanOrderResult.contentTitle', { number: order?.number });

  const getOrderDescription = () => {
    switch (orderViewType) {
      case OrderStatusExtended.Helpdesk:
        return t('scanOrderResult.description.helpdesk');
      case OrderStatusExtended.Retrieved:
        return t('scanOrderResult.description.retrieved');
      default:
        return undefined;
    }
  };

  const getContent = () => {
    if (order) {
      switch (orderViewType) {
        case OrderStatusExtended.Paid:
          return (
            <Result.Paid
              order={order}
              remainingCredits={remainingCredits}
              fetchOrderDetails={fetchOrderDetails}
              isCrew={isCrew}
            />
          );
        case OrderStatusExtended.Helpdesk:
          return <Result.Helpdesk order={order} isCrew={isCrew} />;
        case OrderStatusExtended.Retrieved:
          return <Result.Retrieved order={order} isCrew={isCrew} />;
        case OrderStatusExtended.WrongBooth:
          return <Result.WrongBooth order={order} isCrew={isCrew} />;
        default:
          return <Result.Unknown isCrew={isCrew} />;
      }
    }

    return <Result.Unknown isCrew={isCrew} />;
  };

  if (isCrew) {
    return (
      <CrewLayout
        title={t('crew.home.title')}
        titleIcon={QRCodeIcon}
        footer={
          <CrewFooter
            remainingCredits={remainingCredits}
            status={orderViewType}
            order={order}
            fetchOrderDetails={fetchOrderDetails}
          />
        }
        backgroundColor={STATUS_BACKGROUND_COLORS[orderViewType]}
        centered={orderViewType === OrderStatusExtended.Unknown}
      >
        {getContent()}
      </CrewLayout>
    );
  }

  return (
    <CardLayout
      title={getCardTitle()}
      contentTitle={getContentTitle()}
      contentDescription={getOrderDescription()}
      contentTitleColor="white"
      backgroundColor={STATUS_BACKGROUND_COLORS[orderViewType]}
      footer={<Footer status={orderViewType} order={order} />}
      onBackClick={
        orderViewType !== OrderStatusExtended.Paid ? goBack : undefined
      }
      centered
      maxContentWidth={750}
    >
      {getContent()}
    </CardLayout>
  );
};

export default ScanOrderResult;
