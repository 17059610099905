import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { getMerchantOrderDetails } from 'core/requests';
import { loaderSlice, messageSlice } from 'core/store';
import { OrderCard, Status } from 'shared/components';
import { HOME_ROUTE } from 'shared/constants';
import { useRoleNavigation } from 'shared/helpers';
import { CardLayout, CrewLayout } from 'shared/layouts';
import { Order, PaymentStatus } from 'shared/types';
import Footer from './Footer';
import useStyles from './useStyles';

const MerchantPayment: FC = () => {
  const classes = useStyles({});
  const { t } = useTranslation();
  const { location } = useHistory();
  const { push } = useRoleNavigation();
  const dispatch = useDispatch();
  const query = new URLSearchParams(location.search);
  const orderUuid = query.get('orderId');
  const [orderDetails, setOrderDetails] = useState<Order>();
  const [paymentStatus, setPaymentStatus] =
    useState<PaymentStatus | undefined>();
  const isCrew = location.pathname.includes('/crew');

  useEffect(() => {
    if (orderUuid) {
      (async () => {
        dispatch(loaderSlice.actions.setLoaderState({ loading: true }));
        try {
          const {
            data: { data },
          } = await getMerchantOrderDetails(orderUuid);
          setOrderDetails(data);
          setPaymentStatus(data.paymentStatus);
        } catch (error) {
          dispatch(
            messageSlice.actions.showMessage({
              text: t('payment.message.fetchNewOrdersFail'),
              type: 'error',
            }),
          );
        } finally {
          dispatch(loaderSlice.actions.setLoaderState({ loading: false }));
        }
      })();
    }
  }, [orderUuid]);

  const getStatusBasedProps = () => {
    switch (paymentStatus) {
      case PaymentStatus.Paid:
        return {
          contentTitle: t('payment.success.contentTitle'),
          contentUnderTitle: (
            <Status text={t('payment.success.subtitle')} status="success" />
          ),
        };
      case PaymentStatus.Open:
        return {
          contentTitle: t('payment.pending.contentTitle'),
          contentUnderTitle: (
            <Status text={t('payment.pending.subtitle')} status="warning" />
          ),
        };
      case undefined:
        return {
          contentTitle: t('payment.loading.contentTitle'),
          contentUnderTitle: (
            <Status text={t('payment.loading.subtitle')} status="warning" />
          ),
        };
      default:
        return {
          contentTitle: t('payment.failure.contentTitle'),
          contentUnderTitle: (
            <Status text={t('payment.failure.subtitle')} status="failure" />
          ),
        };
    }
  };

  const handleGoBack = () => {
    push(HOME_ROUTE);
  };

  const statusBasedProps = getStatusBasedProps();

  if (isCrew) {
    return (
      <CrewLayout
        title={t('payment.title')}
        footer={<Footer orderDetails={orderDetails} isCrew />}
      >
        <div className={classes.subcontainer}>
          <div className={classes.topContainer}>
            <div className={classes.contentTitle}>
              {statusBasedProps.contentTitle}
            </div>
            <div>{statusBasedProps.contentUnderTitle}</div>
          </div>
          {orderDetails && (
            <OrderCard
              orderBoothTypes={[orderDetails.booth.type]}
              orderItems={orderDetails.products}
              orderDate={orderDetails.date}
              orderTotalAmount={orderDetails.totalAmount}
              orderStatus={orderDetails.status}
              paymentStatus={orderDetails.paymentStatus}
              orderNumber={orderDetails.number}
              showSummary
              alwaysExpanded
              noAvailability
            />
          )}
        </div>
      </CrewLayout>
    );
  }

  return (
    <CardLayout
      title={t('payment.title')}
      {...statusBasedProps}
      onBackClick={handleGoBack}
      footer={<Footer orderDetails={orderDetails} />}
      maxContentWidth={750}
      centered
    >
      {orderDetails && (
        <OrderCard
          orderBoothTypes={[orderDetails.booth.type]}
          orderItems={orderDetails.products}
          orderDate={orderDetails.date}
          orderTotalAmount={orderDetails.totalAmount}
          orderStatus={orderDetails.status}
          paymentStatus={orderDetails.paymentStatus}
          orderNumber={orderDetails.number}
          showSummary
          alwaysExpanded
          noAvailability
        />
      )}
    </CardLayout>
  );
};

export default MerchantPayment;
