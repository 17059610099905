import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { FC } from 'react';
import QrcodeReact from 'qrcode.react';
import { getQrCodeValueString } from 'shared/helpers';
import { QrCodeType } from 'shared/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    qrCodeContainer: {
      boxSizing: 'border-box',
      width: '100%',
      maxWidth: 'calc(100vh - 60px)',
      margin: '0 auto',
    },
    qrCodeBox: {
      maxWidth: '460px',
      margin: '0 auto',
      padding: '30px',
      borderRadius: '8px',
      backgroundColor: theme.palette.common.white,
      boxShadow: '0 0 21px -2px rgba(0,0,0,0.2)',
    },
    qrCode: {
      width: '100%',
      height: 'auto',
    },
  }),
);

type Props = {
  type: QrCodeType;
  value: string | number | null;
};

const QrCode: FC<Props> = ({ type, value }) => {
  const classes = useStyles();

  return value ? (
    <div className={classes.qrCodeContainer}>
      <div className={classes.qrCodeBox}>
        <QrcodeReact
          className={classes.qrCode}
          value={getQrCodeValueString(type, value)}
          renderAs="svg"
        />
      </div>
    </div>
  ) : null;
};

export default QrCode;
