import { createStyles, Dialog, makeStyles, Theme } from '@material-ui/core';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'shared/components';
import Status from './Status';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      borderRadius: '25px',
      padding: '37px 22px 24px 22px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    dialogTitle: {
      width: '240px',
      fontSize: '21px',
      fontWeight: 800,
      lineHeight: '28px',
      color: theme.customPalette.textPrimary,
      marginBottom: '12px',
      textAlign: 'center',
    },
    dialogDescription: {
      width: '240px',
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '19px',
      color: theme.customPalette.accentText,
      marginBottom: '20px',
      textAlign: 'center',
    },
    button: {
      maxWidth: '240px',
      marginTop: '20px',
    },
  }),
);

type Props = {
  open: boolean;
  onClickButton: () => void;
  entity: string;
};

const CancelSuccessDialog: FC<Props> = ({ open, onClickButton, entity }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      PaperProps={{ className: classes.container }}
      maxWidth="xs"
    >
      <div className={classes.dialogTitle}>
        {t(`orderDetails.cancel${entity}ConfirmationDialog.title`)}
      </div>
      <Status
        text={t(
          `orderDetails.cancel${entity}ConfirmationDialog.statusDescription`,
        )}
      />
      <div className={classes.dialogDescription}>
        {t(`orderDetails.cancel${entity}ConfirmationDialog.description`)}
      </div>

      <Button
        text={t(
          `orderDetails.cancel${entity}ConfirmationDialog.backToTheOrderHistory`,
        )}
        primary
        className={classes.button}
        onClick={onClickButton}
      />
    </Dialog>
  );
};

export default CancelSuccessDialog;
