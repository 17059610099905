import { createStyles, Fab, makeStyles, Radio, Theme } from '@material-ui/core';
import { CheckCircle, Close } from '@material-ui/icons';
import { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { postSwitchBooth } from 'core/requests';
import {
  currentUserSlice,
  loaderSlice,
  messageSlice,
  RootState,
} from 'core/store';
import { MerchantBooth } from 'shared/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerOverlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      height: '100vh',
      zIndex: 1,
      backgroundColor: 'rgba(0,0,0,0.4)',
    },
    bottomDrawer: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      minHeight: 200,
      maxHeight: 'calc(100% - 85px)',
      borderTopLeftRadius: 25,
      borderTopRightRadius: 25,
      paddingTop: 25,
      backgroundColor: theme.palette.common.white,
      zIndex: 1,
      overflowY: 'scroll',
    },
    scrollable: {
      padding: '0 18px 18px 18px',
      '& > h3': {
        margin: 0,
        fontSize: '21px',
        fontWeight: 800,
        letterSpacing: 0,
        lineHeight: '28px',
      },
    },
    boothSelection: {
      listStyle: 'none',
      margin: 0,
      padding: 0,
    },
    radio: {
      color: '#d0d0d0',
    },
    menuIcon: {
      fontSize: '24px',
      color: 'white',
    },
    button: {
      position: 'absolute',
      top: 9,
      right: 15,
      height: '40px',
      width: '40px',
    },
    rightAddon: {
      marginLeft: 10,
      fontSize: '10px',
      color: '#97AABA',
      textTransform: 'uppercase',
    },
    boothItem: {
      display: 'flex',
      alignItems: 'center',
      fontSize: '13px',
    },
  }),
);

type Props = {
  close: () => void;
};

const BottomDrawer: FC<Props> = ({ close }) => {
  const classes = useStyles();
  const { currentUser, merchantBooths } = useSelector((state: RootState) => ({
    currentUser: state.currentUser.data,
    merchantBooths: state.merchantBooths.data?.items || [],
  }));
  const merchantBoothId = currentUser?.merchantBoothId;
  const [currentBoothId, setCurrentBoothId] = useState(merchantBoothId);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleRadioClick = async (event: ChangeEvent<HTMLInputElement>) => {
    const boothId = currentBoothId;
    if (event.target.value) {
      dispatch(loaderSlice.actions.setLoaderState({ loading: true }));
      try {
        setCurrentBoothId(parseInt(event.target.value, 10));
        await postSwitchBooth(parseInt(event.target.value, 10));
        dispatch(currentUserSlice.actions.getOnlyCurrentUserRequest());
        close();
      } catch {
        setCurrentBoothId(boothId);
        dispatch(
          messageSlice.actions.showMessage({
            text: t('appMenu.message.changeAccountFail'),
            type: 'error',
          }),
        );
      } finally {
        dispatch(loaderSlice.actions.setLoaderState({ loading: false }));
      }
    }
  };

  const renderListItem = (item: MerchantBooth) => (
    <li key={item.id} className={classes.boothItem}>
      <Radio
        className={classes.radio}
        color="primary"
        checked={item.id === currentBoothId}
        onChange={handleRadioClick}
        value={item.id}
        inputProps={{ 'aria-label': item.name }}
        checkedIcon={<CheckCircle fontSize="inherit" />}
      />
      {item.name}
      {!item.open && (
        <span className={classes.rightAddon}>{`(${t('appMenu.closed')})`}</span>
      )}
    </li>
  );

  return (
    <div className={classes.drawerOverlay}>
      <Fab
        className={classes.button}
        size="small"
        color="primary"
        onClick={close}
      >
        <Close className={classes.menuIcon} />
      </Fab>
      <div className={classes.bottomDrawer}>
        <div className={classes.scrollable}>
          <h3>Accounts</h3>
          <ul className={classes.boothSelection}>
            {merchantBooths.map(renderListItem)}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BottomDrawer;
