import { createSlice } from '@reduxjs/toolkit';
import { PaymentStatus } from 'shared/types';
import reducers from './reducers';
import { OrderedProductsState } from '.';

const initialState: OrderedProductsState = {
  loading: false,
  isDraft: false,
  products: {},
  total: 0,
  defaultCurrencyTotal: 0,
  paymentStatus: PaymentStatus.None,
};

const orderedProductsSlice = createSlice({
  name: 'orderedProducts',
  initialState,
  reducers,
});

export default orderedProductsSlice;
