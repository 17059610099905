import { call, put, takeEvery } from 'redux-saga/effects';
import { getDraft } from 'core/requests';
import { PaymentStatus, PromiseResult } from 'shared/types';
import i18next from 'i18next';
import { messageSlice } from '../message';
import orderedProductsSlice from './slice';

function* getUserDraftSaga() {
  try {
    const {
      data: { data },
    }: PromiseResult<ReturnType<typeof getDraft>> = yield call(getDraft);
    if (
      data.products.length &&
      (!data.paymentStatus || data.paymentStatus === PaymentStatus.None)
    ) {
      yield put(orderedProductsSlice.actions.getOrderDraftSuccess(data));
    }
  } catch (error) {
    if (error?.response?.status === 404) {
      yield put(orderedProductsSlice.actions.getOrderDraftEmptySuccess());
    } else {
      yield put(orderedProductsSlice.actions.getOrderDraftFailure());
      yield put(
        messageSlice.actions.showMessage({
          text: i18next.t('message.draftError'),
          type: 'error',
        }),
      );
    }
  }
}

export function* getUserDraftSagaWatcher() {
  yield takeEvery(
    orderedProductsSlice.actions.getOrderDraftRequest,
    getUserDraftSaga,
  );
}
