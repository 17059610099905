import clsx from 'clsx';
import { parseISO } from 'date-fns';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { OrderSummary } from 'shared/components';
import { Order } from 'shared/types';
import useStyles from '../useStyles';
import OrderDetails from './OrderDetails';

type Props = {
  order: Order;
  isCrew: boolean;
};

const Retrieved: FC<Props> = ({ isCrew, order }) => {
  const { t } = useTranslation();
  const classes = useStyles({ isCrew });
  return (
    <div
      className={clsx(
        classes.retrievedContainer,
        isCrew && classes.crewContainer,
      )}
    >
      {isCrew && <h3>{t('scanOrderResult.description.retrieved')}</h3>}
      <OrderDetails
        className={classes.orderDetails}
        boothType={order.booth.type}
        boothLocation={order.booth.zone}
        date={parseISO(order.date)}
        isCrew={isCrew}
      />
      <OrderSummary
        backgroundColor="#ffe2e2"
        textColor="#8f1a1a"
        items={order.products}
      />
    </div>
  );
};

export default Retrieved;
