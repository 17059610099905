import { IconButton } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import clsx from 'clsx';
import { FunctionComponent } from 'react';
import screens from './screens';
import useStyles from './styles';

type Props = {
  activeIndex: number;
  onClickNext: () => void;
};

const IntroScreens: FunctionComponent<Props> = ({
  activeIndex,
  onClickNext,
}) => {
  const classes = useStyles();

  const renderPageNumber = (screen: typeof screens[0]) => (
    <li key={screen.key}>
      <span className={clsx(activeIndex === screen.pageNumber - 1 && 'active')}>
        {screen.pageNumber}
      </span>
    </li>
  );

  return (
    <nav className={classes.pagination} aria-label="pagination navigation">
      <ul>
        {screens.map(renderPageNumber)}
        <li>
          <IconButton
            className={classes.arrowButton}
            aria-label="next view"
            onClick={onClickNext}
            size="small"
          >
            <ArrowForwardIcon />
          </IconButton>
        </li>
      </ul>
    </nav>
  );
};

export default IntroScreens;
