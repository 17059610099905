import { createStyles, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { FC } from 'react';
import { getCurrencyString } from 'shared/helpers';

const useStyles = makeStyles<
  Theme,
  { coloredBackground?: boolean; noMargin?: boolean; secondVariant?: boolean }
>((theme: Theme) =>
  createStyles({
    totalContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: ({ noMargin }) => (noMargin ? 0 : '30px'),
    },
    totalText: ({ secondVariant }) => ({
      marginRight: '20px',
      fontSize: '16px',
      fontWeight: 600,
      color: secondVariant ? '#C7BAE8' : theme.customPalette.textPrimary,
    }),
    amountContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    currencyText: {
      fontSize: '14px',
      lineHeight: '19px',
      color: ({ coloredBackground, secondVariant }) => {
        if (secondVariant) {
          return '#C7BAE8';
        }
        return coloredBackground ? 'white' : theme.customPalette.accentText;
      },
      marginRight: '4px',
      marginBottom: '8px',
    },
    amountText: ({ secondVariant }) => ({
      fontSize: '20px',
      fontWeight: 700,
      color: secondVariant ? 'white' : theme.customPalette.textPrimary,
      marginRight: '4px',
    }),
    amountDecoration: {
      height: '36px',
      width: '2px',
      backgroundColor: '#00ff98',
    },
  }),
);

type Props = {
  className?: string;
  title: string;
  amount: number;
  currency: string;
  coloredBackground?: boolean;
  noMargin?: boolean;
  secondVariant?: boolean;
};

const CreditCount: FC<Props> = ({
  className,
  title,
  amount,
  currency,
  coloredBackground,
  noMargin,
  secondVariant,
}) => {
  const classes = useStyles({ coloredBackground, noMargin, secondVariant });

  return (
    <div className={clsx(classes.totalContainer, className)}>
      <span className={classes.totalText}>{title}</span>
      <div className={classes.amountContainer}>
        <span className={classes.currencyText}>
          {getCurrencyString(currency)}
        </span>
        <span className={classes.amountText}>{amount.toFixed(2)}</span>
        <div className={classes.amountDecoration} />
      </div>
    </div>
  );
};

export default CreditCount;
