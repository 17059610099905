import { createStyles, makeStyles, Theme } from '@material-ui/core';

const containerProps = {
  padding: '20px 20px 0 20px',
  '& > h3': {
    fontSize: '31px',
    fontWeight: 'bold',
    letterSpacing: '1.48px',
    color: 'white',
  },
};

const useStyles = makeStyles<Theme, { isCrew?: boolean }>(() =>
  createStyles({
    paidContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    paidFooter: {
      boxSizing: 'border-box',
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '33px 21px 16px 21px',
    },
    confirmRetrievedButton: {
      margin: '10px 0',
    },
    orderDetails: ({ isCrew }) => ({
      marginBottom: 20,
      opacity: isCrew ? 0.8 : 1,
    }),
    orderDetailsText: ({ isCrew }) => ({
      color: 'white',
      fontSize: isCrew ? '20px' : '16px',
      lineHeight: isCrew ? '26px' : '22px',
      fontWeight: 600,
      marginRight: '4px',
    }),
    orderDetailsRowContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    iconText: ({ isCrew }) => ({
      marginTop: isCrew ? '32px' : '0',
      fontSize: isCrew ? '31px' : '21px',
      lineHeight: '28px',
      color: 'white',
      fontWeight: 900,
    }),
    secondaryContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    helpdeskContainer: containerProps,
    helpdeskButton: {
      marginBottom: '16px',
    },
    helpdeskIcon: ({ isCrew }) => ({
      fontSize: isCrew ? '148px' : '50px',
      color: 'white',
      marginTop: isCrew ? '41px' : '20px',
      opacity: 0.5,
    }),
    helpdeskText: {
      fontSize: '21px',
      lineHeight: '28px',
      color: 'white',
      fontWeight: 900,
    },
    crewContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      maxWidth: '460px',
    },
    retrievedContainer: containerProps,
    unknownContainer: {
      padding: '20px 20px 0 20px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    unknownIcon: ({ isCrew }) => ({
      fontSize: isCrew ? '122px' : '44px',
      color: 'white',
      marginTop: isCrew ? '0' : '78px',
      opacity: isCrew ? 0.5 : 1,
    }),
    unknownDescription: {
      color: '#ffe2e2',
      fontSize: '16px',
      lineHeight: '22px',
      fontWeight: 600,
    },
    wrongBoothContainer: containerProps,
    wrongBoothIcon: ({ isCrew }) => ({
      fontSize: isCrew ? '120px' : '44px',
      color: 'white',
      marginTop: isCrew ? '50px' : '20px',
      opacity: isCrew ? 0.5 : 1,
    }),
    remainingCredits: {
      margin: 0,
    },
  }),
);

export default useStyles;
