import Payment from './Payment';
import ToPUpPayment from './TopUpPayment';
import TopUpWallet from './TopUpWallet';
import MerchantPayment from './MerchantPayment';

export default {
  Payment,
  ToPUpPayment,
  TopUpWallet,
  MerchantPayment,
};
