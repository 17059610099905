import { push } from 'connected-react-router';
import i18next from 'i18next';
import {
  categoriesSlice,
  currentEventSlice,
  currentUserSlice,
  merchantBoothsSlice,
  messageSlice,
  orderedProductsSlice,
  store,
} from 'core/store';
import { LOGIN_ROUTE, MERCHANT_LOGIN_ROUTE } from 'shared/constants';
import authToken from './authToken';

const signOut = () => {
  authToken.remove();
  const { currentUser } = store.getState();
  if (currentUser?.data?.role.includes('ROLE_MERCHANT')) {
    store.dispatch(push(MERCHANT_LOGIN_ROUTE));
  } else {
    store.dispatch(push(LOGIN_ROUTE));
  }
  store.dispatch(
    messageSlice.actions.showMessage({
      text: i18next.t('message.loggedOut'),
      type: 'error',
    }),
  );
  store.dispatch(currentUserSlice.actions.resetCurrentUserState());
  store.dispatch(currentEventSlice.actions.resetCurrentEventState());
  store.dispatch(merchantBoothsSlice.actions.resetMerchantBoothsState());
  store.dispatch(orderedProductsSlice.actions.clearOrderedProducts());
  store.dispatch(categoriesSlice.actions.resetCategoriesState());
};

export default signOut;
