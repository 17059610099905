import { Info } from '@material-ui/icons';
import { format } from 'date-fns';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { putBoothOrderingHours } from 'core/requests';
import { loaderSlice, messageSlice } from 'core/store';
import { usePermissions } from 'features/Auth';
import { MerchantBoothInfo, OrderingDay } from 'shared/types';
import OrderHoursCard from './OrderHoursCard';
import OrderHoursDialog from './OrderHoursDialog';
import useStyles from './useStyles';

type Props = {
  merchantBoothId?: number;
  boothInfo?: MerchantBoothInfo;
  fetchMerchantBoothInfo: () => Promise<void>;
};

const OrderingHours: FC<Props> = ({
  boothInfo,
  fetchMerchantBoothInfo,
  merchantBoothId,
}) => {
  const { isAllowedTo } = usePermissions();
  const classes = useStyles();
  const { t } = useTranslation();
  const [editedOrderingDay, setEditedOrderingDay] = useState<OrderingDay>();
  const dispatch = useDispatch();

  const handleOpeningHoursEditClick = (orderingDay: OrderingDay) => {
    setEditedOrderingDay({ ...orderingDay });
  };

  const handleOpeningHoursDialogClose = () => {
    setEditedOrderingDay(undefined);
  };

  const renderOpeningHours = (orderHours: OrderingDay) => (
    <OrderHoursCard
      key={`orderHours-${orderHours.dayNumber}`}
      active={orderHours.active}
      openingDate={orderHours.openingDate}
      closingDate={orderHours.closingDate}
      dayNumber={orderHours.dayNumber}
      onEditClick={() => {
        handleOpeningHoursEditClick(orderHours);
      }}
      isEditable={isAllowedTo('booth.orderingHours.edit')}
    />
  );

  const handleOpeningHoursSave = async (
    active: boolean,
    openingDate: Date,
    closingDate: Date,
  ) => {
    if (merchantBoothId && editedOrderingDay) {
      const payload = {
        dayNumber: editedOrderingDay.dayNumber,
        active,
        openingDate: format(openingDate, 'yyyy-MM-dd hh:mm:ss'),
        closingDate: format(closingDate, 'yyyy-MM-dd hh:mm:ss'),
      };
      dispatch(loaderSlice.actions.setLoaderState({ loading: true }));
      try {
        await putBoothOrderingHours(merchantBoothId, payload);
        fetchMerchantBoothInfo();
        setEditedOrderingDay(undefined);
      } catch {
        dispatch(
          messageSlice.actions.showMessage({
            text: t('merchantProfile.message.editOrderingHoursFail'),
            type: 'error',
          }),
        );
      } finally {
        dispatch(loaderSlice.actions.setLoaderState({ loading: false }));
      }
    }
  };

  return (
    <>
      <span className={classes.sectionTitleText}>
        {t('merchantProfile.orderHours')}
      </span>
      <div className={classes.orderHoursDescriptionContainer}>
        <Info className={classes.orderHoursIcon} />
        <p className={classes.orderHoursDescriptionText}>
          {t('merchantProfile.orderHoursDescription')}
        </p>
      </div>
      {boothInfo?.orderHours.map(renderOpeningHours)}
      {editedOrderingDay && (
        <OrderHoursDialog
          active={editedOrderingDay.active}
          open={true}
          openingDate={new Date(editedOrderingDay.openingDate)}
          closingDate={new Date(editedOrderingDay.closingDate)}
          dayNumber={editedOrderingDay.dayNumber}
          onSave={handleOpeningHoursSave}
          onClose={handleOpeningHoursDialogClose}
        />
      )}
    </>
  );
};

export default OrderingHours;
