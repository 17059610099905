import { FC } from 'react';
import Auth from 'features/Auth';
import { Permission } from 'features/Auth';
import AppContext from 'features/AppContext';
import Booth from 'features/Booth';
import Home from 'features/Home';
import Order from 'features/Order';
import Payments from 'features/Payments';
import {
  CREW_ACTIVATE_DEVICE_ROUTE,
  CREW_HOME_ROUTE,
  EVENT_SELECTION_ROUTE,
  LOGIN_ROUTE,
  REGISTER_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  HOME_ROUTE,
  BOOTH_SELECTION_ROUTE,
  BOOTH_MENU_ROUTE,
  BOOTH_INFO_ROUTE,
  CHECKOUT_ROUTE,
  MERCHANT_LOGIN_ROUTE,
  MERCHANT_SET_PASSWORD_ROUTE,
  MERCHANT_FORGOT_PASSWORD_ROUTE,
  PRODUCT_OVERVIEW_ROUTE,
  SET_PASSWORD_ROUTE,
  PROFILE_ROUTE,
  CHANGE_PASSWORD_ROUTE,
  CHANGE_PROFILE_DATA_ROUTE,
  QR_SCAN_ROUTE,
  ORDER_BY_BOOTH_NUMBER_ROUTE,
  ORDER_DETAILS_ROUTE,
  ORDER_HISTORY_ROUTE,
  PAYMENT_METHOD_SELECTION_ROUTE,
  MY_QR_ROUTE,
  SCAN_ORDER_RESULT_ROUTE,
  MY_CREDITS_ROUTE,
  TOP_UP_WALLET_ROUTE,
  PAYMENT_REDIRECT_ROUTE,
  TOP_UP_PAYMENT_REDIRECT_ROUTE,
  MERCHANT_QR_FOR_CREW,
} from 'shared/constants';

export enum VisibleElement {
  MenuButton = 'MenuButton',
  AltHeader = 'AltHeader',
}

export enum HiddenElement {
  Header = 'Header',
}

export type RouteConfig = {
  path: string;
  component: FC<unknown>;
  exact?: true;
  params?: string[];
  show?: VisibleElement[];
  hide?: HiddenElement[];
  permission?: Permission;
};

export const addMerchantPrefix = (route: string) => `/merchant${route}`;
export const addCrewPrefix = (route: string) => `/crew${route}`;

export const merchantRoutes: RouteConfig[] = [
  { path: LOGIN_ROUTE, component: Auth.MerchantLogin },
  { path: SET_PASSWORD_ROUTE, component: Auth.ResetPassword },
  { path: FORGOT_PASSWORD_ROUTE, component: Auth.ForgotPassword },
  {
    path: MERCHANT_QR_FOR_CREW,
    component: Home.MerchantQrForCrew,
    permission: 'crew.activeDevices.add',
  },
  {
    path: HOME_ROUTE,
    component: Home.MerchantHome,
    exact: true,
    show: [VisibleElement.MenuButton],
  },
  {
    path: PROFILE_ROUTE,
    component: Home.MerchantProfile,
    show: [VisibleElement.MenuButton],
  },
  {
    path: PRODUCT_OVERVIEW_ROUTE,
    component: Booth.MerchantProductOverview,
    exact: true,
    show: [VisibleElement.MenuButton],
  },
  {
    path: ORDER_DETAILS_ROUTE,
    component: Order.MerchantOrderDetails,
    exact: true,
    params: ['orderId'],
  },
  {
    path: ORDER_HISTORY_ROUTE,
    component: Order.MerchantOrderHistory,
    exact: true,
    show: [VisibleElement.MenuButton],
  },
  {
    path: SCAN_ORDER_RESULT_ROUTE,
    component: Order.ScanOrderResult,
    params: ['orderId'],
  },
  { path: QR_SCAN_ROUTE, component: Home.QrScan },
  {
    path: BOOTH_MENU_ROUTE,
    component: Booth.BoothMenu,
    exact: true,
    show: [VisibleElement.MenuButton],
  },
  { path: PAYMENT_METHOD_SELECTION_ROUTE, component: Order.PaymentSelection },
  { path: PAYMENT_REDIRECT_ROUTE, component: Payments.MerchantPayment },
  {
    path: EVENT_SELECTION_ROUTE,
    component: AppContext.EventSelection,
    exact: true,
  },
];

export const visitorRoutes: RouteConfig[] = [
  {
    path: EVENT_SELECTION_ROUTE,
    component: AppContext.EventSelection,
    exact: true,
  },
  { path: LOGIN_ROUTE, component: Auth.Login, exact: true },
  { path: REGISTER_ROUTE, component: Auth.Register },
  { path: FORGOT_PASSWORD_ROUTE, component: Auth.ForgotPassword },
  { path: SET_PASSWORD_ROUTE, component: Auth.ResetPassword },
  {
    path: HOME_ROUTE,
    component: Home.VisitorHome,
    exact: true,
    show: [VisibleElement.MenuButton],
  },
  { path: PROFILE_ROUTE, component: Home.Profile },
  { path: CHANGE_PROFILE_DATA_ROUTE, component: Home.ChangeProfileData },
  { path: QR_SCAN_ROUTE, component: Home.QrScan },
  { path: CHANGE_PASSWORD_ROUTE, component: Home.ChangePassword },
  { path: MY_QR_ROUTE, component: Home.MyQr },
  { path: MY_CREDITS_ROUTE, component: Home.MyCredits },
  {
    path: BOOTH_SELECTION_ROUTE,
    component: Booth.BoothSelection,
    params: ['id'],
    show: [VisibleElement.MenuButton],
  },
  {
    path: BOOTH_MENU_ROUTE,
    component: Booth.BoothMenu,
    params: ['boothTypeNumber'],
    exact: true,
    show: [VisibleElement.MenuButton],
  },
  { path: ORDER_BY_BOOTH_NUMBER_ROUTE, component: Booth.OrderByBoothNumber },
  {
    path: BOOTH_INFO_ROUTE,
    component: Booth.BoothTypeInfo,
    params: ['boothNumber'],
    show: [VisibleElement.MenuButton],
  },
  {
    path: CHECKOUT_ROUTE,
    component: Order.Checkout,
    show: [VisibleElement.MenuButton],
  },
  {
    path: ORDER_DETAILS_ROUTE,
    component: Order.OrderDetails,
    params: ['orderId'],
  },
  {
    path: ORDER_HISTORY_ROUTE,
    component: Order.OrderHistory,
    exact: true,
  },
  {
    path: PAYMENT_METHOD_SELECTION_ROUTE,
    component: Order.PaymentSelection,
    params: ['source?'],
  },
  { path: TOP_UP_WALLET_ROUTE, component: Payments.TopUpWallet },
  {
    path: PAYMENT_REDIRECT_ROUTE,
    component: Payments.Payment,
    params: ['orderUuid?'],
  },
  {
    path: TOP_UP_PAYMENT_REDIRECT_ROUTE,
    component: Payments.ToPUpPayment,
    show: [VisibleElement.AltHeader],
  },
];

export const crewRoutes: RouteConfig[] = [
  { path: CREW_ACTIVATE_DEVICE_ROUTE, component: Home.QrScan, exact: true },
  { path: CREW_HOME_ROUTE, component: Home.CrewHome, exact: true },
  {
    path: SCAN_ORDER_RESULT_ROUTE,
    component: Order.ScanOrderResult,
    params: ['orderId'],
  },
  { path: QR_SCAN_ROUTE, component: Home.QrScan },
  {
    path: BOOTH_MENU_ROUTE,
    component: Booth.BoothMenu,
    exact: true,
    show: [VisibleElement.MenuButton],
  },
  { path: PAYMENT_METHOD_SELECTION_ROUTE, component: Order.PaymentSelection },
  { path: PAYMENT_REDIRECT_ROUTE, component: Payments.MerchantPayment },
];

export const authRoutes: string[] = [
  LOGIN_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  SET_PASSWORD_ROUTE,
  REGISTER_ROUTE,
  MERCHANT_LOGIN_ROUTE,
  MERCHANT_SET_PASSWORD_ROUTE,
  MERCHANT_FORGOT_PASSWORD_ROUTE,
  CREW_HOME_ROUTE,
  CREW_ACTIVATE_DEVICE_ROUTE,
];
