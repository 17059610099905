import { createStyles, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Order } from 'shared/types';

const useStyles = makeStyles<
  Theme,
  {
    backgroundColor: React.CSSProperties['color'];
    textColor: React.CSSProperties['color'];
    noCard: boolean | undefined;
  }
>((theme) =>
  createStyles({
    orderSummary: {
      boxSizing: 'border-box',
      width: '100%',
      borderRadius: ({ noCard }) => (noCard ? 0 : '22px'),
      padding: ({ noCard }) => (noCard ? '20px 0' : '20px 25px 20px 9px'),
      marginBottom: ({ noCard }) => (noCard ? 0 : '16px'),
      backgroundColor: ({ backgroundColor, noCard }) =>
        noCard ? 'transparent' : backgroundColor,
    },
    orderSummaryItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      fontSize: '17px',
      color: ({ textColor }) => textColor,
      '&:not(:last-child)': {
        paddingBottom: '8px',
      },
    },
    orderSummaryQuantityText: {
      textAlign: 'right',
      marginRight: '20px',
      minWidth: '45px',
      color: ({ textColor }) => textColor,
    },
    notAvailable: {
      '& > span': {
        color: theme.palette.error.main,
      },
    },
    soldOut: {
      fontSize: '0.75rem',
      position: 'relative',
      top: '-0.25rem',
      left: '0.25rem',
    },
  }),
);

type Props = {
  className?: string;
  backgroundColor: React.CSSProperties['color'];
  textColor: React.CSSProperties['color'];
  items: Order['products'];
  noCard?: boolean;
  noAvailability?: boolean;
};

const OrderSummary: FC<Props> = ({
  className,
  items,
  backgroundColor,
  textColor,
  noCard,
  noAvailability,
}) => {
  const classes = useStyles({ backgroundColor, textColor, noCard });
  const { t } = useTranslation();

  const renderItem = (item: typeof items[0]) => (
    <div
      key={item.uuid}
      className={clsx(
        classes.orderSummaryItem,
        !item.available && !noAvailability && classes.notAvailable,
      )}
    >
      <span className={classes.orderSummaryQuantityText}>
        {item.quantity} x
      </span>
      <span>{item.name}</span>
      {!item.available && !noAvailability && (
        <span className={classes.soldOut}>{t('order.summary.soldOut')}</span>
      )}
    </div>
  );

  return (
    <div className={clsx(classes.orderSummary, className)}>
      {items.map(renderItem)}
    </div>
  );
};

export default OrderSummary;
