import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { TFunction } from 'i18next';
import { FC } from 'react';
import { Dialog } from '@material-ui/core';
import { Button } from 'shared/components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      borderRadius: '25px',
      padding: '37px 22px 24px 22px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    dialogTitle: {
      width: '240px',
      fontSize: '21px',
      fontWeight: 800,
      lineHeight: '28px',
      color: theme.customPalette.textPrimary,
      marginBottom: '12px',
      textAlign: 'center',
    },
    dialogDescription: {
      maxWidth: '240px',
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '19px',
      color: theme.customPalette.accentText,
      marginBottom: '20px',
      textAlign: 'center',
    },
    button: {
      maxWidth: '240px',
      marginTop: '20px',
    },
  }),
);

type Props = {
  open: boolean;
  t: TFunction;
  onRetry: () => void;
  onCancel: () => void;
  description: string;
};

const FailureDialog: FC<Props> = ({
  description,
  onRetry,
  onCancel,
  open,
  t,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      PaperProps={{ className: classes.container }}
      maxWidth="xs"
    >
      <div className={classes.dialogTitle}>
        {t('qrScan.failureDialog.title')}
      </div>
      <div className={classes.dialogDescription}>{description}</div>

      <Button
        text={t('common.retry')}
        primary
        className={classes.button}
        onClick={onRetry}
      />
      <Button
        text={t('common.cancel')}
        className={classes.button}
        onClick={onCancel}
      />
    </Dialog>
  );
};

export default FailureDialog;
