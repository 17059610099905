import { FC, useMemo } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Info } from '@material-ui/icons';
import { BOOTH_INFO_ROUTE } from 'shared/constants';
import { getImageUrl, useRoleNavigation } from 'shared/helpers';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import BoothIcon from 'shared/assets/booth-3.svg';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '27px 18px 23px 18px',
      color: 'white',
    },
    headerImage: {
      width: '50px',
      height: '50px',
      filter: 'invert(1)',
      marginRight: '12px',
    },
    headerText: {
      fontSize: '21px',
      fontWeight: 700,
      color: 'white',
      letterSpacing: '1px',
    },
    boothNumberInfoText: {
      fontSize: '16px',
      lineHeight: '22px',
      fontWeight: 700,
      color: '#d3c9ed',
      marginRight: '8px',
    },
    centeredRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    boothInfoText: {
      fontSize: '16px',
      lineHeight: '24px',
      color: theme.customPalette.accentGreen,
      fontWeight: 600,
      marginLeft: '4px',
    },
    boothNumberText: {
      fontSize: '19px',
      lineHeight: '24px',
      fontWeight: 600,
      color: '#2c7ec1',
      display: 'block',
    },
    hidden: {
      opacity: 0,
    },
  }),
);

type Props = {
  name: string;
  categoryId?: number;
  number?: number;
  showInfo?: boolean;
  loading?: boolean;
};

const BoothHeader: FC<Props> = ({
  name,
  categoryId,
  number,
  showInfo,
  loading,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { push } = useRoleNavigation();
  const categories = useSelector((state: RootState) => state.categories);

  const selectedCategory = useMemo(
    () => categories.data.find((item) => item.id === categoryId),
    [categories, categoryId],
  );

  const handleInfoClick = () => {
    push(`${BOOTH_INFO_ROUTE}/${number}`);
  };

  return (
    <div className={classes.headerContainer}>
      <img
        className={clsx(classes.headerImage, loading && classes.hidden)}
        src={selectedCategory ? getImageUrl(selectedCategory?.icon) : BoothIcon}
      />
      <div>
        <span className={classes.headerText}>{name}</span>
        {showInfo && typeof number === 'number' && (
          <div className={classes.centeredRow} onClick={handleInfoClick}>
            <span className={classes.boothNumberInfoText}>
              {t('booth.boothHeaderNumberInfoText', { number })}
            </span>
            <Info className={classes.boothInfoText} />
            <a className={classes.boothInfoText}>{t('booth.info')}</a>
          </div>
        )}
        {!showInfo && typeof number === 'number' && (
          <span className={classes.boothNumberText}>
            {t('booth.boothHeaderNumberText', { number })}
          </span>
        )}
      </div>
    </div>
  );
};

export default BoothHeader;
