import {
  ChangeEventHandler,
  FC,
  MouseEventHandler,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { useTranslation, Trans } from 'react-i18next';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { SvgIcon } from '@material-ui/core';
import { parseISO, set, sub, format, formatISO } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { postRegister } from 'core/requests';
import { messageSlice } from 'core/store';
import { CardLayout } from 'shared/layouts';
import { Input, Button } from 'shared/components';
import { LOGIN_ROUTE } from 'shared/constants';
import { getPasswordRules } from 'shared/helpers';
import { useRegisterStyles } from 'shared/styles';
import { ReactComponent as CheckIcon } from 'assets/check.svg';
import { ReactComponent as CalendarIcon } from 'assets/calendar.svg';
import { ReactComponent as EmailIcon } from 'assets/email.svg';
import { ReactComponent as KeyIcon } from 'assets/key.svg';
import { ReactComponent as UserIcon } from 'assets/user.svg';

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  dateOfBirth: '',
  password: '',
  confirmPassword: '',
};

const initialFocusedDate = sub(set(new Date(), { month: 0, date: 1 }), {
  years: 25,
});

const Register: FC = () => {
  const classes = useRegisterStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const dateInputRef = useRef<HTMLInputElement>();

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        firstName: Yup.string().required(t('validation.required')),
        lastName: Yup.string().required(t('validation.required')),
        email: Yup.string()
          .required(t('validation.required'))
          .email(t('validation.invalidEmail')),
        dateOfBirth: Yup.string().required(t('validation.required')),
        password: getPasswordRules(t),
        confirmPassword: Yup.string()
          .oneOf([Yup.ref('password')], t('validation.passwordsMustMatch'))
          .required(t('validation.required')),
      }),
    [i18n.language],
  );

  const handleBackClick = () => {
    history.goBack();
  };

  const handleFormSubmit = async (
    values: typeof initialValues,
    { setErrors }: FormikHelpers<typeof initialValues>,
  ) => {
    try {
      const { dateOfBirth, ...data } = values;
      await postRegister({
        ...data,
        dateOfBirth: format(parseISO(dateOfBirth), 'yyyy-MM-dd'),
        terms: true,
        language: i18n.language.toUpperCase(),
      });
      dispatch(
        messageSlice.actions.showMessage({
          text: t('message.registrationSuccessful'),
          type: 'success',
        }),
      );
      history.push(LOGIN_ROUTE);
    } catch (error) {
      const responseErrors =
        (error as { response: { data: { errors: { [key: string]: string } } } })
          ?.response?.data?.errors || {};
      setErrors(responseErrors);
      dispatch(
        messageSlice.actions.showMessage({
          text: t('message.registrationError'),
          type: 'error',
        }),
      );
    }
  };

  const handleDateClick: MouseEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();
    setIsDatePickerOpen(true);
    dateInputRef.current?.blur();
  };

  const handleDatePickerClose = () => {
    setIsDatePickerOpen(false);
    dateInputRef.current?.blur();
  };

  return (
    <CardLayout
      title={t('login.screenTitle')}
      contentTitle={t('register.title')}
      contentDescription={t('register.description')}
      onBackClick={handleBackClick}
      backgroundColor="white"
      maxContentWidth={340}
      centered
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {({
          values,
          touched,
          errors,
          setFieldValue,
          setFieldTouched,
          handleSubmit,
          handleChange,
          handleBlur,
        }) => {
          const getFieldError = (field: keyof typeof initialValues) => {
            const isTouched = touched[field] || undefined;
            return isTouched && errors[field];
          };

          const handleFormFieldChange: ChangeEventHandler<HTMLInputElement> = (
            event,
          ) => {
            const fieldName = event.target.name as keyof typeof initialValues;
            if (touched[fieldName]) {
              handleChange(event);
            } else {
              handleChange(event);
              setFieldTouched(fieldName);
            }
          };

          return (
            <div className={classes.container}>
              <Input
                name="firstName"
                icon={
                  <SvgIcon component={UserIcon} className={classes.inputIcon} />
                }
                placeholder={t('register.firstNamePlaceholder')}
                className={classes.input}
                onChange={handleFormFieldChange}
                onBlur={handleBlur}
                error={getFieldError('firstName')}
              />
              <Input
                name="lastName"
                icon={
                  <SvgIcon component={UserIcon} className={classes.inputIcon} />
                }
                placeholder={t('register.lastNamePlaceholder')}
                className={classes.input}
                onChange={handleFormFieldChange}
                onBlur={handleBlur}
                error={getFieldError('lastName')}
              />
              <Input
                name="email"
                icon={
                  <SvgIcon
                    component={EmailIcon}
                    className={classes.inputIcon}
                  />
                }
                placeholder={t('register.emailPlaceholder')}
                className={classes.input}
                onChange={handleFormFieldChange}
                onBlur={handleBlur}
                error={getFieldError('email')}
              />
              <span className={classes.dobText}>
                {t('register.dobSectionTitle')}
              </span>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className={classes.datePicker}
                  views={['year', 'month', 'date']}
                  open={isDatePickerOpen}
                  openTo="year"
                  margin="normal"
                  id="date-picker-dialog"
                  format="yyyy-MM-dd"
                  initialFocusedDate={initialFocusedDate}
                  maxDate={Date.now()}
                  value={values.dateOfBirth || null}
                  onChange={(_, value) => {
                    if (typeof value === 'string') {
                      const date = new Date(value);
                      setFieldValue('dateOfBirth', formatISO(date));
                    }
                  }}
                  onClose={handleDatePickerClose}
                />
              </MuiPickersUtilsProvider>
              <Input
                inputRef={dateInputRef}
                icon={
                  <SvgIcon
                    component={CalendarIcon}
                    className={classes.inputIcon}
                  />
                }
                placeholder="DD / MM / YYYY"
                className={classes.input}
                error={getFieldError('dateOfBirth')}
                value={
                  values.dateOfBirth &&
                  format(parseISO(values.dateOfBirth), 'dd / MM / yyyy')
                }
                onClick={handleDateClick}
              />
              <Input
                name="password"
                icon={
                  <SvgIcon component={KeyIcon} className={classes.inputIcon} />
                }
                placeholder={t('register.passwordPlaceholder')}
                className={classes.input}
                onChange={handleFormFieldChange}
                onBlur={handleBlur}
                error={getFieldError('password')}
                secure
              />
              <Input
                name="confirmPassword"
                icon={
                  <SvgIcon component={KeyIcon} className={classes.inputIcon} />
                }
                placeholder={t('register.confirmPasswordPlaceholder')}
                className={classes.input}
                onChange={handleFormFieldChange}
                onBlur={handleBlur}
                error={getFieldError('confirmPassword')}
                secure
              />
              <div className={classes.tosContainer}>
                <SvgIcon component={CheckIcon} className={classes.checkIcon} />
                <Trans
                  className={classes.tosText}
                  i18nKey="register.consentText"
                  parent="span"
                  values={{ tos: t('register.tosLinkText') }}
                  components={[<a key="tosLink" className={classes.tosLink} />]}
                />
              </div>
              <Button
                className={classes.registerButton}
                text={t('register.registerButtonText')}
                primary
                onClick={handleSubmit}
              />
            </div>
          );
        }}
      </Formik>
    </CardLayout>
  );
};

export default Register;
