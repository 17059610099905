import { FC } from 'react';
import { SvgIcon } from '@material-ui/core';
import { AccessTime, ExpandMore } from '@material-ui/icons';
import { ReactComponent as CalendarIcon } from 'assets/calendar.svg';
import useStyles from './useStyles';

type Props = {
  showDate?: boolean;
  text: string;
  onClick: () => void;
};

const DateTimeSelect: FC<Props> = ({ showDate, text, onClick }) => {
  const classes = useStyles();
  return (
    <div onClick={onClick} className={classes.dateTimeSelectContainer}>
      <div className={classes.dateTimeSelectLeft}>
        {showDate ? (
          <SvgIcon className={classes.dateTimeIcon} component={CalendarIcon} />
        ) : (
          <AccessTime className={classes.dateTimeIcon} />
        )}
        <span className={classes.dateTimeText}>{text}</span>
      </div>
      <ExpandMore className={classes.dateTimeRightIcon} />
    </div>
  );
};

export default DateTimeSelect;
