import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles<
  Theme,
  {
    textColor: React.CSSProperties['color'];
  }
>((theme) =>
  createStyles({
    orderSummary: {
      padding: '32px',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      maxHeight: '100%',
      flexWrap: 'wrap',
      boxSizing: 'border-box',
    },
    itemContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    orderSummaryItem: {
      boxSizing: 'border-box',
      width: '100%',
      minWidth: '220px',
      maxWidth: '460px',
      margin: '8px',
      padding: '14px 14px 14px 9px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: '12px',
      fontSize: '17px',
      color: ({ textColor }) => textColor,
      backgroundColor: '#e8faf1',
    },
    orderSummaryQuantityText: {
      textAlign: 'right',
      marginRight: '20px',
      minWidth: '45px',
      color: ({ textColor }) => textColor,
    },
    notAvailable: {
      '& > span': {
        color: theme.palette.error.main,
      },
    },
    soldOut: {
      fontSize: '0.75rem',
      position: 'relative',
      top: '-0.25rem',
      left: '0.25rem',
    },
  }),
);

export default useStyles;
