import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'shared/components';
import { HOME_ROUTE } from 'shared/constants';
import { useRoleNavigation } from 'shared/helpers';
import useStyles from './useStyles';

const Footer: FC = () => {
  const classes = useStyles({});
  const { t } = useTranslation();
  const { push } = useRoleNavigation();

  const handleClickPlaceNewOrder = () => {
    push(HOME_ROUTE);
  };

  return (
    <div className={classes.footer}>
      <Button
        primary
        text={t('common.placeNewOrder')}
        onClick={handleClickPlaceNewOrder}
      />
    </div>
  );
};

export default Footer;
