import { FC, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { matchPath, useHistory } from 'react-router';
import {
  Fab,
  Select,
  MenuItem,
  SelectProps,
  Badge,
  SvgIcon,
} from '@material-ui/core';
import { Close, Menu } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { currentEventSlice, RootState } from 'core/store';
import { authToken, useRoleNavigation } from 'shared/helpers';
import { ReactComponent as CartIcon } from 'assets/cart.svg';
import { ProtectedRoute } from 'features/Auth';
import Other from 'features/Other';
import {
  EVENT_SELECTION_ROUTE,
  LOGIN_ROUTE,
  CHECKOUT_ROUTE,
  MERCHANT_LOGIN_ROUTE,
  CREW_HOME_ROUTE,
} from 'shared/constants';
import logoIcon from 'assets/logo.svg';
import altLogoIcon from 'assets/altLogo.svg';
import { languages } from 'shared/constants';
import AppMenu from './AppMenu';
import Message from './Message';
import SpinnerOverlay from './SpinnerOverlay';
import useStyles from './useStyles';
import {
  RouteConfig,
  addCrewPrefix,
  addMerchantPrefix,
  merchantRoutes,
  visitorRoutes,
  authRoutes,
  VisibleElement,
  HiddenElement,
  crewRoutes,
} from './config';

const Routes: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    push,
    location: { pathname },
  } = useHistory();
  const { i18n } = useTranslation();
  const {
    currentEvent,
    currentEventLoading,
    currentUser,
    currentUserLoading,
    productsLength,
    isLoading,
  } = useSelector((state: RootState) => ({
    currentEvent: state.currentEvent.data,
    currentEventLoading: state.currentEvent.loading,
    currentUser: state.currentUser.data,
    currentUserLoading: state.currentUser.loading,
    productsLength: Object.values(state.orderedProducts.products || {}).filter(
      (product) => product.quantity,
    ).length,
    isLoading: state.loader.loading,
  }));
  const roleNavigation = useRoleNavigation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [areIntroScreensVisible, setIntroScreensVisibility] = useState(
    () => !localStorage.getItem('introScreens'),
  );
  const userRoles = authToken.getUserRoles();
  const isMerchant = userRoles && userRoles.includes('ROLE_MERCHANT');

  useEffect(() => {
    if (authToken.getToken()) {
      dispatch(currentEventSlice.actions.getCurrentEventRequest());
    }
  }, []);

  const redirectRole = () => {
    if (
      currentUser &&
      pathname.includes('/merchant') &&
      !currentUser.role.includes('ROLE_MERCHANT')
    ) {
      push(pathname.replace('/merchant', ''));
    }

    if (
      currentUser &&
      !pathname.includes('/merchant') &&
      currentUser.role.includes('ROLE_MERCHANT')
    ) {
      push(addMerchantPrefix(pathname));
    }

    if (
      currentUser &&
      !pathname.includes('/crew') &&
      currentUser.role.includes('ROLE_CREW_MEMBER')
    ) {
      push(addCrewPrefix(pathname));
    }

    if (!authRoutes.includes(pathname) && !authToken.getToken()) {
      let redirectRoute = LOGIN_ROUTE;
      if (pathname.includes('/merchant')) {
        redirectRoute = MERCHANT_LOGIN_ROUTE;
      } else if (pathname.includes('/crew')) {
        redirectRoute = CREW_HOME_ROUTE;
      }
      push(redirectRoute);
    }
  };

  useEffect(() => {
    redirectRole();
  }, [pathname, currentUser]);

  useEffect(() => {
    if (isMenuOpen || isLoading) {
      document.body.setAttribute('style', 'overflow: hidden');
    } else {
      document.body.removeAttribute('style');
    }
  }, [isMenuOpen, isLoading]);

  const handleMenuButtonClick = () => {
    setIsMenuOpen((current) => !current);
  };

  const handleDismissIntroScreens = () => {
    setIntroScreensVisibility(false);
    localStorage.setItem('introScreens', '1');
  };

  const handleLanguageSelectChange: SelectProps['onChange'] = (event) => {
    if (typeof event.target.value === 'string') {
      i18n.changeLanguage(event.target.value);
    }
  };

  const hideMenu = () => {
    setIsMenuOpen(false);
  };

  const handleCartClick = () => {
    roleNavigation.push(CHECKOUT_ROUTE);
  };

  const renderLanguageItem = (item: typeof languages[0]) => (
    <MenuItem key={item.code} value={item.code}>
      {item.name}
    </MenuItem>
  );

  const getPath = (path: string, params?: string[]) => {
    let result = path;
    if (pathname.includes('/merchant')) {
      result = addMerchantPrefix(path);
    }
    if (pathname.includes('/crew') && !path.includes('/crew')) {
      result = path === '/' ? '/crew' : addCrewPrefix(path);
    }
    if (params) {
      return `${result}/${params.map((param) => `:${param}`).join('/')}`;
    }

    return result;
  };

  const getRouteGroup = (): 'merchant' | 'crew' | 'visitor' => {
    if (pathname.includes('/merchant')) {
      return 'merchant';
    }
    if (pathname.includes('/crew')) {
      return 'crew';
    }
    return 'visitor';
  };

  const currentRoute = [
    ...merchantRoutes,
    ...visitorRoutes,
    ...crewRoutes,
  ].find((route) => {
    const isAuthRoute = authRoutes.includes(pathname);
    const routePath =
      pathname.includes('/crew') && isAuthRoute
        ? route.path
        : getPath(route.path, route.params);
    return matchPath(pathname, { ...route, path: routePath })?.isExact;
  });
  const isAltHeader = currentRoute?.show?.includes(VisibleElement.AltHeader);
  const isMenuButtonVisible = currentRoute?.show?.includes(
    VisibleElement.MenuButton,
  );
  const isHeaderVisible =
    !currentRoute?.hide?.includes(HiddenElement.Header) &&
    !pathname.includes('/crew');
  const routeGroup = getRouteGroup();

  const renderRoute = ({ path, params, permission, ...rest }: RouteConfig) =>
    permission ? (
      <ProtectedRoute
        key={path}
        {...rest}
        path={getPath(path, params)}
        permission={permission}
      />
    ) : (
      <Route key={path} {...rest} path={getPath(path, params)} />
    );

  const getRouteFilteringPredicate = (role: string) => (route: RouteConfig) =>
    authRoutes.includes(route.path) ||
    (authToken.getToken() && route.path === EVENT_SELECTION_ROUTE) ||
    (currentEvent?.active && Boolean(currentUser?.role?.includes(role)));

  if (areIntroScreensVisible) {
    return <Other.IntroScreens onDismiss={handleDismissIntroScreens} />;
  }

  const eventSelectionRoute = isMerchant
    ? `/merchant${EVENT_SELECTION_ROUTE}`
    : EVENT_SELECTION_ROUTE;

  return (
    <div
      className={classes.container}
      style={isMenuOpen ? { overflow: 'hidden' } : undefined}
    >
      {isHeaderVisible && (
        <div
          className={
            isAltHeader ? classes.altHeaderContainer : classes.headerContainer
          }
        >
          <img
            src={isAltHeader ? altLogoIcon : logoIcon}
            className={classes.logoIcon}
          />
          {isMenuButtonVisible && (
            <div className={classes.headerRightContainer}>
              {!pathname.includes('merchant') && !isMenuOpen && (
                <Badge
                  badgeContent={productsLength}
                  color="primary"
                  className={classes.headerBadge}
                  onClick={handleCartClick}
                >
                  <SvgIcon
                    component={CartIcon}
                    viewBox="0 0 43 40"
                    className={classes.cartIcon}
                  />
                </Badge>
              )}
              <Fab
                className={classes.button}
                size="small"
                color="primary"
                onClick={handleMenuButtonClick}
              >
                {isMenuOpen ? (
                  <Close className={classes.menuIcon} />
                ) : (
                  <Menu className={classes.menuIcon} />
                )}
              </Fab>
            </div>
          )}
          {authRoutes.includes(pathname) && (
            <Select
              labelId="language-select-label"
              id="language-select"
              value={i18n.language}
              className={classes.select}
              disableUnderline
              classes={{
                icon: classes.select,
              }}
              onChange={handleLanguageSelectChange}
            >
              {languages.map(renderLanguageItem)}
            </Select>
          )}
        </div>
      )}
      <Message />
      <AppMenu
        isOpen={isMenuOpen}
        hideMenu={hideMenu}
        isMerchant={Boolean(currentUser?.role?.includes('ROLE_MERCHANT'))}
      />
      <Switch>
        {routeGroup === 'merchant' &&
          merchantRoutes
            .filter(getRouteFilteringPredicate('ROLE_MERCHANT'))
            .map(renderRoute)}
        {routeGroup === 'crew' &&
          crewRoutes
            .filter(getRouteFilteringPredicate('ROLE_CREW_MEMBER'))
            .map(renderRoute)}
        {routeGroup === 'visitor' &&
          visitorRoutes
            .filter(getRouteFilteringPredicate('ROLE_VISITOR'))
            .map(renderRoute)}
        {(routeGroup === 'visitor' || routeGroup === 'merchant') &&
          !currentEvent?.active &&
          !currentEventLoading && <Redirect to={eventSelectionRoute} />}
        {(currentEventLoading || currentUserLoading) && <Route />}
        <Route component={Other.NotFound} />
      </Switch>
      <SpinnerOverlay
        isAuthRoute={
          Boolean(currentRoute) && authRoutes.includes(currentRoute!.path)
        }
      />
    </div>
  );
};

export default Routes;
