import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      backgroundColor: 'white',
      borderTopLeftRadius: '25px',
      borderTopRightRadius: '25px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '30px 25px 30px 25px',
      boxShadow: '0 0 11px -2px rgba(0,0,0,0.14)',
    },
    basketEmptyContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    basketEmptyIcon: {
      width: '90px',
      height: '90px',
      marginTop: '42px',
    },
    basketEmptyText: {
      marginTop: '13px',
      fontSize: '16px',
      lineHeight: '22px',
      fontWeight: 600,
      color: theme.customPalette.labelGray,
    },
    addMoreItemsContainer: {
      padding: '10px 18px 30px 18px',
      fontSize: '0.75rem',
    },
    multipleLocations: {
      width: '100%',
      paddingTop: '6px',
    },
  }),
);

export default useStyles;
