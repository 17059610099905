import clsx from 'clsx';
import { FC } from 'react';
import useStyles from './useStyles';

type Props = {
  label: string;
  text: string;
  className?: string;
};

const LabeledInfo: FC<Props> = ({ label, text, className }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.container, className)}>
      <span className={classes.label}>{label}</span>
      <span className={classes.text}>{text}</span>
    </div>
  );
};

export default LabeledInfo;
