import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    conatiner: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    scanContainer: {
      width: '100%',
      maxWidth: 'calc(100vh - 60px)',
    },
    scanSubcontainer: {
      maxWidth: '500px',
      margin: '0 auto',
      padding: '12px',
      border: `3px solid ${theme.customPalette.accentGreen}`,
      borderRadius: '5px',
    },
    footer: {
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '21px',
    },
  }),
);

export default useStyles;
