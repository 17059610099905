import { createStyles, makeStyles, SvgIcon } from '@material-ui/core';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as QRCodeSvg } from 'assets/qr-code.svg';
import { Button, Spacer } from 'shared/components';
import { BOOTH_MENU_ROUTE, QR_SCAN_ROUTE } from 'shared/constants';
import { useRoleNavigation } from 'shared/helpers';

const useStyles = makeStyles(() =>
  createStyles({
    footerContainer: {
      display: 'flex',
      flexDirection: 'row',
      padding: '16px',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  }),
);

const MerchantFooter: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { push } = useRoleNavigation();

  const handleClickPlaceOrder = () => {
    push(BOOTH_MENU_ROUTE);
  };

  const handleClickScan = () => {
    push(QR_SCAN_ROUTE);
  };

  return (
    <div className={classes.footerContainer}>
      <Button
        text={t('merchant.productOverview.placeOrder')}
        color="white"
        rounded
        onClick={handleClickPlaceOrder}
      />
      <Spacer />
      <Button
        text={t('merchant.productOverview.scanCode')}
        primary
        icon={<SvgIcon component={QRCodeSvg} />}
        onClick={handleClickScan}
      />
    </div>
  );
};

export default MerchantFooter;
