import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    contentBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    footerContainer: {
      padding: '0 20px 10px 20px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& > *': {
        marginBottom: 20,
      },
    },
    select: {
      display: 'flex',
      margin: 0,
      maxWidth: 300,
    },
    placeholder: {
      color: '#5e7b95',
    },
  }),
);

export default useStyles;
