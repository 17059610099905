import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {
      '& h3': {
        margin: '0 0 12px 0',
        fontSize: '21px',
        fontWeight: 'bold',
      },
      '&:first-child': {
        marginBottom: 50,
      },
    },
    inlineElementsContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '& > div:first-child': {
        flex: '0 0 auto',
        width: 72,
      },
      '& > div:last-child': {
        flex: '1 1 auto',
        color: theme.customPalette.labelGray,
      },
    },
    boothIcon: {
      display: 'block',
      fontSize: '63px',
    },
    scanButton: {
      backgroundColor: theme.customPalette.background,
      color: 'red',
      '&:hover': {
        backgroundColor: '#030037',
      },
    },
    placeholder: {
      color: '#5e7b95',
    },
    select: {
      display: 'flex',
      maxWidth: 300,
    },
  }),
);

export default useStyles;
