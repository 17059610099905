import { ComponentProps, FC, ReactNode, useState } from 'react';
import {
  FilledInput,
  createStyles,
  makeStyles,
  InputAdornment,
  Icon,
  IconButton,
  Theme,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { MAX_INTERACTIVE_ELEMENT_WIDTH } from 'shared/constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      maxWidth: `${MAX_INTERACTIVE_ELEMENT_WIDTH}px`,
      width: '100%',
      marginBottom: '3px',
    },
    inputContainer: {
      display: 'flex',
      height: '44px',
      borderRadius: '12px',
      backgroundColor: theme.customPalette.lightGrayBackground,
    },
    icon: {
      color: 'gray',
      fontSize: '18px',
    },
    passwordIcon: {
      fontSize: '18px',
      color: 'lightgray',
    },
    input: {
      padding: '12px 0px 12px 8px',
      fontSize: '14px',
      fontWeight: 600,
    },
    errorTextContainer: {
      marginLeft: '8px',
      minHeight: '18px',
    },
    errorText: {
      fontSize: '13px',
      color: theme.palette.error.main,
    },
  }),
);

type Props = Omit<
  ComponentProps<typeof FilledInput>,
  'startAdornment' | 'endAdornment' | 'type' | 'error'
> & {
  icon?: ReactNode;
  secure?: boolean;
  error?: string;
  inputClassName?: string;
};

const Input: FC<Props> = ({
  icon,
  secure,
  className,
  error,
  inputClassName,
  ...props
}) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(!secure);

  const handlePasswordIconPress = () => {
    setShowPassword((current) => !current);
  };

  return (
    <div
      className={
        className ? `${classes.container} ${className}` : classes.container
      }
    >
      <FilledInput
        {...props}
        className={
          inputClassName
            ? `${classes.inputContainer} ${inputClassName}`
            : classes.inputContainer
        }
        type={showPassword ? 'text' : 'password'}
        classes={{ input: classes.input }}
        disableUnderline
        startAdornment={
          icon && (
            <InputAdornment position="start">
              <Icon className={classes.icon}>{icon}</Icon>
            </InputAdornment>
          )
        }
        endAdornment={
          secure && (
            <InputAdornment position="end">
              <IconButton
                edge="end"
                onClick={handlePasswordIconPress}
                onMouseDown={(event) => {
                  event.preventDefault();
                }}
              >
                {showPassword ? (
                  <Visibility className={classes.passwordIcon} />
                ) : (
                  <VisibilityOff className={classes.passwordIcon} />
                )}
              </IconButton>
            </InputAdornment>
          )
        }
        error={Boolean(error)}
      />
      <div className={classes.errorTextContainer}>
        <span className={classes.errorText}>{error}</span>
      </div>
    </div>
  );
};

export default Input;
