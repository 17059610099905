import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    qrCodeContainer: {
      boxSizing: 'border-box',
      width: '100%',
      maxWidth: 'calc(100vh - 60px)',
      margin: '0px auto 10px auto',
    },
    qrCodeBox: {
      maxWidth: '500px',
      margin: '0 auto',
      padding: '30px',
      borderRadius: '8px',
      backgroundColor: theme.palette.common.white,
      boxShadow: '0 0 21px -2px rgba(0,0,0,0.2)',
    },
    qrCode: {
      width: '100%',
      height: 'auto',
    },
    creditsDescriptionContainer: {
      marginTop: '38px',
    },
    creditsDescription: {
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '22px',
      color: theme.customPalette.labelGray,
    },
  }),
);

export default useStyles;
