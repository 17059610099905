import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Cancel } from '@material-ui/icons';
import useStyles from '../useStyles';

type Props = {
  isCrew: boolean;
};

const Unknown: FC<Props> = ({ isCrew }) => {
  const { t } = useTranslation();
  const classes = useStyles({ isCrew });

  return (
    <div className={classes.unknownContainer}>
      <Cancel className={classes.unknownIcon} />
      <div className={classes.iconText}>
        {t('scanOrderResult.unknownOrder')}
      </div>
      {!isCrew && (
        <div className={classes.unknownDescription}>
          {t('scanOrderResult.description.unknown')}
        </div>
      )}
    </div>
  );
};

export default Unknown;
