import { createStyles, makeStyles } from '@material-ui/core';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'shared/components';
import { HOME_ROUTE } from 'shared/constants';
import { useRoleNavigation } from 'shared/helpers';

const useStyles = makeStyles(() =>
  createStyles({
    footer: {
      display: 'flex',
      height: '100%',
    },
    buttonsContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      margin: '16px 18px',
    },
    cancelButton: {
      width: 'auto',
      minWidth: 108,
      color: 'white',
    },
  }),
);

const CrewFooter: FC = () => {
  const classes = useStyles();
  const { push } = useRoleNavigation();
  const { t } = useTranslation();

  const handleCancelClick = () => {
    push(HOME_ROUTE);
  };

  return (
    <div className={classes.footer}>
      <div className={classes.buttonsContainer}>
        <Button
          className={classes.cancelButton}
          rounded
          text={t('common.cancel')}
          onClick={handleCancelClick}
          color="black"
        />
      </div>
    </div>
  );
};

export default CrewFooter;
