import { TFunction } from 'i18next';
import * as Yup from 'yup';

const getPasswordRules = (t: TFunction) =>
  Yup.string()
    .required(t('validation.required'))
    .min(8, t('validation.tooShort'))
    .matches(/[A-Z]/, t('validation.uppercaseRequired'))
    .matches(/[a-z]/, t('validation.lowercaseRequired'))
    .matches(/[0-9]/, t('validation.numberRequired'));
// .matches(
//   /[\!\@\#\$\%\^\&\*\(\)]/,
//   t('validation.specialCharacterRequired'),
// );

export default getPasswordRules;
