import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { loaderSlice, messageSlice } from 'core/store';
import { putOrderAsPending } from 'core/requests';
import {
  Button,
  ConfirmDialog,
  MerchantFooter,
  ScreenFooter,
} from 'shared/components';
import { HOME_ROUTE } from 'shared/constants';
import { useRoleNavigation } from 'shared/helpers';
import { Order, OrderStatusExtended } from 'shared/types';
import useStyles from './useStyles';

type Props = {
  status: OrderStatusExtended;
  order?: Order;
};

const Footer: FC<Props> = ({ status, order }) => {
  const classes = useStyles({});
  const { t } = useTranslation();
  const { push } = useRoleNavigation();
  const dispatch = useDispatch();
  const [isConfirmDialogOpen, setConfirmDialogOpenness] = useState(false);

  const handleOrderCompletedClick = () => {
    push(HOME_ROUTE);
  };

  const handleRefuseOrder = async () => {
    if (order?.id) {
      dispatch(loaderSlice.actions.setLoaderState({ loading: true }));
      try {
        await putOrderAsPending(order.id);
        push(HOME_ROUTE);
      } catch (error) {
        dispatch(
          messageSlice.actions.showMessage({
            text: error.message,
            type: 'error',
          }),
        );
      } finally {
        dispatch(loaderSlice.actions.setLoaderState({ loading: false }));
        setConfirmDialogOpenness(false);
      }
    }
  };

  const handleClickConfirmOk = () => {
    handleRefuseOrder();
  };

  const handleClickConfirmCancel = () => {
    setConfirmDialogOpenness(false);
  };

  const handleClickRefuse = async () => {
    setConfirmDialogOpenness(true);
  };

  if (status === OrderStatusExtended.Paid) {
    return (
      <ScreenFooter fixed className={classes.paidFooter}>
        <span>{t('scanOrderResult.confirmationFooterTitle')}</span>
        <Button
          text={t('scanOrderResult.confirmationFooterOkText')}
          primary
          className={classes.confirmRetrievedButton}
          onClick={handleOrderCompletedClick}
        />
        <Button
          text={t('scanOrderResult.refuseButtonText')}
          onClick={handleClickRefuse}
        />
        <ConfirmDialog
          open={isConfirmDialogOpen}
          title={t('scanOrderResult.confirmRefuseOrderTitle')}
          description={t('scanOrderResult.confirmRefuseOrderDescription')}
          okText={t('common.yes')}
          cancelText={t('common.no')}
          onCancel={handleClickConfirmCancel}
          onConfirm={handleClickConfirmOk}
        />
      </ScreenFooter>
    );
  }

  return (
    <ScreenFooter fixed>
      <MerchantFooter />
    </ScreenFooter>
  );
};

export default Footer;
