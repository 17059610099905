import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { putBoothTypeStatusText } from 'core/requests';
import { BoothTypeStatusText, MerchantBoothInfo } from 'shared/types';
import EditableCard from './EditableCard';
import useStyles from './useStyles';

type Props = {
  boothInfo?: MerchantBoothInfo;
  fetchMerchantBoothInfo: () => Promise<void>;
};

const BoothTypeStatusTextSection: FC<Props> = ({
  boothInfo,
  fetchMerchantBoothInfo,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const renderCard = (data: BoothTypeStatusText) => (
    <EditableCard
      key={data.languageCode}
      languageCode={data.languageCode}
      text={data.text}
      isEditable={true}
      t={t}
      dispatch={dispatch}
      onSave={putBoothTypeStatusText}
      errorMessage={t('merchantProfile.message.editBoothTypeStatusTextFail')}
      fetchMerchantBoothInfo={fetchMerchantBoothInfo}
    />
  );

  return (
    <>
      <span className={classes.sectionTitleText}>
        {t('merchantProfile.statusText')}
      </span>
      {boothInfo?.typeStatusText?.map(renderCard)}
    </>
  );
};

export default BoothTypeStatusTextSection;
