import { all } from 'redux-saga/effects';
import { getCurrentEventSagaWatcher } from './currentEvent/sagas';
import {
  getCurrentUserSagaWatcher,
  getOnlyCurrentUserSagaWatcher,
} from './currentUser/sagas';
import { getMerchantBoothsSagaWatcher } from './merchantBooths/sagas';
import { getCategoriesSagaWatcher } from './categories/sagas';
import { getUserDraftSagaWatcher } from './orderedProducts/sagas';

function* rootSaga() {
  yield all([
    getOnlyCurrentUserSagaWatcher(),
    getCurrentUserSagaWatcher(),
    getCurrentEventSagaWatcher(),
    getMerchantBoothsSagaWatcher(),
    getCategoriesSagaWatcher(),
    getUserDraftSagaWatcher(),
  ]);
}

export default rootSaga;
