import { FC } from 'react';
import {
  makeStyles,
  createStyles,
  Switch as MuiSwitch,
  Theme,
  SwitchProps,
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    switchBase: {
      padding: '7px',
      fontSize: '24px',
      color: theme.palette.error.main,
      '&$checked': {
        color: theme.palette.primary.main,
      },
      '&$checked + $track': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    checked: {},
    track: {
      backgroundColor: theme.palette.error.main,
    },
  }),
);

const Switch: FC<SwitchProps> = (props) => {
  const classes = useStyles();

  return (
    <MuiSwitch
      classes={{
        switchBase: classes.switchBase,
        checked: classes.checked,
        track: classes.track,
      }}
      checkedIcon={<CheckCircleIcon fontSize="inherit" />}
      icon={<CancelIcon fontSize="inherit" />}
      {...props}
    />
  );
};

export default Switch;
