import { FC, useMemo, useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { CardLayout } from 'shared/layouts';
import { BoothMenuItem, ConfirmDialog, LinkButton } from 'shared/components';
import { HOME_ROUTE } from 'shared/constants';
import { OrderedProduct } from 'shared/types';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { orderedProductsSlice } from 'core/store/orderedProducts';
import basketEmptyIcon from 'assets/cart-empty.svg';
import useStyles from './useStyles';
import Footer from './Footer';

const Checkout: FC = () => {
  const classes = useStyles({});
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { products, total } = useSelector(
    (state: RootState) => state.orderedProducts,
  );
  const location = useLocation();
  const { goBack, push } = useHistory();
  const [focusedMenuItem, setFocusedMenuItem] = useState<string | null>(null);
  const [deletedItemUuid, setDeletedItemUuid] = useState<string | null>(null);
  const [errors] = useState<string[]>([]);

  const multipleLocations = useMemo(
    () =>
      Object.values(products).some(
        (item, _, arr) => item.boothTypeId !== arr[0]?.boothTypeId,
      ),
    [products],
  );

  // TODO: make a request for checking statuses of the booths of the product’s booth type and setErrors if needed
  useEffect(() => {
    if (focusedMenuItem) {
      const deletableProducts = Object.values(products).filter(
        (item) => !item.quantity,
      );
      deletableProducts.forEach((item) => {
        dispatch(orderedProductsSlice.actions.deleteOrderedProduct(item.uuid));
      });
    }
  }, [focusedMenuItem]);

  const handleItemQuantityChange = (uuid: string, increase: boolean) => () => {
    if (increase) {
      dispatch(orderedProductsSlice.actions.addOrderedProduct(products[uuid]));
    } else {
      const removedProduct = products[uuid];
      if (removedProduct?.quantity > 1) {
        dispatch(orderedProductsSlice.actions.subtractOrderedProduct(uuid));
      }

      if (removedProduct?.quantity === 1) {
        setDeletedItemUuid(uuid);
      }
    }

    setFocusedMenuItem(uuid);
  };

  const handleItemQuantityClick = (uuid: string) => () => {
    setFocusedMenuItem(uuid);
  };

  const handleItemDeleteClick = (uuid: string) => () => {
    setDeletedItemUuid(uuid);
  };

  const handleItemDeleteOkClick = () => {
    if (deletedItemUuid) {
      dispatch(
        orderedProductsSlice.actions.deleteOrderedProduct(deletedItemUuid),
      );
    }
    setDeletedItemUuid(null);
  };

  const handleItemDeleteCancelClick = () => {
    setDeletedItemUuid(null);
  };

  const handleClickAdd = () => {
    push(HOME_ROUTE);
  };

  const handleGoBack = () => {
    const query = new URLSearchParams(location.search);
    const returnDestination = query.get('return');
    if (returnDestination) {
      push(returnDestination);
    } else {
      goBack();
    }
  };

  const renderMenuItem = (item: OrderedProduct) => (
    <BoothMenuItem
      key={item.uuid}
      showDeleteButton
      showControlsWithZero
      {...item}
      quantity={item.quantity}
      focused={item.uuid === focusedMenuItem}
      onQuantityClick={handleItemQuantityClick(item.uuid)}
      onIncrease={handleItemQuantityChange(item.uuid, true)}
      onDecrease={handleItemQuantityChange(item.uuid, false)}
      onDeleteClick={handleItemDeleteClick(item.uuid)}
      helperText={
        errors.includes(item.uuid) ? t('checkout.noBoothsAreAvailable') : ''
      }
    />
  );

  const productsValues = Object.values(products);

  return (
    <CardLayout
      title={t('checkout.title')}
      contentTitle={t('checkout.contentTitle')}
      onBackClick={handleGoBack}
      footer={
        <Footer
          isBasketEmpty={!productsValues.length}
          amount={{
            value: total,
            currency: productsValues[0]?.amount?.currency || '',
          }}
          isOrderValid={!errors.length}
          multipleLocations={multipleLocations}
        />
      }
      maxContentWidth={750}
    >
      {productsValues.length ? (
        <div>{productsValues.map(renderMenuItem)}</div>
      ) : (
        <div className={classes.basketEmptyContainer}>
          <img className={classes.basketEmptyIcon} src={basketEmptyIcon} />
          <span className={classes.basketEmptyText}>
            {t('checkout.cartEmpty')}
          </span>
        </div>
      )}
      <div className={classes.addMoreItemsContainer}>
        <LinkButton text={t('common.addMoreItems')} onClick={handleClickAdd} />
      </div>
      <ConfirmDialog
        open={Boolean(deletedItemUuid)}
        title={t('checkout.confirmDeleteTitle')}
        description={t('checkout.confirmDeleteDescription')}
        okText={t('common.yes')}
        cancelText={t('common.no')}
        onCancel={handleItemDeleteCancelClick}
        onConfirm={handleItemDeleteOkClick}
      />
    </CardLayout>
  );
};

export default Checkout;
