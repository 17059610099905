import { createStyles, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import LinkButton from './LinkButton';

const useStyles = makeStyles(() =>
  createStyles({
    linkContainer: {
      fontSize: '0.75rem',
      textAlign: 'right',
    },
  }),
);

type Props = {
  className?: string;
  onClick: () => void;
};

const SeeMoreButton: FC<Props> = ({ className, onClick }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={clsx(classes.linkContainer, className)}>
      <LinkButton text={t('common.SeeMoreButton')} onClick={onClick} />
    </div>
  );
};

export default SeeMoreButton;
