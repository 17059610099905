import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useLoginStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: '22px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    forgotPasswordText: {
      fontSize: '12px',
      color: theme.palette.secondary.main,
      marginTop: '18px',
      marginBottom: '40px',
      fontWeight: 600,
    },
    input: {
      width: '100%',
    },
    button: {
      marginTop: '6px',
    },
    registerButton: {
      marginTop: '20px',
      marginBottom: '20px',
    },
    inputIcon: {
      fontSize: '18px',
      color: theme.palette.common.black,
    },
  }),
);

export default useLoginStyles;
