import { FC } from 'react';
import {
  makeStyles,
  createStyles,
  FormHelperText,
  Theme,
} from '@material-ui/core';
import { Add, Remove, Close } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { getValueWithCurrencyString } from 'shared/helpers';
import { Amount } from 'shared/types';
import { Card } from '.';

const useStyles = makeStyles<
  Theme,
  {
    focused?: boolean;
    quantity: number;
    highlightSelected?: boolean;
    showDeleteButton?: boolean;
  }
>((theme: Theme) =>
  createStyles({
    cardContainer: {
      margin: '0 0 20px 0',
    },
    card: {
      position: 'relative',
      paddingRight: ({ showDeleteButton }) => (showDeleteButton ? 120 : 70),
      backgroundColor: ({ quantity, focused, highlightSelected }) =>
        quantity === 0 || focused || !highlightSelected
          ? 'white'
          : theme.customPalette.highlightGreen,
    },
    nameText: {
      fontSize: '17px',
      lineHeight: '14px',
      color: theme.customPalette.textPrimary,
      marginBottom: '9px',
    },
    amountText: {
      fontSize: '17px',
      fontWeight: 900,
      lineHeight: '14px',
      color: theme.customPalette.textPrimary,
    },
    leftContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: '18px 4px 18px 21px',
      height: '100%',
      borderTopLeftRadius: '12px',
      borderBottomLeftRadius: '12px',
    },
    rightContainer: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: ({ quantity, highlightSelected }) =>
        quantity === 0 || !highlightSelected
          ? 'white'
          : theme.customPalette.highlightGreen,
      paddingLeft: 16,
      borderTopRightRadius: '12px',
      borderBottomRightRadius: '12px',
    },
    plusIconContainer: {
      width: '22px',
      height: '22px',
      backgroundColor: theme.customPalette.accentGreen,
      borderRadius: '50%',
    },
    plusIcon: {
      fontSize: '22px',
      color: 'white',
    },
    minusIconContainer: {
      width: '22px',
      height: '22px',
      backgroundColor: 'white',
      borderRadius: '50%',
      border: '1px solid #00cc79',
    },
    minusIcon: {
      fontSize: '22px',
      color: theme.palette.primary.main,
    },
    soldOutText: {
      fontSize: '15px',
      lineHeight: '14px',
      color: '#ff5353',
      fontWeight: 700,
    },
    quantityContainer: {
      boxSizing: 'border-box',
      height: '22px',
      minWidth: '35px',
      padding: '0 5px',
      borderRadius: '11px',
      border: (props) =>
        `1px solid ${
          props.focused ? '#d0d0d0' : theme.customPalette.accentGreen
        }`,
      backgroundColor: 'white',
      fontSize: '13px',
      lineHeight: '14px',
      fontWeight: 700,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: (props) =>
        props.focused ? '#000' : theme.customPalette.backgroundGreen,
      marginRight: '9px',
      marginLeft: '9px',
    },
    quantityWrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      paddingRight: 16,
    },
    deleteButtonContainer: {
      minWidth: '50px',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderTopRightRadius: '12px',
      borderBottomRightRadius: '12px',
      backgroundColor: '#ff545488',
    },
    deleteButtonIcon: {
      color: '#ffffffcc',
    },
  }),
);

type Props = {
  name: string;
  amount: Amount;
  quantity: number;
  available: boolean;
  focused?: boolean;
  showControlsWithZero?: boolean;
  highlightSelected?: boolean;
  showDeleteButton?: boolean;
  onQuantityClick: () => void;
  onIncrease: () => void;
  onDecrease: () => void;
  onDeleteClick?: () => void;
  helperText?: string;
};

const BoothMenuItem: FC<Props> = ({
  name,
  amount,
  quantity,
  available,
  focused,
  showControlsWithZero,
  highlightSelected,
  showDeleteButton,
  onQuantityClick,
  onIncrease,
  onDecrease,
  onDeleteClick,
  helperText,
}) => {
  const classes = useStyles({
    focused,
    quantity,
    highlightSelected,
    showDeleteButton,
  });
  const { t } = useTranslation();

  if (amount) {
    return (
      <div className={classes.cardContainer}>
        <Card className={classes.card}>
          <div className={classes.leftContainer}>
            <span className={classes.nameText}>{name}</span>
            {available ? (
              <span className={classes.amountText}>
                {getValueWithCurrencyString(amount)}
              </span>
            ) : (
              <span className={classes.soldOutText}>
                {t('booth.outOfStock')}
              </span>
            )}
          </div>
          <div className={classes.rightContainer}>
            <div className={classes.quantityWrapper}>
              {Boolean(quantity || showControlsWithZero) &&
                (focused ? (
                  <>
                    <div
                      className={classes.minusIconContainer}
                      onClick={onDecrease}
                    >
                      <Remove className={classes.minusIcon} />
                    </div>
                    <div
                      onClick={onQuantityClick}
                      className={classes.quantityContainer}
                    >
                      {quantity}
                    </div>
                    <div
                      className={classes.plusIconContainer}
                      onClick={onIncrease}
                    >
                      <Add className={classes.plusIcon} />
                    </div>
                  </>
                ) : (
                  <div
                    onClick={onQuantityClick}
                    className={classes.quantityContainer}
                  >
                    {quantity}
                  </div>
                ))}
              {!quantity && available && !showControlsWithZero && (
                <div className={classes.plusIconContainer} onClick={onIncrease}>
                  <Add className={classes.plusIcon} />
                </div>
              )}
            </div>
            {showDeleteButton && (
              <div
                className={classes.deleteButtonContainer}
                onClick={onDeleteClick}
              >
                <Close className={classes.deleteButtonIcon} />
              </div>
            )}
          </div>
        </Card>
        {helperText && <FormHelperText error>{helperText}</FormHelperText>}
      </div>
    );
  }

  return null;
};

export default BoothMenuItem;
