import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getUserIdentityToken } from 'core/requests';
import { loaderSlice, messageSlice, RootState } from 'core/store';
import { CardLayout } from 'shared/layouts';
import { CreditCount, QrCode } from 'shared/components';
import { QrCodeType } from 'shared/types';
import Footer from './Footer';
import useStyles from './useStyles';

const MyQr: FC = () => {
  const classes = useStyles();
  const { goBack } = useHistory();
  const { t } = useTranslation();
  const { currentUser, currency } = useSelector((state: RootState) => ({
    currentUser: state.currentUser.data,
    currency: state.currentEvent.data?.currency || '',
  }));
  const [qrData, setQrData] = useState<string | null>(null);
  const [seconds, setSeconds] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      (async () => {
        dispatch(loaderSlice.actions.setLoaderState({ loading: true }));
        try {
          const {
            data: { data },
          } = await getUserIdentityToken();
          setQrData(data.token);
        } catch (error) {
          dispatch(
            messageSlice.actions.showMessage({
              text: t('myQr.message.fetchIdentityTokenFail'),
              type: 'error',
            }),
          );
        } finally {
          dispatch(loaderSlice.actions.setLoaderState({ loading: false }));
        }
      })();
      setSeconds(300);
    }
  }, [seconds]);

  return (
    <CardLayout
      title={t('myQr.title')}
      contentTitle={t('myQr.contentTitle')}
      contentDescription={t('myQr.contentDescription')}
      footer={<Footer />}
      onBackClick={goBack}
      maxContentWidth={750}
      centered
    >
      <QrCode type={QrCodeType.CreditsPayment} value={qrData} />
      <div>
        {t('myQr.tokenWillRefreshIn')} {seconds}
      </div>
      <div className={classes.creditsDescriptionContainer}>
        <span className={classes.creditsDescription}>
          {t('myQr.creditsDescription')}
        </span>
        <CreditCount
          title={t('myQr.creditsTitle')}
          amount={currentUser?.credits ?? 0}
          currency={currency}
          noMargin
        />
      </div>
    </CardLayout>
  );
};

export default MyQr;
