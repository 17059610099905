import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import { RootState } from 'core/store';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      position: 'fixed',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      backgroundColor: '#00000088',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 2,
    },
    loadingText: {
      marginTop: '24px',
      fontSize: '22px',
      color: 'white',
      fontWeight: 700,
    },
  }),
);

type Props = {
  isAuthRoute: boolean;
};

const SpinnerOverlay: FC<Props> = ({ isAuthRoute }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const loading = useSelector(
    (state: RootState) =>
      ((state.categories.loading ||
        state.currentUser.loading ||
        state.currentEvent.loading) &&
        !isAuthRoute) ||
      state.loader.loading,
  );

  if (!loading) {
    return null;
  }

  return (
    <div className={classes.container}>
      <CircularProgress />
      <span className={classes.loadingText}>{t('common.loading')}</span>
    </div>
  );
};

export default SpinnerOverlay;
