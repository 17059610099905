import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { Permission } from './permissions';

const usePermissions = () => {
  const { permissions, isAuthenticated } = useSelector((state: RootState) => ({
    permissions: state.currentUser.data?.permissions,
    isAuthenticated: Boolean(state.currentUser.data?.role?.[0]),
  }));

  const isAllowedTo = (permission: Permission) =>
    Boolean(permissions?.includes(permission));

  return { isAllowedTo, isAuthenticated };
};

export default usePermissions;
