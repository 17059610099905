import { createStyles, makeStyles } from '@material-ui/core';
import { FC, useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  getAvailableBoothMenuItems,
  getBoothTypeInfo,
  getBoothTypeMenuItems,
} from 'core/requests';
import {
  RootState,
  orderedProductsSlice,
  loaderSlice,
  messageSlice,
} from 'core/store';
import { CrewLayout, FlatLayout } from 'shared/layouts';
import {
  BoothHeader,
  BoothMenuItem,
  CheckoutFooter,
  ScreenFooter,
} from 'shared/components';
import { BoothTypeInfo, Product } from 'shared/types';
import MerchantHeader from './MerchantHeader';
import MerchantFooter from './MerchantFooter';

const useStyles = makeStyles(() =>
  createStyles({
    subcontainer: {
      padding: '30px 20px 65px 20px',
    },
    crewSubcontainer: {
      padding: '30px 20px 20px 20px',
      width: '100%',
      maxWidth: 750,
    },
  }),
);

const BoothMenu: FC = () => {
  const { goBack, location } = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { boothTypeNumber } = useParams<{ boothTypeNumber: string }>();
  const { products, isLoading } = useSelector((state: RootState) => ({
    products: state.orderedProducts.products,
    isLoading: state.loader.loading,
  }));
  const [boothMenuItems, setBoothMenuItems] = useState<Product[]>([]);
  const [focusedMenuItem, setFocusedMenuItem] = useState<string | null>(null);
  const [boothTypeInfo, setBoothTypeInfo] =
    useState<BoothTypeInfo | null>(null);
  const isCrew = location.pathname.includes('/crew');
  const isMerchant = location.pathname.includes('merchant');
  const { currentUser } = useSelector((state: RootState) => ({
    currentUser: state.currentUser.data,
  }));
  const merchantBoothId = currentUser?.merchantBoothId;
  const classes = useStyles();

  useEffect(() => {
    const fetchBoothMenuItems = async () => {
      try {
        const { data } = await getBoothTypeMenuItems(boothTypeNumber);
        setBoothMenuItems(data.data.items);
      } catch {
        dispatch(
          messageSlice.actions.showMessage({
            text: t('message.boothMenuFetchError'),
            type: 'error',
          }),
        );
      }
    };
    const fetchBoothTypeInfo = async () => {
      try {
        const { data } = await getBoothTypeInfo(boothTypeNumber);
        setBoothTypeInfo(data.data);
      } catch {
        dispatch(
          messageSlice.actions.showMessage({
            text: t('message.boothInfoFetchError'),
            type: 'error',
          }),
        );
      }
    };
    const fetchAvailableBoothMenuItems = async () => {
      console.log('merchantBoothId', merchantBoothId);
      if (merchantBoothId) {
        try {
          const { data } = await getAvailableBoothMenuItems(merchantBoothId);
          setBoothMenuItems(data.data.items);
        } catch {
          dispatch(
            messageSlice.actions.showMessage({
              text: t('message.boothMenuFetchError'),
              type: 'error',
            }),
          );
        }
      }
    };
    if (isMerchant || isCrew) {
      (async () => {
        dispatch(loaderSlice.actions.setLoaderState({ loading: true }));
        await fetchAvailableBoothMenuItems();
        dispatch(loaderSlice.actions.setLoaderState({ loading: false }));
      })();
    } else {
      (async () => {
        dispatch(loaderSlice.actions.setLoaderState({ loading: true }));
        await Promise.allSettled([fetchBoothMenuItems(), fetchBoothTypeInfo()]);
        dispatch(loaderSlice.actions.setLoaderState({ loading: false }));
      })();
    }
  }, [isMerchant]);

  const handleItemQuantityChange = (uuid: string, increase: boolean) => () => {
    if (increase) {
      dispatch(
        orderedProductsSlice.actions.addOrderedProduct(
          boothMenuItems.find((item) => item.uuid === uuid)!,
        ),
      );
    } else {
      const removedProduct = products[uuid];
      if (removedProduct?.quantity === 1) {
        dispatch(orderedProductsSlice.actions.deleteOrderedProduct(uuid));
      } else {
        dispatch(orderedProductsSlice.actions.subtractOrderedProduct(uuid));
      }
    }

    setFocusedMenuItem(uuid);
  };

  const handleItemQuantityClick = (id: string) => () => {
    setFocusedMenuItem(id);
  };

  const productsCategoryId = useMemo(
    () =>
      boothMenuItems.some(
        (item, _, arr) => item.categoryId !== arr[0]?.categoryId,
      )
        ? undefined
        : boothMenuItems[0]?.categoryId,
    [boothMenuItems],
  );

  const renderMenuItems = (item: Product) => (
    <BoothMenuItem
      key={item.uuid}
      {...item}
      quantity={products[item.uuid]?.quantity ?? 0}
      highlightSelected
      focused={item.uuid === focusedMenuItem}
      onQuantityClick={handleItemQuantityClick(item.uuid)}
      onIncrease={handleItemQuantityChange(item.uuid, true)}
      onDecrease={handleItemQuantityChange(item.uuid, false)}
    />
  );

  if (isCrew) {
    return (
      <CrewLayout
        title={t('booth.menu.merchant.title')}
        footer={<MerchantFooter isCrew />}
      >
        <div className={classes.crewSubcontainer}>
          {boothMenuItems.map(renderMenuItems)}
        </div>
      </CrewLayout>
    );
  }

  return (
    <FlatLayout
      backgroundOverflow={isMerchant ? undefined : 0}
      onBackClick={goBack}
      header={
        isMerchant ? (
          <MerchantHeader />
        ) : (
          <BoothHeader
            name={boothTypeInfo?.name ?? ''}
            categoryId={productsCategoryId}
            number={Number(boothTypeInfo?.number)}
            showInfo
            loading={isLoading}
          />
        )
      }
      footer={
        <ScreenFooter>
          {isMerchant ? <MerchantFooter /> : <CheckoutFooter />}
        </ScreenFooter>
      }
      maxContentWidth={750}
    >
      <div className={classes.subcontainer}>
        {boothMenuItems.map(renderMenuItems)}
      </div>
    </FlatLayout>
  );
};

export default BoothMenu;
