import { SvgIcon } from '@material-ui/core';
import { ChangeEventHandler, FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { ReactComponent as KeyIcon } from 'assets/key.svg';
import { setUserPassword } from 'core/requests';
import { messageSlice } from 'core/store';
import { Input, Button } from 'shared/components';
import { MERCHANT_LOGIN_ROUTE } from 'shared/constants';
import { getPasswordRules } from 'shared/helpers';
import { CardLayout } from 'shared/layouts';
import { useLoginStyles } from 'shared/styles';

const initialValues = {
  password: '',
  confirmPassword: '',
};

const ResetPassword: FC = () => {
  const classes = useLoginStyles();
  const history = useHistory();
  const { pathname } = useLocation();
  const { t, i18n } = useTranslation();
  const query = new URLSearchParams(location.search);
  const queryToken = query.get('token');
  const dispatch = useDispatch();
  const isMerchant = pathname.includes('/merchant');

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        password: getPasswordRules(t),
        confirmPassword: Yup.string()
          .oneOf([Yup.ref('password')], t('validation.passwordsMustMatch'))
          .required(t('validation.required')),
      }),
    [i18n.language],
  );

  const handleFormSubmit = async (values: typeof initialValues) => {
    if (queryToken) {
      try {
        await setUserPassword({
          token: queryToken,
          password: values.password,
        });
        dispatch(
          messageSlice.actions.showMessage({
            text: t('resetPassword.message.setPasswordSuccess'),
            type: 'success',
          }),
        );
        history.push(MERCHANT_LOGIN_ROUTE);
      } catch (err) {
        dispatch(
          messageSlice.actions.showMessage({
            text: t('resetPassword.message.setPasswordFail'),
            type: 'error',
          }),
        );
      }
    }
  };

  const getTitleAndDescription = () => ({
    contentTitle: t(
      isMerchant ? 'setMerchantPassword.title' : 'resetPassword.title',
    ),
    contentDescription: t(
      isMerchant
        ? 'setMerchantPassword.description'
        : 'resetPassword.description',
    ),
  });

  return (
    <CardLayout
      {...getTitleAndDescription()}
      backgroundColor="white"
      onBackClick={history.goBack}
      maxContentWidth={340}
      centered
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {({
          touched,
          errors,
          setFieldTouched,
          handleSubmit,
          handleChange,
          handleBlur,
        }) => {
          const getFieldError = (field: keyof typeof initialValues) => {
            const isTouched = touched[field] || undefined;
            return isTouched && errors[field];
          };

          const handleFormFieldChange: ChangeEventHandler<HTMLInputElement> = (
            event,
          ) => {
            const fieldName = event.target.name as keyof typeof initialValues;
            if (touched[fieldName]) {
              handleChange(event);
            } else {
              handleChange(event);
              setFieldTouched(fieldName);
            }
          };

          return (
            <div className={classes.container}>
              <Input
                name="password"
                icon={
                  <SvgIcon component={KeyIcon} className={classes.inputIcon} />
                }
                placeholder={t('resetPassword.passwordPlaceholder')}
                className={classes.input}
                onChange={handleFormFieldChange}
                onBlur={handleBlur}
                error={getFieldError('password')}
                secure
              />
              <Input
                name="confirmPassword"
                icon={
                  <SvgIcon component={KeyIcon} className={classes.inputIcon} />
                }
                placeholder={t('resetPassword.confirmPasswordPlaceholder')}
                className={classes.input}
                onChange={handleFormFieldChange}
                onBlur={handleBlur}
                error={getFieldError('confirmPassword')}
                secure
              />
              <Button
                className={classes.registerButton}
                text={t('resetPassword.saveButtonText')}
                primary
                onClick={handleSubmit}
              />
            </div>
          );
        }}
      </Formik>
    </CardLayout>
  );
};

export default ResetPassword;
