import { authToken } from 'shared/helpers';
import {
  BoothInfo,
  BoothType,
  BoothTypeAbout,
  BoothTypeInfo,
  BoothTypeStatusText,
  EventsListItem,
  EventResponse,
  IdentityTokenResponse,
  MerchantBoothInfo,
  MerchantBoothsResponse,
  MerchantOrderHistoryItem,
  Order,
  OrderDraft,
  OrderHistoryItem,
  OrderingDay,
  OrderStatus,
  Payment,
  PaymentMethod,
  Product,
  ProductCategory,
  UserResponse,
} from 'shared/types';
import axiosInstance from './axiosConfig';

export type Response<T> = {
  code: number;
  message: string;
  data: T;
};

export const setUserPassword = (body: { token: string; password: string }) =>
  axiosInstance.post('/users/reset-password', body);

type ChangePasswordBody = {
  currentPassword: string;
  password: string;
};

export const changePassword = (body: ChangePasswordBody) =>
  axiosInstance.post('/users/change-password', body);

export const login = async (body: { email: string; password: string }) => {
  const {
    data: { data },
  } = await axiosInstance.post<Response<{ token: string }>>(
    '/users/token',
    body,
  );
  authToken.setToken(data.token);
};

type RegisterBody = {
  firstName: string;
  lastName: string;
  email: string;
  dateOfBirth: string;
  password: string;
  confirmPassword: string;
  terms: boolean;
  language: string;
};

export const postRegister = (body: RegisterBody) =>
  axiosInstance.post('/visitors', body);

export const postForgotPassword = (body: { email: string }) =>
  axiosInstance.post('/users/reset-password-request', body);

export const getCurrentUser = () =>
  axiosInstance.get<Response<UserResponse>>('/me');

export const getUserIdentityToken = () =>
  axiosInstance.get<Response<IdentityTokenResponse>>('/user/identity-token');

export const getCurrentEvent = () =>
  axiosInstance.get<Response<EventResponse>>('/events/current');

type ProductCategories = {
  items: ProductCategory[];
};

export const getProductCategories = () =>
  axiosInstance.get<Response<ProductCategories>>('/product-categories');

export const getOrderDetails = (id: string) =>
  axiosInstance.get<Response<Order>>(`/order/${id}`);

export const getVisitorRemainingCredits = (uuid: string) =>
  axiosInstance.get<Response<{ remainingCredits: number }>>(
    `merchant/visitor-remaining-credits/${uuid}`,
  );

export const getMerchantOrderDetails = (uuid: string) =>
  axiosInstance.get<Response<Order>>(`/merchant/order/${uuid}`);

type CategoryBoothTypeList = {
  items: BoothType[];
};

export const getCategoryBoothTypeList = (categoryId: string) =>
  axiosInstance.get<Response<CategoryBoothTypeList>>(
    `/product-categories/${categoryId}/booth-types`,
  );

type MerchantOrders = {
  items: MerchantOrderHistoryItem[];
};

export const getOutstandingOrders = () =>
  axiosInstance.get<Response<MerchantOrders>>('/outstanding-orders');

export const getBoothRetrievedOrders = (id: number) =>
  axiosInstance.get<Response<MerchantOrders>>(`/booths/${id}/retrieved-orders`);

type BoothTypes = {
  items: BoothType[];
};

export const getBoothTypes = () =>
  axiosInstance.get<Response<BoothTypes>>('/booth-types');

type BoothMenuItems = {
  items: Product[];
};

export const getBoothTypeMenuItems = (boothNumber: string) =>
  axiosInstance.get<Response<BoothMenuItems>>(
    `/booths/${boothNumber}/products`,
  );

export const getBoothMenuItemsForOverview = (merchantBoothId: number) =>
  axiosInstance.get<Response<BoothMenuItems>>(
    `/merchant/booth/${merchantBoothId}/products`,
  );

export const getAvailableBoothMenuItems = (merchantBoothId: number) =>
  axiosInstance.get<Response<BoothMenuItems>>(
    `/merchant/booth/${merchantBoothId}/available-products`,
  );

export const putBoothProductAvailability = (
  boothId: number,
  productUuid: string,
) =>
  axiosInstance.put(
    `merchant/booth/${boothId}/product/${productUuid}/availabilty`,
  );

type CheckoutItems = Array<{ uuid: string; quantity: number }>;

export const getDraft = () =>
  axiosInstance.get<Response<OrderDraft>>('/drafts');

export const getBoothTypeInfo = (boothTypeNumber: string | number) =>
  axiosInstance.get<Response<BoothTypeInfo>>(`/booths/${boothTypeNumber}`);

export const createOrder = (body: {
  paymentMethodId: string;
  redirectUrl?: string;
  products: CheckoutItems;
}) => axiosInstance.post<Response<{ checkoutUrl: string }>>('/drafts', body);

export const createMerchantOrder = (body: {
  paymentMethodId: string;
  redirectUrl?: string;
  products: CheckoutItems;
  identityToken?: string;
}) =>
  axiosInstance.post<Response<{ checkoutUrl: string; orderId: string }>>(
    '/merchant/order',
    body,
  );

export const createCrewOrder = (body: {
  paymentMethodId: string;
  redirectUrl?: string;
  products: CheckoutItems;
  identityToken?: string;
}) =>
  axiosInstance.post<Response<{ checkoutUrl: string; orderId: string }>>(
    '/merchant/order',
    body,
  );

export const retryOrderPayment = ({
  orderUuid,
  ...rest
}: {
  orderUuid: string;
  paymentMethodId: string;
  redirectUrl: string;
  identityToken?: string;
}) =>
  axiosInstance.post<Response<{ checkoutUrl: string }>>(
    `/orders/${orderUuid}/payment-retry`,
    rest,
  );

export const cancelDraft = (draftUuid: string) =>
  axiosInstance.post<Response<{ checkoutUrl: string }>>(
    `/drafts/${draftUuid}/cancel`,
  );

export const topUpCredits = (body: {
  paymentMethodId: string;
  redirectUrl: string;
  credits: number;
}) =>
  axiosInstance.post<Response<{ checkoutUrl: string }>>('/credits/topup', body);

export const getPaymentDetails = (paymentUuid: string) =>
  axiosInstance.get<Response<Payment>>(`/payments/${paymentUuid}`);

export type VisitorOrders = {
  items: OrderHistoryItem[];
};

export const getVisitorOrders = () =>
  axiosInstance.get<Response<VisitorOrders>>('/visitor-orders');

export const getVisitorRecentOrders = () =>
  axiosInstance.get<Response<VisitorOrders>>('/visitor-recent-orders');

export const putOrderAsRetrieved = (orderUuid: string, boothId: number) =>
  axiosInstance.put(`/orders/${orderUuid}/delivery-status`, {
    status: OrderStatus.Retrieved,
    boothId,
  });

export const putOrderAsCancelled = (orderUuid: string) =>
  axiosInstance.put(`/orders/${orderUuid}/delivery-status`, {
    status: OrderStatus.Cancelled,
  });

export const putBoothStatus = (boothId: number) =>
  axiosInstance.put(`/booths/${boothId}/status`);

export const putBoothOrderingHours = (boothId: number, body: OrderingDay) =>
  axiosInstance.put(`/booths/${boothId}/opening-times`, body);

export const getBoothInfo = (boothId: number) =>
  axiosInstance.get<Response<BoothInfo>>(`/old-booths/${boothId}`);

export const putOrderAsHelpdesk = (orderUuid: string) =>
  axiosInstance.put(`/orders/${orderUuid}/delivery-status`, {
    status: OrderStatus.Helpdesk,
  });

export const putBoothTypeAbout = (body: BoothTypeAbout) =>
  axiosInstance.put(`/merchant/booth-type-about`, body);

export const putBoothTypeStatusText = (body: BoothTypeStatusText) =>
  axiosInstance.put(`/merchant/booth-type-status-text`, body);

export const putOrderAsPending = (orderUuid: string) =>
  axiosInstance.put(`/orders/${orderUuid}/delivery-status`, {
    status: OrderStatus.Pending,
  });

export const getMerchantBoothInfo = (boothId: number) =>
  axiosInstance.get<Response<MerchantBoothInfo>>(`/booth/${boothId}`);

export const getPaymentMethods = () =>
  axiosInstance.get<Response<{ items: PaymentMethod[] }>>('/payment-methods');

export const getMerchantBoothList = () =>
  axiosInstance.get<Response<MerchantBoothsResponse>>('/merchant/booths');

export const postSwitchBooth = (boothId: number) =>
  axiosInstance.post('/merchant/booth/switch', { booth: boothId });

export type EventList = {
  items: EventsListItem[];
};

export const getActiveEvents = () =>
  axiosInstance.get<Response<EventList>>('/active-events');

export const getMerchantActiveEvents = () =>
  axiosInstance.get<Response<EventList>>('/merchant/active-events');

export const postSwitchEvent = (eventUuid: string) =>
  axiosInstance.post('/events/change', { eventUuid });

export const postBoothTypeImages = (formData: FormData) =>
  axiosInstance.post('/merchant/booth-type/add-images', formData);

export const putChangeLanguage = (language: string) =>
  axiosInstance.put('/visitor/change-language', { language });

export const deleteBoothTypeImages = (images: string[]) =>
  axiosInstance.delete('/merchant/booth-type/delete-images', {
    data: { images },
  });

export const postActivateCrewMemberDevice = async (body: {
  identityToken?: string;
}) => {
  const {
    data: { data },
  } = await axiosInstance.post<Response<{ token: string }>>(
    '/crew-members',
    body,
  );
  authToken.setToken(data.token);
};
