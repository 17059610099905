import React, { CSSProperties, FC } from 'react';
import clsx from 'clsx';
import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles<unknown, { altShadow?: boolean }>(() =>
  createStyles({
    card: {
      borderRadius: '12px',
      backgroundColor: '#FFFFFF',
      boxShadow: (props) =>
        props.altShadow
          ? '0 0 7px 4px rgba(0,0,0,0.1)'
          : '0 0 11px -2px rgba(0,0,0,0.14)',
    },
  }),
);

type Props = {
  className?: string;
  altShadow?: boolean;
  style?: CSSProperties;
  onClick?: () => void;
  element?: 'div' | 'span';
};

const Card: FC<Props> = ({
  className,
  altShadow,
  style,
  onClick,
  children,
  element = 'div',
}) => {
  const classes = useStyles({ altShadow });

  return React.createElement(
    element,
    {
      className: clsx(classes.card, className),
      onClick,
      role: onClick ? 'button' : 'generic',
      tabIndex: onClick ? 0 : undefined,
      style,
    },
    children,
  );
};

export default Card;
