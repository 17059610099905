import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { CardLayout, CrewLayout } from 'shared/layouts';
import { getDraft, getOrderDetails, getPaymentMethods } from 'core/requests';
import { loaderSlice, messageSlice, RootState } from 'core/store';
import { hasOnlyDigits } from 'shared/helpers';
import { OrderedProduct, PaymentMethod, PaymentMethodType } from 'shared/types';
import Footer, { PaymentData } from './Footer';
import MethodCard from './MethodCard';
import TopUpReminderDialog from './TopUpReminderDialog';
import useStyles from './useStyles';

const PaymentSelection: FC = () => {
  const { t } = useTranslation();
  const { goBack, location } = useHistory();
  const { source } = useParams<{ source: 'draft' | undefined }>();
  const isCrew = location.pathname.includes('/crew');
  const isMerchant = location.pathname.includes('/merchant');
  const [paymentMethods, setPaymentMethods] = useState<
    Array<PaymentMethod & { disabled: boolean }>
  >([]);
  const [paymentMethodId, setPaymentMethodId] = useState<PaymentMethodType>();
  const {
    exchangeRate,
    orderedProducts: { total, defaultCurrencyTotal, products },
    currentUser,
    availableCredits,
  } = useSelector((state: RootState) => ({
    exchangeRate: state.currentEvent?.data?.exchangeRate,
    orderedProducts: state.orderedProducts,
    currentUser: state.currentUser.data,
    availableCredits: state.currentUser.data?.credits,
  }));
  const [paymentData, setPaymentData] = useState<PaymentData | undefined>();
  const [topUpReminderOpen, setTopUpReminderOpen] = useState(false);
  const dispatch = useDispatch();
  const query = new URLSearchParams(location.search);
  const orderUuid = query.get('orderUuid');
  const orderId = query.get('orderId');
  const topUp = query.get('topUp');
  const draftUuid = query.get('draftUuid');
  const topUpAmount =
    topUp && hasOnlyDigits(topUp) ? parseInt(topUp, 10) : undefined;
  const paymentDataTotal = paymentData?.total;
  const hasEnoughCredits = useMemo(
    () =>
      Boolean(
        !topUp &&
          !isMerchant &&
          paymentDataTotal &&
          paymentDataTotal <= (availableCredits || 0),
      ),
    [availableCredits, paymentDataTotal, topUp, isMerchant],
  );
  const classes = useStyles({});

  useEffect(() => {
    setTopUpReminderOpen(
      paymentMethodId === PaymentMethodType.Credits &&
        !hasEnoughCredits &&
        undefined !== currentUser &&
        currentUser.role.includes('ROLE_VISITOR'),
    );
  }, [paymentMethodId, hasEnoughCredits]);

  const fetchPaymentMethods = useCallback(async () => {
    try {
      const {
        data: { data },
      } = await getPaymentMethods();
      setPaymentMethods(
        data.items
          .filter(
            (item) =>
              (!isMerchant || item.availableForMerchant) &&
              (!topUp || item.availableForCreditsPayment),
          )
          .map((item) => ({
            ...item,
            disabled: true,
          })),
      );
    } catch (error) {
      dispatch(
        messageSlice.actions.showMessage({
          text: t('paymentSelection.message.fetchPaymentMethodsFail'),
          type: 'error',
        }),
      );
    }
  }, []);

  const fetchDraft = useCallback(async () => {
    try {
      const {
        data: { data },
      } = await getDraft();
      const draftProducts = data.products.reduce(
        (acc, value) => ({ ...acc, [value.uuid]: value }),
        {} as { [key: string]: OrderedProduct },
      );

      setPaymentData({
        total: data.totalAmount.value,
        defaultCurrencyTotal: data.defaultCurrencyTotalAmount.value,
        products: draftProducts,
      });
    } catch (error) {
      dispatch(
        messageSlice.actions.showMessage({
          text: t('paymentSelection.message.fetchDraftFail'),
          type: 'error',
        }),
      );
    }
  }, []);

  const fetchOrderDetails = useCallback(async () => {
    if (orderId) {
      dispatch(loaderSlice.actions.setLoaderState({ loading: true }));
      try {
        const {
          data: { data },
        } = await getOrderDetails(orderId);
        const products = data.products.reduce(
          (acc, value) => ({
            ...acc,
            [value.uuid]: {
              ...value,
              categoryId: 0,
            },
          }),
          {} as { [key: string]: OrderedProduct },
        );
        setPaymentData({
          total: data.totalAmount.value,
          defaultCurrencyTotal: data.defaultCurrencyTotalAmount.value,
          products,
        });
      } catch (error) {
        dispatch(
          messageSlice.actions.showMessage({
            text: t('orderDetails.message.fetchOrderDetailsFail'),
            type: 'error',
          }),
        );
      } finally {
        dispatch(loaderSlice.actions.setLoaderState({ loading: false }));
      }
    }
  }, []);

  useEffect(() => {
    if (source === 'draft') {
      //draft from order details and retry payment for draft (/payment view)
      (async () => {
        dispatch(loaderSlice.actions.setLoaderState({ loading: true }));
        await Promise.allSettled([fetchPaymentMethods(), fetchDraft()]);
        dispatch(loaderSlice.actions.setLoaderState({ loading: false }));
      })();
    } else if (orderUuid && orderId) {
      // merchant retry payment
      (async () => {
        dispatch(loaderSlice.actions.setLoaderState({ loading: true }));
        await Promise.allSettled([fetchPaymentMethods(), fetchOrderDetails()]);
        dispatch(loaderSlice.actions.setLoaderState({ loading: false }));
      })();
    } else {
      (async () => {
        dispatch(loaderSlice.actions.setLoaderState({ loading: true }));
        await fetchPaymentMethods();
        dispatch(loaderSlice.actions.setLoaderState({ loading: false }));
      })();
      setPaymentData({ total, defaultCurrencyTotal, products });
    }
  }, []);

  useEffect(() => {
    if (topUpAmount && exchangeRate) {
      const topUpInEuro = topUpAmount * exchangeRate;
      setPaymentMethods((current) =>
        current.map((method) => ({
          ...method,
          disabled:
            method.id !== PaymentMethodType.Credits &&
            (topUpInEuro < method.minimumAmount.value ||
              topUpInEuro > method.maximumAmount.value),
        })),
      );
    } else if (!topUpAmount && paymentMethods.length && paymentDataTotal) {
      setPaymentMethods((current) =>
        current.map((method) => ({
          ...method,
          disabled:
            method.id !== PaymentMethodType.Credits &&
            (paymentDataTotal < method.minimumAmount.value ||
              paymentDataTotal > method.maximumAmount.value),
        })),
      );
    }
  }, [paymentDataTotal, paymentMethods.length, topUpAmount, exchangeRate]);

  const handleClickCard = (paymentMethodId: PaymentMethodType) => {
    setPaymentMethodId(paymentMethodId);
  };

  const handleCloseTopUpReminder = () => {
    setPaymentMethodId(undefined);
    setTopUpReminderOpen(false);
  };

  const renderCard = (paymentMethod: PaymentMethod & { disabled: boolean }) => (
    <MethodCard
      key={paymentMethod.id}
      paymentMethod={paymentMethod}
      isSelected={paymentMethodId === paymentMethod.id}
      onClickCard={handleClickCard}
      disabled={paymentMethod.disabled}
    />
  );

  if (isCrew) {
    return (
      <CrewLayout
        title={t('paymentSelection.title')}
        footer={
          <Footer
            paymentMethodId={paymentMethodId}
            isCrew
            isMerchant={isMerchant}
            paymentData={paymentData}
            orderId={orderId}
            orderUuid={orderUuid}
            draftUuid={draftUuid}
            topUpAmount={topUpAmount}
            source={source}
          />
        }
      >
        <div className={classes.bodySubcontainer}>
          <h3>{t('paymentSelection.contentTitle')}</h3>
          {paymentMethods.map(renderCard)}
          {topUpReminderOpen && (
            <TopUpReminderDialog close={handleCloseTopUpReminder} open={true} />
          )}
        </div>
      </CrewLayout>
    );
  }

  return (
    <CardLayout
      title={t('paymentSelection.title')}
      centered
      onBackClick={goBack}
      footer={
        <Footer
          paymentMethodId={paymentMethodId}
          isMerchant={isMerchant}
          paymentData={paymentData}
          orderId={orderId}
          orderUuid={orderUuid}
          draftUuid={draftUuid}
          topUpAmount={topUpAmount}
          source={source}
        />
      }
      maxContentWidth={360}
    >
      <h3>{t('paymentSelection.contentTitle')}</h3>
      {paymentMethods.map(renderCard)}
      {topUpReminderOpen && (
        <TopUpReminderDialog close={handleCloseTopUpReminder} open={true} />
      )}
    </CardLayout>
  );
};

export default PaymentSelection;
