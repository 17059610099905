import { FC } from 'react';
import { useSelector } from 'react-redux';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { getImageUrl, useRoleNavigation } from 'shared/helpers';
import { FlatLayout } from 'shared/layouts';
import { Card, PlaceOrderFooter, ScreenFooter } from 'shared/components';
import { BOOTH_SELECTION_ROUTE } from 'shared/constants';
import { ProductCategory } from 'shared/types';
import { RootState } from 'core/store';
import Header from './Header';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItemsContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
      marginTop: '34px',
      marginBottom: '75px',
    },
    card: {
      width: '130px',
      height: '130px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '0 12px 24px 12px',
    },
    menuItemImage: {
      width: '50px',
      height: '50px',
      marginBottom: '9px',
    },
    menuItemName: {
      fontSize: '15px',
      color: theme.customPalette.background,
      paddingLeft: '12px',
      paddingRight: '12px',
      textAlign: 'center',
    },
  }),
);

const VisitorHome: FC = () => {
  const classes = useStyles();
  const { push } = useRoleNavigation();
  const categories = useSelector((state: RootState) => state.categories.data);

  const handleMenuItemClick = (id: number) => () => {
    push(`${BOOTH_SELECTION_ROUTE}/${id}`);
  };

  const renderMenuItem = (item: ProductCategory) => (
    <Card
      key={item.id}
      className={classes.card}
      onClick={handleMenuItemClick(item.id)}
      altShadow
    >
      <img className={classes.menuItemImage} src={getImageUrl(item.icon)} />
      <span className={classes.menuItemName}>{item.name}</span>
    </Card>
  );

  return (
    <FlatLayout
      header={<Header />}
      footer={
        <ScreenFooter>
          <PlaceOrderFooter />
        </ScreenFooter>
      }
      maxContentWidth={750}
    >
      <div className={classes.menuItemsContainer}>
        {categories.map(renderMenuItem)}
      </div>
    </FlatLayout>
  );
};

export default VisitorHome;
