import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    label: {
      fontSize: '10px',
      fontWeight: 600,
      lineHeight: '14px',
      color: theme.customPalette.labelGray,
      textTransform: 'uppercase',
    },
    text: {
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '22px',
      color: theme.customPalette.textPrimary,
    },
  }),
);

export default useStyles;
