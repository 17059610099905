import { FC } from 'react';
import { format } from 'date-fns';
import { CalendarToday, LocationOn } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import useStyles from '../useStyles';

type Props = {
  boothType: number;
  boothLocation: string;
  date: Date;
  className?: string;
  isCrew: boolean;
};

const OrderDetails: FC<Props> = ({
  boothType,
  boothLocation,
  date,
  className,
  isCrew,
}) => {
  const { t } = useTranslation();
  const classes = useStyles({ isCrew });

  return (
    <div className={className}>
      <div className={classes.orderDetailsRowContainer}>
        <CalendarToday className={classes.orderDetailsText} />
        <span className={classes.orderDetailsText}>
          {format(date, 'HH:mm')}
        </span>
        <span className={classes.orderDetailsText}>|</span>
        <span className={classes.orderDetailsText}>
          {format(date, 'EEEE dd MMMM')}
        </span>
      </div>
      <div className={classes.orderDetailsRowContainer}>
        <LocationOn className={classes.orderDetailsText} />
        <span className={classes.orderDetailsText}>
          {t('scanOrderResult.boothNumber', { number: boothType })}
        </span>
        <span className={classes.orderDetailsText}>-</span>
        <span className={classes.orderDetailsText}>{boothLocation}</span>
      </div>
    </div>
  );
};

export default OrderDetails;
