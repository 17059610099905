import {
  Chip as MuiChip,
  createStyles,
  makeStyles,
  ChipProps,
} from '@material-ui/core';
import clsx from 'clsx';
import { FunctionComponent } from 'react';

const useStyles = makeStyles(() =>
  createStyles({
    chip: {
      marginRight: '0.375rem',
      fontSize: '0.75rem',
      backgroundColor: '#cacfe8',
      color: '#422e6b',
      '&.MuiChip-sizeSmall': {
        height: '1.3125rem',
      },
    },
  }),
);

const Chip: FunctionComponent<ChipProps> = ({ className, ...props }) => {
  const classes = useStyles();

  return (
    <MuiChip
      className={clsx(classes.chip, className)}
      size="small"
      {...props}
    />
  );
};

export default Chip;
