import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles<Theme, { isCrew?: boolean }>((theme) =>
  createStyles({
    bodySubcontainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      maxWidth: 360,
      boxSizing: 'border-box',
      padding: '30px 20px 20px 20px',
      color: '#393f44',
      '& h3': {
        margin: '0 0 20px 0',
        fontSize: '1.3125rem',
        fontWeight: 'bold',
        color: 'white',
      },
    },
    cardButton: {
      marginBottom: 20,
    },
    card: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      width: '100%',
      padding: '14px 12px 14px 11px',
    },
    leftSlot: {
      flex: '0 0 42px',
    },
    rightSlot: {
      flex: '1 1 auto',
    },
    radio: {
      color: '#d0d0d0',
    },
    images: {
      paddingTop: 2,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
      minHeight: 38,
    },
    festivalCredits: {
      paddingRight: 12,
      minHeight: 35,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontSize: '0.875rem',
      color: theme.customPalette.labelGray,
      '& > span': {
        padding: '12px',
      },
    },
    linkedCardTopContainer: {
      paddingRight: 12,
      minHeight: 38,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      fontSize: '0.875rem',
      color: theme.customPalette.labelGray,
      '& > span': {
        padding: '12px 0 12px 12px',
      },
    },
    linkedCardBottomContainer: {
      paddingRight: 12,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontSize: '0.9375rem',
      color: theme.customPalette.labelGray,
    },
    footer: ({ isCrew }) => ({
      display: 'flex',
      flexDirection: isCrew ? 'row' : 'column',
      alignItems: 'center',
      justifyContent: isCrew ? 'space-between' : 'initial',
      padding: '0 20px 10px 20px',
      height: '100%',
      '& > *': {
        marginBottom: isCrew ? 0 : 20,
        '&:first-child': {
          order: isCrew ? 1 : 0,
        },
      },
    }),
    amount: {
      fontSize: '1.125rem',
      lineHeight: '1.5rem',
      color: theme.palette.secondary.main,
      whiteSpace: 'nowrap',
    },
    crewCancelButton: {
      width: 'auto',
      minWidth: 108,
    },
  }),
);

export default useStyles;
