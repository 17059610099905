import { FC, ReactNode } from 'react';
import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginTop: '-10px',
      paddingBottom: '4px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    leftSlot: {
      paddingTop: '8px',
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      borderTopLeftRadius: '12px',
      borderBottomLeftRadius: '12px',
    },
    rightSlot: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      borderTopRightRadius: '12px',
      borderBottomRightRadius: '12px',
    },
  }),
);

type Props = {
  leftSlotContent?: ReactNode;
  rightSlotContent?: ReactNode;
};

const ProductItemHeader: FC<Props> = ({
  leftSlotContent,
  rightSlotContent,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.leftSlot}>{leftSlotContent}</div>
      <div className={classes.rightSlot}>{rightSlotContent}</div>
    </div>
  );
};

export default ProductItemHeader;
