import Checkout from './Checkout';
import MerchantOrderDetails from './MerchantOrderDetails';
import MerchantOrderHistory from './MerchantOrderHistory';
import OrderDetails from './OrderDetails';
import OrderHistory from './OrderHistory';
import PaymentSelection from './PaymentSelection';
import ScanOrderResult from './ScanOrderResult';

export default {
  Checkout,
  MerchantOrderDetails,
  MerchantOrderHistory,
  OrderDetails,
  OrderHistory,
  PaymentSelection,
  ScanOrderResult,
};
