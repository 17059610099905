import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { categoriesSlice } from './categories';
import { currentUserSlice } from './currentUser';
import { currentEventSlice } from './currentEvent';
import { merchantBoothsSlice } from './merchantBooths';
import { loaderSlice } from './loader';
import { messageSlice } from './message';
import { orderedProductsSlice } from './orderedProducts';

const createRootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    message: messageSlice.reducer,
    currentUser: currentUserSlice.reducer,
    currentEvent: currentEventSlice.reducer,
    merchantBooths: merchantBoothsSlice.reducer,
    orderedProducts: orderedProductsSlice.reducer,
    categories: categoriesSlice.reducer,
    loader: loaderSlice.reducer,
  });

export default createRootReducer;
