import { FC } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { Permission, usePermissions } from 'features/Auth';
import { HOME_ROUTE } from 'shared/constants';

type Props = RouteProps & {
  permission: Permission;
};

const ProtectedRoute: FC<Props> = ({ permission, ...routeProps }) => {
  const { isAllowedTo, isAuthenticated } = usePermissions();

  if (!isAuthenticated) {
    return null;
  }

  return isAllowedTo(permission) ? (
    <Route {...routeProps} />
  ) : (
    <Redirect to={HOME_ROUTE} />
  );
};

export default ProtectedRoute;
