import { FC } from 'react';
import useStyles from './useStyles';

const NotFound: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <span className={classes.largeText}>404</span>
      <span className={classes.smallText}>Page not found</span>
    </div>
  );
};

export default NotFound;
