import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { FC, ReactNode } from 'react';
import { Dialog } from '@material-ui/core';
import { Button } from './';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      borderRadius: '25px',
      padding: '37px 22px 24px 22px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    dialogTitle: {
      width: '240px',
      fontSize: '21px',
      fontWeight: 800,
      lineHeight: '28px',
      color: theme.customPalette.textPrimary,
      marginBottom: '12px',
      textAlign: 'center',
    },
    dialogDescription: {
      maxWidth: '240px',
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '19px',
      color: theme.customPalette.accentText,
      marginBottom: '20px',
      textAlign: 'center',
    },
    button: {
      maxWidth: '240px',
      marginTop: '20px',
    },
  }),
);

type Props = {
  open: boolean;
  title: string;
  description?: ReactNode;
  okText: string;
  cancelText: string;
  onConfirm: () => void;
  onCancel: () => void;
};

const ConfirmDialog: FC<Props> = ({
  open,
  title,
  description,
  okText,
  cancelText,
  onConfirm,
  onCancel,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      PaperProps={{ className: classes.container }}
      maxWidth="xs"
    >
      <div className={classes.dialogTitle}>{title}</div>
      <div className={classes.dialogDescription}>{description}</div>

      <Button
        text={okText}
        primary
        className={classes.button}
        onClick={onConfirm}
      />
      <Button text={cancelText} className={classes.button} onClick={onCancel} />
    </Dialog>
  );
};

export default ConfirmDialog;
