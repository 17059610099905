import clsx from 'clsx';
import { useTheme } from '@material-ui/core/styles';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  NoOrders,
  OrderCard,
  OrderSummary,
  SeeAllButton,
  SeeMoreButton,
} from 'shared/components';
import { MerchantOrderHistoryItem, OrderItem } from 'shared/types';
import useStyles from './useStyles';

const ORDER_HISTORY_ITEMS_PER_PAGE = 20;

const getOrdersToRender = (
  orders: MerchantOrderHistoryItem[],
  showAll: boolean,
) => (showAll ? orders : orders.slice(0, ORDER_HISTORY_ITEMS_PER_PAGE));

const getSummaryItems = (outstandingOrders: MerchantOrderHistoryItem[]) => {
  const groupedProducts = outstandingOrders.reduce((acc, current) => {
    const accCopy = { ...acc };
    current.products.forEach((item) => {
      if (accCopy.hasOwnProperty(item.uuid)) {
        accCopy[item.uuid] = {
          ...accCopy[item.uuid],
          quantity: accCopy[item.uuid].quantity + item.quantity,
        };
      } else {
        accCopy[item.uuid] = item;
      }
    });
    return accCopy;
  }, {} as { [key: string]: OrderItem });
  return Object.values(groupedProducts);
};

type Props = {
  outstandingOrders: MerchantOrderHistoryItem[];
  retrievedOrders: MerchantOrderHistoryItem[];
};

const CardBody: FC<Props> = ({ outstandingOrders, retrievedOrders }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const [showAllOutstanding, setShowAllOutstanding] = useState(false);
  const [showAllRetrieved, setShowAllRetrieved] = useState(false);

  const handleClickshowAllOutstanding = () => {
    setShowAllOutstanding((state) => !state);
  };

  const handleClickshowAllRetrieved = () => {
    setShowAllRetrieved((state) => !state);
  };

  const renderOrder =
    (collapsed: boolean) => (order: MerchantOrderHistoryItem) =>
      (
        <OrderCard
          key={order.uuid}
          orderBoothTypes={[order.boothTypeId]}
          orderItems={order.products}
          orderNumber={order.number}
          orderStatus={order.deliveryStatus}
          paymentStatus={order.paymentStatus}
          orderTotalAmount={order.amount}
          orderDate={order.created}
          initiallyCollapsed={collapsed}
          variant="merchant"
        />
      );

  return (
    <>
      {Boolean(outstandingOrders.length) && (
        <section className={clsx(classes.section, classes.overviewSection)}>
          <header>
            <h3>{t('merchantOrderHistory.contentTitle1')}</h3>
          </header>
          <OrderSummary
            className={classes.orderSummary}
            backgroundColor={theme.palette.primary.main}
            textColor={theme.palette.common.white}
            items={getSummaryItems(outstandingOrders)}
          />
        </section>
      )}
      <section className={clsx(classes.section, classes.outstandingSection)}>
        <header>
          <h3>{t('merchantOrderHistory.contentTitle2')}</h3>
          {outstandingOrders.length > ORDER_HISTORY_ITEMS_PER_PAGE && (
            <SeeAllButton
              expanded={showAllOutstanding}
              onClick={handleClickshowAllOutstanding}
            />
          )}
        </header>
        {!outstandingOrders.length && (
          <NoOrders text={t('orderHistory.noOutstandingOrders')} />
        )}
        {getOrdersToRender(outstandingOrders, showAllOutstanding).map(
          renderOrder(false),
        )}
        {outstandingOrders.length > ORDER_HISTORY_ITEMS_PER_PAGE && (
          <SeeMoreButton onClick={handleClickshowAllOutstanding} />
        )}
      </section>
      <section className={clsx(classes.section, classes.retrievedSection)}>
        <header>
          <h3>{t('merchantOrderHistory.contentTitle3')}</h3>
          {retrievedOrders.length > ORDER_HISTORY_ITEMS_PER_PAGE && (
            <SeeAllButton
              expanded={showAllRetrieved}
              onClick={handleClickshowAllRetrieved}
            />
          )}
        </header>
        {!retrievedOrders.length && (
          <NoOrders text={t('orderHistory.noRetrievedOrders')} />
        )}
        {getOrdersToRender(retrievedOrders, showAllRetrieved).map(
          renderOrder(true),
        )}
        {retrievedOrders.length > ORDER_HISTORY_ITEMS_PER_PAGE && (
          <SeeMoreButton onClick={handleClickshowAllRetrieved} />
        )}
      </section>
    </>
  );
};

export default CardBody;
