import { createMuiTheme } from '@material-ui/core';

type Color = React.CSSProperties['color'];

type CustomPalette = {
  background: Color;
  lightGrayBackground: Color;
  introAdditional: Color;
  introScreenBodyText: Color;
  menuSecondaryLink: Color;
  labelGray: Color;
  textPrimary: Color;
  blueText: Color;
  highlightGreen: Color;
  backgroundGreen: Color;
  accentGreen: Color;
  accentText: Color;
  blueGray: Color;
  darkPurple: Color;
  warning: Color;
  error: Color;
};

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    customPalette: CustomPalette;
  }

  interface ThemeOptions {
    customPalette?: Partial<CustomPalette>;
  }
}

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#38cc79',
    },
    secondary: {
      main: '#0559c1',
    },
  },
  customPalette: {
    background: '#220961',
    lightGrayBackground: '#f8f8f7',
    introAdditional: '#ff4743',
    introScreenBodyText: '#e1d9f6',
    menuSecondaryLink: '#9a87cd',
    labelGray: '#7b8084',
    textPrimary: '#393f44',
    blueText: '#0459c1',
    highlightGreen: '#ccffea',
    backgroundGreen: '#00995b',
    accentGreen: '#00cc79',
    warning: '#ff9400',
    error: '#ff4949',
    accentText: '#b5b7b9',
    blueGray: '#5e7b95',
    darkPurple: '#140048',
  },
  typography: {
    fontFamily: ['Nunito Sans', 'sans-serif'].join(','),
  },
});
