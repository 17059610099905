export type Amount = {
  value: number;
  currency: string;
};

export type UserResponse = {
  uuid: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string[];
  permissions: string[];
  language: string;
  dateOfBirth: string;
  credits: number;
  merchantBoothId?: number;
};

export type IdentityTokenResponse = {
  token: string;
};

export type EventsListItem = {
  uuid: string;
  name: string;
};

export type EventResponse = {
  uuid: string;
  name: string;
  currency: string;
  exchangeRate: number;
  cancelPaidOrder: boolean;
  active: boolean;
};

export type PromiseResult<T> = T extends PromiseLike<infer U> ? U : T;

export type Product = {
  uuid: string;
  name: string;
  amount: Amount;
  defaultCurrencyAmount: Amount;
  available: boolean;
  boothTypeId: number;
  categoryId: number;
};

export type OrderedProduct = Product & {
  quantity: number;
};

export enum OrderViewType {
  Paid = 'paid',
  Helpdesk = 'helpdesk',
  Retrieved = 'retrieved',
  WrongBooth = 'wrongBooth',
  Unknown = 'unknown',
}

export enum OrderStatus {
  Pending = 'pending',
  Retrieved = 'retrieved',
  Helpdesk = 'helpdesk',
  Cancelled = 'cancelled',
  WrongBooth = 'wrongBooth',
  Unknown = 'unknown',
}

export enum OrderStatusExtended {
  Draft = 'draft',
  Paid = 'paid',
  Pending = 'pending',
  Retrieved = 'retrieved',
  Helpdesk = 'helpdesk',
  Cancelled = 'cancelled',
  WrongBooth = 'wrongBooth',
  Unknown = 'unknown',
}

export enum PaymentStatus {
  Open = 'open',
  Pending = 'pending',
  Failed = 'failed',
  Canceled = 'canceled',
  Expired = 'expired',
  Paid = 'paid',
  None = 'none',
}

export type Booth = {
  type: number;
  zone: string;
};

export type OrderItem = Omit<Product, 'categoryId'> & {
  quantity: number;
};

export type Order = {
  id: string;
  number: number;
  date: string;
  status: OrderStatus;
  paymentStatus: PaymentStatus;
  totalAmount: Amount;
  defaultCurrencyTotalAmount: Amount;
  booth: Booth;
  products: OrderItem[];
  authorUuid: string;
};

export type ProductCategory = { id: number; name: string; icon: string };

export enum PaymentMethodType {
  Credits = 'festivalcredits',
  Bancontact = 'bancontact',
  Ideal = 'ideal',
  Card = 'creditcard',
}

export type PaymentMethod = {
  id: PaymentMethodType;
  name: string;
  maximumAmount: Amount;
  minimumAmount: Amount;
  availableForCreditsPayment: boolean;
  availableForMerchant: boolean;
};

export enum PaymentType {
  Credits = 'credits',
}

export type Payment = {
  credits: number;
  id: string;
  status: PaymentStatus;
  type: PaymentType;
};

export type BoothType = {
  id: number;
  number: number;
  name: string;
  location?: string;
};

export enum QrCodeType {
  BoothNumber = 'BoothNumber',
  OrderId = 'OrderId',
  CreditsPayment = 'CreditsPayment',
  ActivateDevice = 'ActivateDecice',
}

export type QrCodeValue = {
  type: QrCodeType;
  value: number | string;
};

export type BoothTypeInfo = {
  id: number;
  name: string;
  number: number;
  tags: Array<{ id: number; name: string }>;
  typeDescription: string;
  typeId: number;
  typeImages: string[];
  zone: string;
};

export type OrderDraft = {
  id: string;
  products: OrderedProduct[];
  paymentStatus: PaymentStatus;
  totalAmount: Amount;
  defaultCurrencyTotalAmount: Amount;
};

export type OrderDetailsBoothOrder = {
  boothType: number;
  boothZone: string;
  items: OrderItem[];
};

export type OrderHistoryItem = {
  uuid: string;
  amount: Amount;
  boothTypeId: number;
  created: string;
  deliveryStatus: OrderStatus;
  paymentStatus: PaymentStatus;
  number: number;
};

export type MerchantOrderHistoryItem = OrderHistoryItem & {
  products: OrderItem[];
};

export type BoothInfo = {
  id: number;
  name: string;
  number: number;
  zone: string;
  open: boolean;
};

export type OrderingDay = {
  active: boolean;
  dayNumber: number;
  openingDate: string;
  closingDate: string;
};

export type BoothTypeAbout = {
  languageCode: string;
  text: string;
};

export type BoothTypeStatusText = BoothTypeAbout;

export type MerchantBoothInfo = {
  categories: Array<Omit<ProductCategory, 'icon'> & { iconUrl: string }>;
  id: number;
  name: string;
  number: 5;
  open: boolean;
  orderHours: OrderingDay[];
  organizationName: string;
  typeDescription: BoothTypeAbout[];
  typeId: number;
  typeImages: string[];
  typeStatusText: BoothTypeStatusText[];
  zone: string;
};

export type MerchantBooth = {
  id: number;
  name: string;
  number: number;
  open: boolean;
};

export type MerchantBoothsResponse = {
  items: MerchantBooth[];
};
