import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@material-ui/core';
import { FlatLayout } from 'shared/layouts';
import { BoothHeader, PlaceOrderFooter, ScreenFooter } from 'shared/components';
import { BOOTH_MENU_ROUTE } from 'shared/constants';
import { useRoleNavigation } from 'shared/helpers';
import { BoothType } from 'shared/types';
import { messageSlice, RootState } from 'core/store';
import { getCategoryBoothTypeList } from 'core/requests';
import BoothCard from './BoothCard';

const useStyles = makeStyles(() =>
  createStyles({
    contentContainer: {
      paddingTop: '5px',
      paddingBottom: '120px',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'center',
    },
  }),
);

const BoothSelection: FC = () => {
  const { goBack } = useHistory();
  const { push } = useRoleNavigation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id: categoryId } = useParams<{ id: string }>();
  const classes = useStyles();
  const categories = useSelector((state: RootState) => state.categories.data);
  const [booths, setBooths] = useState<BoothType[]>([]);

  const selectedCategory = useMemo(
    () => categories.find((item) => item.id === Number(categoryId)),
    [categories, categoryId],
  );
  const selectedCategoryId = selectedCategory?.id;

  useEffect(() => {
    if (selectedCategoryId) {
      (async () => {
        try {
          const { data } = await getCategoryBoothTypeList(categoryId);
          setBooths(data.data.items);
        } catch {
          dispatch(
            messageSlice.actions.showMessage({
              text: t('message.boothListFetchError'),
              type: 'error',
            }),
          );
        }
      })();
    }
  }, [categoryId, selectedCategoryId]);

  const handleCardClick = (boothNumber: number) => () => {
    push(`${BOOTH_MENU_ROUTE}/${boothNumber}`);
  };

  const renderBoothCard = (item: BoothType) => (
    <BoothCard key={item.id} {...item} onClick={handleCardClick(item.number)} />
  );

  return (
    <FlatLayout
      onBackClick={goBack}
      backgroundOverflow={0}
      header={
        <BoothHeader
          name={selectedCategory?.name ?? ''}
          categoryId={Number(categoryId)}
        />
      }
      footer={
        <ScreenFooter>
          <PlaceOrderFooter />
        </ScreenFooter>
      }
      maxContentWidth={750}
    >
      <div className={classes.contentContainer}>
        {booths.map(renderBoothCard)}
      </div>
    </FlatLayout>
  );
};

export default BoothSelection;
