import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { MAX_INTERACTIVE_ELEMENT_WIDTH } from 'shared/constants';
import { RootState } from 'core/store';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    headerContainer: {
      margin: '15px 25px 27px 16px',
    },
    welcomeText: {
      fontSize: '21px',
      fontWeight: 700,
      color: 'white',
    },
    nameText: {
      color: theme.palette.primary.main,
    },
    creditsContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    creditsDescriptionText: {
      fontSize: '16px',
      color: '#c7bae8',
    },
    creditsAmountContainer: {
      display: 'flex',
      height: '28px',
    },
    pkpText: {
      marginRight: '4px',
      fontSize: '14px',
      color: theme.customPalette.accentText,
      alignSelf: 'flex-start',
    },
    creditsAmount: {
      fontSize: '21px',
      lineHeight: '21px',
      color: '#9a87cd',
      alignSelf: 'flex-end',
    },
    creditsBorder: {
      width: '2px',
      height: '20px',
      backgroundColor: theme.palette.primary.main,
      marginLeft: '4px',
      marginTop: '4.5px',
    },
    inputContainer: {
      display: 'flex',
      height: '40px',
      maxWidth: `${MAX_INTERACTIVE_ELEMENT_WIDTH}px`,
      borderRadius: '20px',
      backgroundColor: 'white',
      alignSelf: 'center',
      width: '100%',
      marginLeft: '18px',
      marginRight: '18px',
    },
    icon: {
      color: theme.customPalette.background,
      fontSize: '22px',
      marginLeft: '12px',
    },
    input: {
      padding: '12px 0px 12px 8px',
      fontSize: '17px',
    },
  }),
);

const Header: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const currentUser = useSelector((state: RootState) => state.currentUser.data);

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <Trans
          className={classes.welcomeText}
          parent="span"
          i18nKey="home.welcome"
          values={{ name: currentUser?.firstName || '' }}
          components={[<span key="welcome" className={classes.nameText} />]}
        />
        <div className={classes.creditsContainer}>
          <span className={classes.creditsDescriptionText}>
            {t('home.availableCredits')}
          </span>
          <div className={classes.creditsAmountContainer}>
            <span className={classes.pkpText}>PKP</span>
            <span className={classes.creditsAmount}>
              {currentUser ? currentUser.credits.toFixed(2) : 0}
            </span>
            <div className={classes.creditsBorder} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
