import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { putChangeLanguage } from 'core/requests';
import { loaderSlice, messageSlice, RootState } from 'core/store';
import { languages } from 'shared/constants';
import i18n from '../../i18n/i18n';

const useLanguage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { language } = useSelector((state: RootState) => ({
    language: state.currentUser.data?.language,
  }));
  const [currentLanguage, setCurrentLanguage] = useState(language || 'en');

  useEffect(() => {
    if (language) {
      setCurrentLanguage(language);
    }
  }, [language]);

  const changeLanguage = async (languageCode: string) => {
    const previousLanguage = currentLanguage;
    if (languageCode) {
      dispatch(loaderSlice.actions.setLoaderState({ loading: true }));
      try {
        setCurrentLanguage(languageCode);
        await putChangeLanguage(languageCode);
        await i18n.changeLanguage(languageCode);
        window.location.reload();
      } catch {
        setCurrentLanguage(previousLanguage);
        dispatch(
          messageSlice.actions.showMessage({
            text: t('appMenu.message.changeLanguageFail'),
            type: 'error',
          }),
        );
      } finally {
        dispatch(loaderSlice.actions.setLoaderState({ loading: false }));
      }
    }
  };

  return { currentLanguage, changeLanguage, languages };
};

export default useLanguage;
