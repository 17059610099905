import { FC } from 'react';
import {
  Button,
  ButtonProps,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '60px',
      height: '60px',
      minWidth: 'auto',
      backgroundColor: '#f8f8f8',
      border: '1px solid #d0d0d0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '12px',
    },
    addPicureIcon: {
      fontSize: '24px',
      color: theme.customPalette.blueText,
    },
  }),
);

const AddButton: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AddCircle className={classes.addPicureIcon} />
    </div>
  );
};

export default AddButton;
