import { createStyles, makeStyles, Theme } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { FC } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 13,
      color: theme.palette.primary.main,
    },
    icon: {
      fontSize: '14px',
      marginRight: 8,
    },
    text: {
      fontSize: '0.875rem',
      fontWeight: 'bold',
    },
  }),
);

type Props = {
  text: string;
};

const Status: FC<Props> = ({ text }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CheckCircleIcon className={classes.icon} />
      <span className={classes.text}>{text}</span>
    </div>
  );
};

export default Status;
