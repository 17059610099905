import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sectionTitle: {
      fontSize: '12px',
      fontWeight: 700,
      color: theme.customPalette.labelGray,
      margin: '20px 0',
      textTransform: 'uppercase',
      display: 'block',
    },
    actionText: {
      fontSize: '12px',
      color: theme.customPalette.blueText,
      fontWeight: 600,
      marginBottom: '32px',
      textDecoration: 'none',
    },
    datePicker: {
      display: 'none',
    },
  }),
);

export default useStyles;
