import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { TOP_BAR_HEIGHT } from 'shared/constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
      height: '100%',
    },
    headerContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: `${TOP_BAR_HEIGHT - 9}px`, // substract 9 for padding
      padding: '9px 15px 0 15px',
      backgroundColor: theme.customPalette.background,
      position: 'relative',
    },
    altHeaderContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: `${TOP_BAR_HEIGHT - 9}px`, // substract 9 for padding
      padding: '9px 15px 0 15px',
      backgroundColor: 'white',
      position: 'relative',
    },
    button: {
      height: '40px',
      width: '40px',
    },
    menuIcon: {
      fontSize: '24px',
      color: 'white',
    },
    logoIcon: {
      height: '29px',
      width: '29px',
    },
    select: {
      color: 'white',
    },
    headerRightContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    headerBadge: {
      marginRight: '16px',
      marginTop: '7px',
    },
    cartIcon: {
      fontSize: '28px',
      color: 'white',
    },
  }),
);

export default useStyles;
